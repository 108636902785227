interface Mapping {
  [key: string]: string
}

export const Mappings: Mapping = {
  'airPressure': 'Air Pressure',
  'airTemp': 'Air Temperature',
  'airHumidity': 'Air Humidity',
  'baroPressure': 'Baro Pressure',
  'battery': 'Battery',
  'cloudCover': 'Cloud Cover',
  'createdAt': 'Created At',
  'dayLength': 'Day Length',
  'leafWetness': 'Leaf Wetness',
  'lightIntensity': 'Light Intensity',
  'precipitationProbability': 'Rain Probability',
  'soilMoisture1': 'Soil Moisture 1',
  'soilMoisture2': 'Soil Moisture 2',
  'soilTemp': 'Soil Temperature',
  'signalStrength': 'Signal Strength',
  'sunshineHours': 'Sunshine Hours',
  'windSpeed': 'Wind Speed',
  'windDirection': 'Wind Direction',
  'rainFall': 'Rain Fall',
  'dayRain': 'Last 24 hr Rain',
  'timestamp': 'Time Stamp',
  'moisture1': 'Moisture 1',
  'moisture2': 'Moisture 2',
  'moisture3': 'Moisture 3',
  'moisture4': 'Moisture 4',

  'MinSoilMoisture1': 'Min Soil Moisture 1',
  'MinSoilMoisture2': 'Min Soil Moisture 2',
  'MinSoilTemp': 'Min Soil Temperature',
  'MinLeafWet': 'Min Leaf Wetness',
  'MinAirTemp': 'Min Air Temperature',
  'MinAirHumidity': 'Min Air Humidity',
  'MinAirPressure': 'Min Air Pressure',
  'MinLux': 'Min Light Intensity',
  'MinWindSpeed': 'Min Wind Speed',
  'MinSignalStrength': 'Min Signal Strength',
  'MinBattery': 'Min Battery',


  'MaxSoilMoisture1': 'Max Soil Moisture 1',
  'MaxSoilMoisture2': 'Max Soil Moisture 2',
  'MaxSoilTemp': 'Max Soil Temperature',
  'MaxLeafWet': 'Max Leaf Wetness',
  'MaxAirTemp': 'Max Air Temperature',
  'MaxAirHumidity': 'Max Air Humidity',
  'MaxAirPressure': 'Max Air Pressure',
  'MaxLux': 'Max Light Intensity',
  'MaxWindSpeed': 'Max Wind Speed',
  'MaxSignalStrength': 'Max Signal Strength',
  'MaxBattery': 'Max Battery',

  // diseases
  'anthracnose': 'Anthracnose',
  'botrytis': 'Botrytis',
  'downy_mildew': 'Downy Mildew',
  'powdery_mildew': 'Powdery Mildew',

  // pests
  'caterpillar': 'Caterpillar',
  'flea_beetle': 'Flea Beetle',
  'jassids': 'Jassids',
  'mealybug': 'Mealy Bug',
  'red_spider_mites': 'Red Spider Mites',
  'thrips': 'Thrips',
}

export const UnitMapping: Mapping = {
  'airPressure': 'kPa',
  'airTemp': '℃',
  'airHumidity': '%',
  'baroPressure': 'hPa',
  'battery': '%',
  'dayRain': 'mm',
  'lightIntensity': 'lux',
  'rainFall': 'mm',
  'signalStrength': '%',
  'windSpeed': 'km/h',
  'soilMoisture1': 'kPa',
  'soilMoisture2': 'kPa',
  'soilTemp': '℃',
  'moisture1': '%',
  'moisture2': '%',
  'moisture3': '%',
  'moisture4': '%',
}

// This row is for the "Show Columns" filter dropdown.
export const ColumnMapping: Mapping = {
  'area': 'Area',
  'cropSown.activeStage': 'Crop Active Stage',
  'cropSown.isCropEnded': 'Crop Ended',
  'cropSown.name': 'Crop',
  'cropSown.sowingDate': 'Sowing Date',
  'cropSown.varietyId': 'Crop Variety',
  'device.dataInterval': 'Data Interval',
  'device.deviceType': 'Device Type',
  'device.imei': 'IMEI',
  'device.sensors': 'Sensors',
  'device.soilMoisture1Depth': 'Soil Moisture 1 Depth',
  'device.soilMoisture2Depth': 'Soil Moisture 2 Depth',
  'district': 'District',
  'dipperDischarge': 'Dipper Discharge',
  'farm.agronomistId': 'Farm Agronomist',
  'farm.altitude': 'Farm Altitude',
  'farm.farmArea': 'Farm Area',
  'farm.farmId': 'Farm Id',
  'farm.farmManagerId': 'Farm Manager',
  'farm.name': 'Farm Name',
  'farmUser.email': 'Farmer Email',
  'farmUserId': 'Farmer Id',
  'farmUser.lang': 'Farmer Language',
  'farmUser.mobile': 'Farmer Mobile',
  'farmUser.name': 'Farmer Name',
  'farmUser.farmUserId': 'Farmer Id',
  'farmUser.doNotContact': 'Do not Contact',
  'name': 'Plot Name',
  'stateAndDistrict': 'Location',
  'perPlantDipper': 'Per Plant Dipper',
  'place': 'Place',
  'plantationYear': 'Plantation Year',
  'plantDistance': 'Plant Distance',
  'plotId': 'Plot Id',
  'plotType': 'Plot Type',
  'rowDistance': 'Row Distance',
  'soilTypeId': 'Soil Type',
  'taluka': 'Taluka',
  'created_date': 'Created Date',
  'farmUser.lastInteractionDate': 'Last Conversation',
  'location': 'Geo Location',
}

export const NutrientScreenMapping: Mapping = {
  'petioleStandard': 'Petiole Standard',
  'soilStandard': 'Soil Standard',
  'foliarDoseRatio': 'Foliar Dose Ratio',
  'standardDose': 'Standard Dose',
  'nutrientIndex': 'Nutrient Index',
  'fertigationDoseRatio': 'Fertigation Dose Ratio',
  'unit': 'Unit',
  'value': 'Value',
  'formula': 'Formula',
  'foliar': 'Foliar',
  'fertigation': 'Fertigation',
  'soilApplication': 'Soil Application',
  'standardSoilDose': 'Standard Soil Dose'
}

export const WindDirectionMapping: Mapping = {
  'NE': 'E',
  'ENE': 'E',
  'E': 'E',
  'ESE': 'E',
  'SSE': 'S',
  'SE': 'S',
  'S': 'S',
  'SSW': 'S',
  'SW': 'W',
  'WSW': 'W',
  'W': 'W',
  'WNW': 'W',
  'NW': 'N',
  'NNW': 'N',
  'N': 'N',
  'NNE': 'N'
}

export function getBusinessDashboardMapping(key: string): string {
  const res=key
  .split('_')  
  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
  .join(' ');  
  

  return res
}

export const InformationCardTitles: Mapping = {
  plots: 'Total Plots',
  districts:"Districts",
  farms:"Farms",
  inconsistentIrrigation: 'Inconsistent Irrigation',
  actions: 'Actions for Today',
  falsyPlots: 'Needs Attention!',
  crops: 'Crops',
}

export const NotificationMapping: Mapping = {
  '1': 'Irrigation',
  '3': 'Fertigation',
  '4': 'Spray',
  '49': 'Preventive Spray',
  '50': 'Dynamic Spray'
}

export const WeatherIcon: Mapping = {
  'Unknown': 'Unknown',
  'Clear': 'Clear',
  'Cloudy': 'Cloudy',
  'PartlyClear': 'Clear',
  'MostlyClear': 'Clear',
  'PartlyCloudy': 'Cloudy',
  'MostlyCloudy': 'Cloudy',
  'Fog': 'Fog',
  'LightFog': 'Fog',
  'LightWind': 'Wind',
  'Wind': 'Wind',
  'StrogWind': 'Wind',
  'Drizzle': 'Rain',
  'FreezingDrizzle': 'Rain',
  'Rain': 'Rain',
  'LightRain': 'Rain',
  'HeavyRain': 'Rain',
  'Snow': 'Snow',
  'Flurries': 'Snow',
  'LightSnow': 'Snow',
  'HeavySnow': 'Snow',
  'FreezingRain': 'Rain',
  'LightFreezingRain': 'Rain',
  'HeavyFreezingRain': 'Rain',
  'Hail': 'Hail',
  'HeavyHail': 'Hail',
  'LightHail': 'Hail',
  'Thunderstorm': 'Rain',
  'Tornado': 'Storm',
  'TropicalStorm': 'Storm',
  'Hurricane': 'Storm',
  'StrongStorms': 'Storm',
  'RainSnow': 'Snow',
  'RainSleet': 'Rain',
  'BlowingSnow': 'Snow',
  'Sleet': 'Rain',
  'Sandstorm': 'Storm',
  'Smoke': 'Fog',
  'IceCrystals': 'Rain',
  'RainHail': 'Rain',
  'Hot': 'Hot',
  'Cold': 'Cold',
  'ScatteredThunderstorms': 'Storm',
  'ScatteredShower': 'Storm'
}

export const WindDirectionIconMapping: Mapping = {
  'NE': 'North East',
  'ENE': 'East North East',
  'E': 'East',
  'ESE': 'East South East',
  'SSE': 'South South East',
  'SE': 'South East',
  'S': 'South',
  'SSW': 'South South West',
  'SW': 'South West',
  'WSW': 'West South West',
  'W': 'West',
  'WNW': 'West North West',
  'NW': 'North West',
  'NNW': 'North North West',
  'N': 'North',
  'NNE': 'North North East'
}

export const SoilMapping: Mapping = {
  '1': "Sandy Loam",
  '2': "Sandy Loam",
  '3': "Clay",
  '4': "Fine Sandy Loam",
  '5': "Fine Sandy Loam",
  '6': "Clay",
}