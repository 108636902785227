
export const storeName="FylloCacheStore";
export const dbName="FylloCacheDB";

let dbInstance: IDBDatabase | null = null; // Singleton instance

// Function to initialize or get the singleton instance of the database
export async function getDBInstance(): Promise<IDBDatabase> {
  if (dbInstance) {
    // Return the existing instance if already initialized
    return dbInstance;
  }

  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "id" });
      }
    };

    request.onsuccess = (event) => {
      dbInstance = (event.target as IDBOpenDBRequest).result; // Store the instance
      resolve(dbInstance);
    };

    request.onerror = (event) => {
      const error = (event.target as IDBOpenDBRequest).error;
      reject(error);
    };
  });
}

// Function to get data from the table
export async function getFromDB(key: string): Promise<any | undefined> {
  const db = await getDBInstance();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.get(key);

    request.onsuccess = () => {
      if (request.result) {
        resolve(request.result.value);
      } else {
        console.log("No data found for key:", key);
        resolve(undefined);
      }
    };
    request.onerror = (event) => reject((event.target as IDBRequest).error);
  });
}

// Function to save data in the table
export async function saveToDB(key: string, value: any): Promise<void> {
  const db = await getDBInstance();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const dataToStore = { id: key, value };
    const request = store.put(dataToStore);

    request.onsuccess = () => {
      resolve()};
    request.onerror = (event) => reject((event.target as IDBRequest).error);
  });
}

// Function to clear all data from the object store
export async function clearAllTables(): Promise<void> {
  const db = await getDBInstance(); // Use the singleton instance
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const clearRequest = store.clear(); // Clear all entries in the store

    clearRequest.onsuccess = () => {
      console.log("All data cleared from the store.");
      resolve();
    };

    clearRequest.onerror = (event) => {
      console.error("Error clearing store:", (event.target as IDBRequest).error);
      reject((event.target as IDBRequest).error);
    };
  });
}
  