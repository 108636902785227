import { Grid, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Mappings, UnitMapping } from '../../constants/WordMapping'
import { getLatestFieldData } from '../../services/plot.service'
import { getDate, print } from '../../utils/helper'

interface PlotID {
  propsPlotId?: string;
}

const LiveDataSkeleton = () => (
  <Grid>
    <Skeleton variant='text' height={40} />
    <Grid container justifyContent={'space-evenly'}>
      <Skeleton variant='rectangular' width={300} height={200} style={{ borderRadius: '15px', margin: '15px 0' }} />
      <Skeleton variant='rectangular' width={300} height={200} style={{ borderRadius: '15px', margin: '15px 0' }} />
      <Skeleton variant='rectangular' width={300} height={200} style={{ borderRadius: '15px', margin: '15px 0' }} />
      <Skeleton variant='rectangular' width={300} height={200} style={{ borderRadius: '15px', margin: '15px 0' }} />
      <Skeleton variant='rectangular' width={300} height={200} style={{ borderRadius: '15px', margin: '15px 0' }} />
      <Skeleton variant='rectangular' width={300} height={200} style={{ borderRadius: '15px', margin: '15px 0' }} />
    </Grid>
  </Grid>
)

const LiveData: React.FC<PlotID> = (props): JSX.Element => {
  const { propsPlotId } = props;
  let { plotId } = useParams()
  if (propsPlotId !== undefined) {
    plotId = propsPlotId;
  }
  const [latestFieldData, setLatestFieldData] = useState<any>()
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    getLatestFieldData(plotId as string)
      .then(res => {
        setLatestFieldData(res)
        setIsReady(true)
      })
  }, [plotId])

  const getDescription = (labels: any[], value: string | number) => {
    let text = "", color = ""
    labels.map((label: any) => {
      const { min, max, name, labelColor } = label
      if (value >= min && value <= max) {
        text = name
        color = labelColor
      }
    })
    return {
      text,
      color
    }
  }

  return (
    <>
      {
        isReady ?
          <Grid>
            <Typography textAlign={'center'} mb={2} >Last Updated {getDate(latestFieldData?.timestamp?.value, 'LLLL')}</Typography>

            <Grid container justifyContent={'center'} >
              {
                latestFieldData && Object.keys(latestFieldData)?.map((data, ind) => {
                  const toShow = ['airHumidity', 'airPressure', 'airTemp', 'lightIntensity', 'windSpeed', 'soilMoisture1', 'soilMoisture2', 'moisture1', 'moisture2', 'moisture3', 'moisture4', 'soilTemp', 'leafWetness']
                  const toShow2 = ['rainFall', 'windDirection', 'dayRain']

                  if (toShow.includes(data) || toShow2.includes(data)) {
                    const { color, description, value, minOptimalValue, maxOptimalValue, labels } = latestFieldData[data]
                    const isValue = typeof latestFieldData[data] !== 'object'
                    return (
                      <Grid textAlign={'center'} display={'flex'} flexDirection={'column'} borderRadius={2} boxShadow={4} m={1} mx={3} p={1} width={300} height={200} key={`live_data_${data}_${ind}`} >
                        <Typography variant='h6' my={1} >{Mappings[data] ?? 'N.A'} {UnitMapping[data] ? `(${UnitMapping[data]})` : ''} </Typography>
                        <Typography variant='h4' my={1} > {print(isValue ? latestFieldData[data] : value)} </Typography>
                        {
                          data !== 'windDirection' &&
                          <Typography
                            color={labels ? getDescription(labels, value).color : color}
                            my={1} fontWeight={600} fontSize={14} >
                            {(labels ? getDescription(labels, value).text : description) ?? ''}
                          </Typography>
                        }

                        {
                          toShow.includes(data) &&
                          <Typography my={1} fontWeight={600} >
                            Ideal: {print(minOptimalValue as number) ?? 'N.A'} - {print(maxOptimalValue as number) ?? 'N.A'}
                          </Typography>
                        }

                      </Grid>
                    )
                  }

                  return <span key={`live_data_${data}_${ind}`}></span>
                })
              }
            </Grid>
          </Grid> :
          <LiveDataSkeleton />
      }
    </>
  )
}

export default LiveData