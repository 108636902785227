import { Search } from '@mui/icons-material';
import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
import {
	Autocomplete,
	DrawingManager,
	GoogleMap,
	InfoWindow,
	Marker,
	MarkerClusterer,
	Polygon,
	useJsApiLoader
} from '@react-google-maps/api';
import _, { clone, uniqueId } from 'lodash';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { DatePicker, DialogConfirm, ToastAlert } from 'src/components';
import { AddGeolocationModal } from 'src/pages/BusinessDashboard/components';
import {
	getGraphPlotsDataForMarker,
	getRegionDataForMarker,
	myMapOptions,
	updateLegends,
	updateMapOverlayDatas
} from 'src/pages/BusinessDashboard/helper';
import { plotsAtom } from 'src/recoil/atom';
import { getLatestDataForMap, getPlot, updateGeoBoundary } from 'src/services/plot.service';
import { getRegionDataFromRegionId, getRegionFromPlot } from 'src/services/region.service';
import { Coordinates, CoordinatesArray, Plot, TLocation } from 'src/utils/types';
import { ToastSeverity } from '../ToastAlert/types';
import './FilterMap.css';
import {
	DrawnRegionInfoModal,
	PlotInfoModal,
	PlotSensorDataInfoModal,
	RegionDatePickerButton,
	RegionInfoModal
} from './components';
import {
	InfoData,
	MapWrapperProps,
	MarkerInfo,
	Markers,
	PlotSensorData,
	RegionModalInfo
} from './utils';

// dayjs
import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

type DateT = string | Date | null | Dayjs;

const mapKey = process.env.REACT_APP_MAP_KEY || 'NA';
const googleMapLibraries: any[] = ['places', 'drawing', 'geometry']; // build fails when type is GoogleMapLibraries

dayjs.extend(utc);
dayjs.extend(tz);
const TIMEZONE_OFFSET = 5;
const emptyFunction = (...args: any) => { };

const MapWrapper: FC<any> = (props) => {
	const area = (props.mapLocations || []).reduce((sum: number, plot: Plot) => sum + (plot.plotArea || 0), 0)
	const cumulativeArea = document.getElementById('google-maps-plots-cumulative-area')
	const cumulativeAreaText = document.getElementById('cumulative-area-text')
	if (cumulativeArea) {
		if (area === 0) cumulativeArea.style.display = 'none'
		else {
			cumulativeArea.style.display = 'flex'
			cumulativeAreaText!.innerHTML = `${area.toFixed(2)} Acres`;
		}
	}


	return (
		<Grid display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'}>
			{!!props.mapLocations &&
				<Map
					handlePolygonClick={props.handlePolygonClick}
					polygonRegion={props.polygonRegion}
					markers={props.mapLocations}
					height={props.height ?? '780px'}
					buttonDisabled={props.buttonDisabled}
					setButtonDisabled={props.setButtonDisabled ?? emptyFunction}
					filterApplied={props.filterApplied}
					legends={props.legends}
					mapsDate={props.mapsDate}
					setGraphPlots={props.setGraphPlots ?? emptyFunction}
					setshowgetPrediction={props.setshowgetPrediction ?? emptyFunction}
					setMapLocations={props.setMapLocations ?? emptyFunction}
					setGetndviImage={props.setGetndviImage ?? emptyFunction}
					setGetndviImagePlotId={props.setGetndviImagePlotId ?? emptyFunction}
					setGetndviImageFarmId={props.setGetndviImageFarmId ?? emptyFunction}
					setGetndviImageCrop={props.setGetndviImageCrop ?? emptyFunction}
					isRegion={props.isRegion}
					setMapsDate={props.setMapsDate ?? emptyFunction}
					plotsMap={props.plotsMap}
					hotspot={props.hotspot}
					singlePlot={props.singlePlot}
				/>
			}
		</Grid>
	)
}

const Map: FC<MapWrapperProps> = (props) => {
	const {
		buttonDisabled, filterApplied, height, isRegion,
		legends, markers, plotsMap, hotspot, setButtonDisabled, setGetndviImage,
		setGetndviImageCrop, setGetndviImageFarmId, setGetndviImagePlotId,
		setGraphPlots, setMapLocations, setMapsDate, setshowgetPrediction,
	} = props;

	const { isLoaded, } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: mapKey,
		libraries: googleMapLibraries,
		nonce: 'google-map-script'
	});

	// used for info model (plot details)
	const [showinfo, setShowinfo] = useState<boolean>(false);
	const [infodata, setInfodata] = useState<InfoData>({});

	// info modal for dashboard map (sensor data)
	const [showPlotSensorInfoModal, setShowPlotSensorInfoModal] = useState<boolean>(false);
	const [plotSensorData, setPlotSensorData] = useState<PlotSensorData>();

	// for info model (selected region/shapes)
	const [showOptionsModal, setShowOptionsModal] = useState<string>('');
	const [optionsModalLocation, setOpionsModalLocation] = useState<TLocation>();

	// info model for regions
	const [showRegionsInfoModal, setShowRegionsInfoModal] = useState<boolean>(false);
	const [regionsModalInfo, setRegionsModalInfo] = useState<RegionModalInfo>();

	const [isOpenSaveGeolocationModal, setIsOpenSaveGeolocationModal] = useState<boolean>(false);

	// plots with geoBoundaries
	const [plotsWithGeoBoundaries, setPlotsWithGeoBoundaries] = useState<Markers>([]);

	// flag that denotes permission to drag a marker (true/false)
	const [dragMarker, setDragMarker] = useState<boolean>(false);
	const [draggedPosition, setDraggedPosition] = useState<TLocation | null>(null);
	const [originalPosition, setOriginalPosition] = useState<TLocation | null>(null);

	// toast alert
	const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>('');
	const [toastSeverity, setToastSeverity] = useState<ToastSeverity>('info');

	// confirm dialog
	const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
	const [onConfirm, setOnConfirm] = useState<any>(null);

	const [autoComplete, setAutoComplete] = useState<any>({});
	const [selectedShape, setSelectedShape] = useState<any>({}); // has every shape drawn
	const [currentShape, setCurrentShape] = useState<any>(); // used for deleting the shape
	const [shapePath, setShapePath] = useState<CoordinatesArray>([]); // [[ [lng0, lat0], [lng1, lat1], [lng2, lat2], ... , [lng0, lat0] ]]
	const [GOOGLE_SHAPE_OPTIONS, setGoogleShapeOptions] = useState({
		fillColor: "white",
		fillOpacity: 0.5,
		strokeWeight: 0,
		clickable: true,
		zIndex: 1,
	})

	const [markersCovered, setMarkersCovered] = useState<Markers>([]);
	const [__, setPlotIds] = useRecoilState(plotsAtom)

	const mapRef: any = useRef(null);
	const map = mapRef.current;
	const drawingManagerRef: any = useRef(null);
	const selectedShapes: any = useRef()

	const todaysDate = new Date();

	const [regionDate, setRegionDate] = useState<DateT>(
		dayjs(todaysDate).add(-1, "day").toDate()
	);

	const [todaysTime, setTodaysTime] = useState<DateT>();

	const [isTodaysDate, setIsTodaysDate] = useState<boolean>(false);
	const [maxTime, setMaxTime] = useState<DateT>();

	/*
	function to check whether its todays date or not
		if todays date, 
			- set TodaysTime and maxTime to -1 hours from now, also, minute should always be 30
		else
			- reset TodaysTime and maxTime
	*/
	useEffect(() => {
		if (plotsMap) return;

		const formattedRegionDate = dayjs(regionDate).format('DD/MM/YYYY')
		const todaysFormattedDate = dayjs().format('DD/MM/YYYY')

		const isTodaysDateTemp = formattedRegionDate === todaysFormattedDate

		if (isTodaysDateTemp) {
			const latestDate = dayjs().add(-1, 'hours').minute(30).format('YYYY-MM-DDTHH:mm');
			setMaxTime(latestDate);
			const todaysTime = dayjs(latestDate).add(-(TIMEZONE_OFFSET + 1), 'hour');
			setTodaysTime(todaysTime)
		} else {
			setTodaysTime(null)
			setMaxTime(null)
		}

		setIsTodaysDate(isTodaysDateTemp);
	}, [regionDate])


	const dateHandler = useCallback((newDate: DateT) => {
		setRegionDate(newDate);
		setMapsDate(newDate)
	}, []);

	useEffect(() => {
		if (plotsMap) return;
		if (isTodaysDate && !!todaysTime) {
			setMapsDate(todaysTime)
		}
	}, [todaysTime])

	// selected region elements
	const selectedRegionInfoContainer: any = document.querySelector('#google-maps-selected-region-info-container');

	useEffect(() => {
		if (plotsMap) return;
		updateMapOverlayDatas(markersCovered)
	}, [markersCovered])

	useEffect(() => {
		if (plotsMap) return;
		selectedShapes.current = selectedShape
		if (!!selectedShapes.current) {
			let insideMarkers: Markers = []

			for (const shape in selectedShapes.current) {
				const currentInsideMarkers = markers!.filter(marker => isMarkerInsideShape(marker, shape))
				insideMarkers = _.union(insideMarkers, currentInsideMarkers)
			}

			setMarkersCovered(insideMarkers)
		}
	}, [selectedShape])

	useEffect(() => {
		if (plotsMap) return;
		setGetndviImagePlotId(null);
		setGetndviImageCrop(null);
		setGetndviImageFarmId(null);
		setGetndviImage(false);
		if (!draggedPosition) setDragMarker(false);
		deleteAllShapes()
		if (map) {
			// get markers with geoboundaries
			if (!!markers && markers.length > 0) {
				const geoBoundaries = markers.filter(marker => !!marker.geoBoundary)
				setPlotsWithGeoBoundaries(geoBoundaries)
			}
			setButtonDisabled(true)

			setTimeout(() => {
				setButtonDisabled(false);
			}, 400)
		}
	}, [markers, map])

	useEffect(() => {
		if (plotsMap) return;
		if (map && legends) {
			updateLegends(legends)
		}
	}, [legends, map])

	useEffect(() => {
		if (!!onConfirm) {
			setIsConfirmOpen(true)
		}
	}, [onConfirm])


	const onLoad = async (map: any) => {
		mapRef.current = map;
		mapRef.current.mapTypeId = 'hybrid'

		const bounds = new window.google.maps.LatLngBounds();

		/**
		 * if isSinglePlot
		 * 		- zoom to the plots level
		 * else 
		 * 		- zoom to India's level
		 */

		const isSinglePlot = props.singlePlot && markers;
		if (isSinglePlot) {

			/**
			 * if hasGeoBoundary
			 * 		- zoom to the boundaries level
			 * else 
			 * 		- zoom to the marker
			*/
			const hasGeoBoundary = (markers[0].geoBoundary as any).coordinates[0].length > 2;
			if (hasGeoBoundary) {
				const geoBoundaryCoords = (markers[0].geoBoundary as any).coordinates[0];

				// Iterate through the coordinates of the geoBoundary and extend the bounds
				for (const coordinate of geoBoundaryCoords) {
					const latLng = new window.google.maps.LatLng(coordinate[1], coordinate[0]);
					bounds.extend(latLng);
				}

				map.fitBounds(bounds);
			} else {
				bounds.extend(new window.google.maps.LatLng(markers[0].location.lat, markers[0].location.lng));
				map.fitBounds(bounds);
			}
		} else {
			// zoom to India level
			bounds.extend(new window.google.maps.LatLng(23.63936, 68.14712));
			bounds.extend(new window.google.maps.LatLng(28.20453, 97.34466));
			map.fitBounds(bounds);
		}
	}

	const onUnmount = useCallback(function callback() {
		mapRef.current = null;
	}, [])

	const onMapTypeIdChanged = () => {
		const satelite = ['hybrid', 'satellite']
		if (!!mapRef && !!mapRef.current) {
			if (satelite.includes(mapRef.current.mapTypeId)) {
				setGoogleShapeOptions((mapOptions) => ({ ...mapOptions, fillColor: 'white' }))
			} else {
				setGoogleShapeOptions((mapOptions) => ({ ...mapOptions, fillColor: '#357e37', fillOpacity: 0.7 }))
			}
		}
	}

	const handleMapClick = (event: any) => {
		if (dragMarker) {
			const { latLng } = event;
			const clickedLat = Number(Number(latLng.lat()).toFixed(6));
			const clickedLng = Number(Number(latLng.lng()).toFixed(6));

			const plotId = infodata.plotId;
			const newPosition = {
				lat: clickedLat,
				lng: clickedLng
			}

			updateDraggedPosition(plotId, newPosition);
		}
	}

	/**
	 * ==================================
	 * Drawing Manager related functions
	 * ==================================
	 */

	const clearSelection = () => {
		if (currentShape) {
			currentShape.setEditable(false);
			setCurrentShape(null)
		}
	}

	const deleteSelectedShape = (currentShapeId: string) => {
		if (currentShape) {
			setShowOptionsModal('')
			const filtered = Object.keys(selectedShapes.current).filter((shapeId) => currentShapeId !== shapeId)

			const newSelectedShape: any = {}
			for (const shapeId in selectedShapes.current) {
				if (filtered.includes(shapeId)) {
					newSelectedShape[shapeId] = selectedShapes.current[shapeId]
				}
			}
			setSelectedShape(newSelectedShape)

			currentShape.setMap(null);
			setGraphPlots({})
			
			if(!!selectedRegionInfoContainer) {
				selectedRegionInfoContainer.style.display = 'none'
			}
		}
	}

	const deleteAllShapes = () => {
		if (!!selectedShapes.current) {
			for (const shapeId in selectedShapes.current) {
				deleteSelectedShape(shapeId)
			}
		}
	}

	const onCircleComplete = (circle: any, shapeId: string) => {
		const shapeObject: any = {}
		shapeObject.type = "circle"
		shapeObject.getCenter = () => {
			return {
				lat: circle.center.lat(),
				lng: circle.center.lng()
			}
		}
		shapeObject.getRadius = () => circle.radius
		shapeObject.setMap = circle.setMap

		const updatedShape = { ...selectedShapes.current }
		updatedShape[shapeId] = shapeObject;
		setSelectedShape(updatedShape)
	};

	const onPolygonComplete = (shape: any, shapeId: string) => {
		const updatedShape = { ...selectedShapes.current }

		const shapePath = shape.getPath().getArray().map((path: any) => ([path.lng(), path.lat()]))
		shapePath.push(shapePath[0])

		const arrayShapePaths = [shapePath]

		setShapePath(arrayShapePaths)

		updatedShape[shapeId] = clone(shape);
		updatedShape[shapeId].type = 'polygon'
		setSelectedShape(updatedShape)
	}

	const onShapeComplete = (shape: any, shapeObject: any, shapeId: string) => {
		clearSelection()
		setCurrentShape(shapeObject);
		if (shape === 'circle') {
			onCircleComplete(shapeObject, shapeId)
			return;
		}
		if (shape === 'polygon') {
			onPolygonComplete(shapeObject, shapeId)
			return;
		}
	};


	/**
	 * ==================================
	 * Search (auto complete) related functions
	 * ==================================
	 */

	const onAutocompleteLoad = async (autocomplete: any) => {
		setAutoComplete(autocomplete);
	}

	const onPlaceChanged = async () => {
		if (autoComplete) {
			const place = await autoComplete.getPlace();
			if (place) {
				const a = place.geometry.viewport.getNorthEast();
				const b = place.geometry.viewport.getSouthWest();
				const bounds = new window.google.maps.LatLngBounds(b, a);
				map.fitBounds(bounds);
			}
		} else {
			console.log('Autocomplete is not loaded yet!');
		}
	}

	/**
	 * ==================================
	 * Geo-Boundary related functions
	 * ==================================
	 */

	/**
	 * @function to notify user about geoBoundary update
	 * @param severity of the toast alert
	 * @param message for the toast alert
	 * @param updatedMarkeres with updatedGeoBoundary
	 * */
	const handleGeoBoundaryUpdate = (severity: ToastSeverity, message: string, updatedMarkers: any[]) => {
		setMapLocations(updatedMarkers);
		setIsToastOpen(true)
		setToastMessage(message)
		setToastSeverity(severity)
	}

	/**
	 * ==================================
	 * Marker Drag related functions
	 * ==================================
	 */

	const handleMarkerDragEnd = (event: any) => {
		const newPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
		const { plotId } = infodata;

		updateDraggedPosition(plotId, newPosition);
	}

	const saveDraggedPosition = () => {
		const plotId = infodata.plotId;

		const confirm = () => {
			const payload = {
				location: draggedPosition
			}

			updateGeoBoundary(plotId, payload)
				.finally(() => {
					setIsToastOpen(true)
					setToastMessage('Location updated successfully')
					setToastSeverity('success')
				})
		}

		setOnConfirm(() => confirm)
		setDragMarker(false)
	}

	/**
	 * @function cancels marker position change
	 * 	- undo the position change
	 * 	- clear drag related states
	 */
	const cancelMarkerPositionChange = () => {
		const plotId = infodata.plotId;
		const updatedMarkers = markers!.map((markerItr) => {
			if (plotId === markerItr.plotId) {
				return { ...markerItr, location: originalPosition }
			}
			return markerItr
		})

		setMapLocations(updatedMarkers);
		setOriginalPosition(null);
		setDraggedPosition(null);
		setDragMarker(false);
	}

	/**
	 * ==================================
	 * Info window related functions
	 * ==================================
	 */

	const openPlotsMapInfoWindow = (marker: MarkerInfo) => {
		getLatestDataForMap(marker.plotId)
			.then((json: any) => {
				setShowPlotSensorInfoModal(true)
				json.location = marker.location;
				json.place = marker.place;
				setPlotSensorData(json);
			})
	}

	const openOptionsWindow = (shapeId: string) => {
		const shapeObject = selectedShapes.current[shapeId]
		let center: TLocation;
		if (shapeObject.type === 'circle') {
			center = shapeObject.getCenter()
		} else {
			/* getting the center of a polygon by 
					using the getPath method
					then calculating the average of all the latitudes and longitudes of the polygon.
			*/
			const path = shapeObject.getPath();
			const latlng = [];

			for (var i = 0; i < path.length; i++) {
				latlng.push({ lat: path.getAt(i).lat(), lng: path.getAt(i).lng() });
			}

			const avgLat = latlng.reduce((a, b) => a + b.lat, 0) / latlng.length;
			const avgLng = latlng.reduce((a, b) => a + b.lng, 0) / latlng.length;

			center = { lat: avgLat, lng: avgLng };
		}

		setOpionsModalLocation(center);
		setShowOptionsModal(shapeId)
	}

	const openInfoWindow = async (marker: any) => {
		if (isRegion) {
			let regionMarker: RegionModalInfo;
			// fetch region data to display in info window
			if (!marker.regionId && !!marker.plotId) {
				const regions = await getRegionFromPlot(marker.plotId)
				regionMarker = {
					...marker,
					region: regions[0],
					regionId: regions[0].id
				}
			} else if (!!marker.region.id) {
				const regions = await getRegionDataFromRegionId(marker.region.id)
				regionMarker = {
					...marker,
					region: regions[0],
					regionId: regions[0].id
				}
			}

			setRegionsModalInfo(regionMarker!);
			setShowRegionsInfoModal(true)
			return;
		}

		setShowinfo(true)
		setInfodata({ isLoading: true, location: marker.location })
		if (!marker.plotId) {
			setInfodata({ isLoading: false, location: marker.location, message: 'No Data Available' })
			return;
		}

		const plotsDetails = await getPlot(marker.plotId, ['device', 'cropSown', 'plotLocation', 'farm'])

		getLatestDataForMap(marker.plotId)
			.then(json => {
				json.location = marker.location;
				json.probability = marker.value;
				if (marker.name) {
					json.name = marker.name
				}

				const { area, plotId, device, cropSown, place, taluka, district, state, farm } = plotsDetails
				const { name } = farm;

				let address = ''
				if (!!place) address += place
				if (!!taluka) address += (address.length > 0 ? ', ' : '') + taluka
				if (!!district) address += (address.length > 0 ? ', ' : '') + district
				if (!!state) address += (address.length > 0 ? ', ' : '') + state

				setInfodata({ ...json, name, plotId, deviceType: device?.deviceType, crop: cropSown?.name || undefined, area, address, marker })
			})
	}

	const saveGeolocation = () => {
		setIsOpenSaveGeolocationModal(markersCovered.length > 0);
		setShowOptionsModal('')
	}


	/**
	 * ==================================
	 * Service functions 
	 * ==================================
	 */

	const viewChartForRegion = (marker: MarkerInfo) => {
		const { label, unit, data } = getRegionDataForMarker(marker, regionDate as string)

		if (hotspot) {
			setGraphPlots({
				label: 'probability',
				unit: '%',
				data: [data],
			})
		} else {
			setGraphPlots({
				label,
				unit,
				data: [data],
				plotId: marker.plotId
			})
		}

		setShowRegionsInfoModal(false)
	}

	const viewChartForPlot = (marker: MarkerInfo) => {
		const { label, unit, data } = getGraphPlotsDataForMarker(marker)
		setGraphPlots({
			label,
			unit,
			data: [data]
		})
		setShowinfo(false)
	}

	const viewCharts = () => {
		setshowgetPrediction(false)
		let LABEL = '', UNIT = ''
		const data = markersCovered.slice(0, 10).map(marker => {
			const { label, unit, data } = hotspot ? getHotspotDataForMarker(marker) : getGraphPlotsDataForMarker(marker)
			LABEL = label
			UNIT = unit
			return data;
		})

		setGraphPlots({
			label: LABEL,
			unit: UNIT,
			data
		})
		setShowOptionsModal('')
	}

	const viewPrediction = () => {
		const plotIds = markersCovered.slice(0, 10).map(marker => marker.plotId);
		setGraphPlots({
			label: '',
			unit: '',
			data: plotIds
		});
		setshowgetPrediction(true)
		setShowOptionsModal('')
	}

	/**
	 * ==================================
	 * Util functions
	 * ==================================
	 */

	const viewSelectedMarkersInGrid = () => {
		const ids = markersCovered.map((marker) => marker.plotId)
		setPlotIds({
			ids,
			text: 'Selected Markers'
		})
	}

	const getHotspotDataForMarker = (marker: any) => {
		const { regionId, value } = marker;

		return {
			label: "probability",
			unit: '%',
			data: { regionId, value }
		}
	}

	const isMarkerInsideShape = (marker: any, shapeId: string) => {
		if (!selectedShapes.current) {
			return true;
		}

		if (selectedShapes.current[shapeId].type === "circle") {
			return window.google.maps.geometry.spherical.computeDistanceBetween(
				marker.location,
				selectedShapes.current[shapeId].getCenter()
			) <= selectedShapes.current[shapeId].getRadius();
		}

		const polygon = selectedShapes.current[shapeId]

		return window.google.maps.geometry.poly.containsLocation(
			marker.location,
			polygon
		);
	};

	/**
	 * @function updates marker position
	 * @param plotId for the marker to be updated
	 * @param newPosition to which it needs to be updated
	 */
	const updateDraggedPosition = (plotId: string, newPosition: TLocation) => {
		const updatedMarkers = markers!.map((markerItr) => {
			if (plotId === markerItr.plotId) {

				// if originalPosition is null, update the original position
				if (!originalPosition) {
					setOriginalPosition(markerItr.location);
				}

				return { ...markerItr, location: newPosition }
			}

			return markerItr
		})

		setMapLocations(updatedMarkers);
		setDraggedPosition(newPosition);
	}

	const markerIcon = (marker: MarkerInfo) => {
		if (marker.icon) {
			return {
				url: marker.icon,
				scaledSize: new window.google.maps.Size(35, 35),
				origin: new window.google.maps.Point(0, -6)
			}
		}
		const exists = ['grey', 'green', 'yellow', 'red', 'blue'].includes(marker.color!);
		if (!exists) {
			return {
				url: require('src/assets/grey-marker.png'),
				scaledSize: new window.google.maps.Size(28, 44),
				origin: new window.google.maps.Point(0, -6)
			}
		}
		return {
			url: require(`src/assets/${marker.color}-marker.png`),
			scaledSize: new window.google.maps.Size(28, 44),
			origin: new window.google.maps.Point(0, -6)
		}
	}

	const clusterStyles = [
		{
			url: markers![0]?.icon ? markers![0]?.icon : 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
			height: 50,
			width: 50,
			textOpacity: markers![0]?.icon ? 0 : 1,
			textColor: markers![0]?.icon ? 'transparent' : 'black'
		},
	];

	return isLoaded ? (
		<>
			<GoogleMap
				options={myMapOptions}
				mapContainerStyle={{
					width: '100%',
					height: height,
					borderRadius: '20px',
					position: 'relative',
				}}
				onLoad={onLoad}
				onUnmount={onUnmount}
				onMapTypeIdChanged={onMapTypeIdChanged}
				onClick={handleMapClick}
			>
				{
					(!(isRegion || plotsMap) || hotspot) && <DrawingManager
						ref={drawingManagerRef}
						onLoad={(manager) => {
							drawingManagerRef.current = manager
							manager.addListener('click', () => { clearSelection() })
						}}
						onOverlayComplete={() => { drawingManagerRef.current.setDrawingMode(null) }}
						onCircleComplete={(circle) => {
							const shapeId = uniqueId('circle-')
							onShapeComplete('circle', circle, shapeId);
							circle.addListener('click', () => {
								setCurrentShape(circle)
								openOptionsWindow(shapeId)
							});
						}}
						onPolygonComplete={(polygon) => {
							const shapeId = uniqueId('polygon-')
							onShapeComplete('polygon', polygon, shapeId)
							polygon.addListener('click', () => {
								setCurrentShape(polygon)
								openOptionsWindow(shapeId)
							});
						}}
						options={{
							drawingControl: true,
							drawingControlOptions: {
								position: window.google.maps.ControlPosition.BOTTOM_CENTER,
								drawingModes: [
									window.google.maps.drawing.OverlayType.CIRCLE,
									window.google.maps.drawing.OverlayType.POLYGON,
									// window.google.maps.drawing.OverlayType.RECTANGLE,
								],
							},
							circleOptions: GOOGLE_SHAPE_OPTIONS,
							polygonOptions: GOOGLE_SHAPE_OPTIONS,
						}}
					/>
				}
				<Autocomplete
					onLoad={onAutocompleteLoad}
					onPlaceChanged={onPlaceChanged}
				>
					<div id='google-maps-autocomplete-container'>
						<input
							type="text"
							placeholder="Search"
							id='google-maps-autocomplete-input'
						/>
						<Search style={{
							position: 'absolute',
							top: 8.5,
							right: 10,
							color: 'gray'
						}} fontSize={'small'} />
					</div>
				</Autocomplete>
				{!buttonDisabled &&
					<MarkerClusterer minimumClusterSize={50} styles={clusterStyles as any}>
						{(markerClusterer: any) => (
							<>
								{markers!.map((marker, index) => {

									/**
									 * if marker doesn't have location
									 *   - render empty div
									 */
									const hasLocation = Boolean(marker?.location);

									if (!hasLocation) {
										return <div key={'filter-map-marker-' + index}></div>;
									}


									/**
									 * marker's position and common props
									 */
									const markerPosition = { lat: marker?.location?.lat ?? 0, lng: marker.location.lng } 
									const MarkersCommonProps = {
										position: markerPosition,
										draggable: dragMarker,
										onDragEnd: handleMarkerDragEnd,
										clusterer: markerClusterer
									}

									/**
									 * check for rendering marker without value, i.e google's default marker
									 */
									const showGooglesDefaultMarker = plotsMap || hotspot || props.singlePlot;
									if (showGooglesDefaultMarker) {
										return (
											<Marker
												key={`plots-map-marker-${index}`}
												onClick={() => hotspot || props.singlePlot ? openInfoWindow(marker) : openPlotsMapInfoWindow(marker)}
												{...MarkersCommonProps}
											/>
										)
									}

									let markerValue: any = Number(marker.value?.toFixed(0) || '0');
									const isPlotData = !!marker?.isPlotData;
									const willValueOverflow = (!!markerValue && markerValue) >= 1000;
									const markerText = willValueOverflow ?
										(markerValue / 1000).toFixed(0) + 'K' :
										markerValue.toString();
										
									return (
										// default markers
										<Marker
											key={'filter-map-marker-' + index}
											label={{
												text:isPlotData ? ' ' : markerText,
												color: isPlotData ? 'red' : 'black',
												fontWeight: '500',

											}}
											onClick={() => openInfoWindow(marker)}
											icon={markerIcon(marker)}
											{...MarkersCommonProps}
										/>
									);
								})}
							</>
						)}
					</MarkerClusterer>
				}
				{/* plot sensor details info model */}
				{showPlotSensorInfoModal && !!plotSensorData && plotSensorData.location && (
					<InfoWindow
						position={{ lat: plotSensorData.location?.lat, lng: plotSensorData.location?.lng }} onCloseClick={() => setShowPlotSensorInfoModal(false)}
					>
						<PlotSensorDataInfoModal plotSensorData={plotSensorData} />
					</InfoWindow>
				)}

				{/* plot details info model */}
				{showinfo && infodata.location && (
					<InfoWindow
						position={{ lat: infodata.location?.lat, lng: infodata.location?.lng }}
						onCloseClick={() => setShowinfo(false)}
						options={{
							pixelOffset: new window.google.maps.Size(0, -20),
						}}
					>
						<PlotInfoModal
							infodata={infodata}
							setDragMarker={setDragMarker}
							setGetndviImage={setGetndviImage}
							setGetndviImageCrop={setGetndviImageCrop}
							setGetndviImageFarmId={setGetndviImageFarmId}
							setGetndviImagePlotId={setGetndviImagePlotId}
							setIsToastOpen={setIsToastOpen}
							setShowinfo={setShowinfo}
							setToastMessage={setToastMessage}
							viewChartForPlot={viewChartForPlot}
						/>
					</InfoWindow>
				)}

				{/* region marker info model */}
				{showRegionsInfoModal && !!regionsModalInfo && regionsModalInfo!.location && (
					<InfoWindow
						position={{ lat: regionsModalInfo!.location?.lat, lng: regionsModalInfo.location?.lng }}
						onCloseClick={() => setShowRegionsInfoModal(false)}
						options={{
							pixelOffset: new window.google.maps.Size(0, -20),
						}}
					>
						<RegionInfoModal viewChartForRegion={viewChartForRegion} regionsModalInfo={regionsModalInfo} />
					</InfoWindow>
				)}


				{/* info model for selected/drawn region */}
				{showOptionsModal && !!optionsModalLocation && (
					<InfoWindow
						position={optionsModalLocation}
						onCloseClick={() => setShowOptionsModal('')}
					>
						<DrawnRegionInfoModal
							deleteSelectedShape={deleteSelectedShape}
							saveGeolocation={saveGeolocation}
							showOptionsModal={showOptionsModal}
							viewCharts={viewCharts}
							viewPrediction={viewPrediction}
							viewSelectedMarkersInGrid={viewSelectedMarkersInGrid}
							hotspot={hotspot}
						/>
					</InfoWindow>
				)}

				{plotsWithGeoBoundaries.map((plot: any, ind: number) => {
					const corrdArr: Array<TLocation> = []
					plot.geoBoundary.coordinates[0]?.map((coordinate: Coordinates) => corrdArr.push({ lat: coordinate[1], lng: coordinate[0] }))
					return (
						<Polygon
							key={`plot-with-polygon-${ind}`}
							path={corrdArr}
							options={{
								strokeColor: 'orange',
								strokeOpacity: 0.4,
								strokeWeight: 0.5,
								fillColor: 'orange',
								fillOpacity: 0.6
							}}
						/>
					)
				})}

				{isRegion && (
					<Grid zIndex={10} bgcolor={'white'} position={'absolute'} top={'60px'} left={'10px'} borderRadius={2} >
						<DatePicker
							onChange={dateHandler}
							value={regionDate as any}
							maxDate={dayjs(new Date()).add(15, 'day').toDate()}
							label={''}
						/>
						{isTodaysDate && filterApplied !== 'crop' &&
							<Grid display={'flex'} justifyContent={'center'} mb={1} px={0.5}>
								<RegionDatePickerButton
									onClick={() => {
										setTodaysTime((time) => dayjs(time).add(-1, 'hour').minute(30).toDate())
									}}
									label={dayjs(todaysTime).tz('Asia/Kolkata').add(TIMEZONE_OFFSET, 'hour').format('hh a')}
									disabled={dayjs(todaysTime).add(-1, 'hour').hour() === 23}
								/>
								<RegionDatePickerButton
									variant='contained'
									onClick={() => { }}
									label={dayjs(todaysTime).tz('Asia/Kolkata').add(TIMEZONE_OFFSET + 1, 'hour').format('hh a')}
								/>
								<RegionDatePickerButton
									onClick={() => setTodaysTime((time) => dayjs(time).add(1, 'hour').minute(30).toDate())}
									label={dayjs(todaysTime).tz('Asia/Kolkata').add(TIMEZONE_OFFSET + 2, 'hour').format('hh a')}
									disabled={dayjs(todaysTime).add(2 + TIMEZONE_OFFSET, 'hour').hour() === 0}
								/>
							</Grid>
						}
						<Grid display={'flex'} justifyContent={'center'} mb={1} px={0.5}>
							<RegionDatePickerButton
								onClick={() => dateHandler(dayjs(regionDate).add(-1, 'day').toDate())}
								label={dayjs(regionDate).add(-1, 'day').format('DD.MM.YYYY')}
							/>
							<RegionDatePickerButton
								onClick={() => dateHandler(dayjs(regionDate).add(1, 'day').toDate())}
								label={dayjs(regionDate).add(1, 'day').format('DD.MM.YYYY')}
								disabled={dayjs(regionDate).isAfter(dayjs(new Date()).add(13, 'day'))}
							/>
						</Grid>
					</Grid>
				)}

				{
					(!plotsMap && !hotspot && filterApplied !== 'geoBoundary') && (
						<>
							<div id="legend"></div>
							<div id="google-maps-date-overlay"></div>
							<div id="google-maps-no-data"></div>
							<div id="google-maps-plots-cumulative-area">
								<Typography fontSize={14} fontWeight={'700'} >Total Area: </Typography> <Typography id="cumulative-area-text" fontSize={14} ml={0.5}></Typography>
							</div>

							<div id="google-maps-selected-region-info-container">
								<span>
									<Typography id='google-maps-selected-region-info-label' fontSize={16} fontWeight={700} textTransform={'capitalize'} ></Typography>
									<Typography id='google-maps-selected-region-info-count' fontSize={16} fontWeight={700} textTransform={'capitalize'} ml={3} ></Typography>
								</span>
								<div>
									<span>
										<Typography fontSize={14} fontWeight={700} marginRight={0.5}> Min: </Typography>
										<Typography id="google-maps-selected-region-min" fontSize={14} > </Typography>
									</span>
									<span>
										<Typography fontSize={14} fontWeight={700} marginRight={0.5}> Max: </Typography>
										<Typography id="google-maps-selected-region-max" fontSize={14} > </Typography>
									</span>
									<span>
										<Typography fontSize={14} fontWeight={700} marginRight={0.5}> Avg: </Typography>
										<Typography id="google-maps-selected-region-avg" fontSize={14} > </Typography>
									</span>
								</div>
							</div>
						</>
					)
				}

				{
					dragMarker && draggedPosition && (
						<Grid position={'absolute'} left={10} bottom={15}>
							<ButtonGroup variant='contained' size='small'>
								<Button
									sx={{
										backgroundColor: 'white',
										color: 'red',
										'&:hover': {
											backgroundColor: 'rgba(255, 0, 0, 0.7)',
											color: 'white'
										}
									}}
									onClick={cancelMarkerPositionChange}
								>
									Cancel
								</Button>
								<Button
									sx={{
										backgroundColor: 'white',
										color: 'green',
										'&:hover': {
											backgroundColor: 'rgba(0, 255, 0, 0.7)',
											color: 'white'
										}
									}}
									onClick={saveDraggedPosition}
								>
									Save
								</Button>
							</ButtonGroup>
						</Grid>
					)
				}
			</GoogleMap>

			{
				markersCovered.length > 0 && <AddGeolocationModal handleGeoBoundaryUpdate={handleGeoBoundaryUpdate} isOpen={isOpenSaveGeolocationModal} setIsOpen={setIsOpenSaveGeolocationModal} markersCovered={markersCovered} boundaryCoords={shapePath} />
			}

			<ToastAlert message={toastMessage} severity={toastSeverity} open={isToastOpen} setOpen={setIsToastOpen} />
			<DialogConfirm
				title='Save Location'
				onConfirm={onConfirm}
				onCancel={cancelMarkerPositionChange}
				content={'Do you want to save the location?'}
				open={isConfirmOpen}
				setOpen={setIsConfirmOpen}
			/>
		</>
	) : <></>
}

export default MapWrapper;