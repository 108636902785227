import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { savetranningScheduleData } from "../utils/XLSX";
import { getFarmUserTrainingSchedule } from "../services/farmUser.service";
import { Button, Typography } from "@mui/material";
import { WhatsappModal } from "./Dashboard/components";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DownloadIcon from "@mui/icons-material/Download";
import Loader from "../components/Loader";
import { AgGridReact } from "ag-grid-react";

type RowData = {
  userId: string;
  name: string;
  countryCode: string;
  mobile: string;
  email: string;
  gender: string;
  device: string;
  batchId: string;
  created_date: string;
  installationDate: string;
  language: string;
  [key: string]: any;
};

type TrainingScheduleData = {
  [key: string]: RowData[];
};

const TrainingSchedule: React.FC = () => {
  const [data, setData] = useState<TrainingScheduleData>({});
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<Record<string, number>>({});
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const gridRef = useRef<AgGridReact>(null);
  const [filteredDataWati, setFilteredDataWati] = useState<any[] | null>(null);

  const processData = (dataObj: TrainingScheduleData) => {
    const processedData: any[] = [];
  
    Object.entries(dataObj).forEach(([key, value]) => {
      const [districtWithUnderscores, state, crop] = key.split("_");

      const district = districtWithUnderscores.replace(/_/g, " ");
  
      (value as RowData[]).forEach((row) => {
        const rowData: any = {};
        rowData["Region"] = `${district}, ${state}`;
        rowData["Crop"] = crop;
  
        Object.keys(row).forEach((field) => {
          rowData[field] = row[field] || "";
        });

        processedData.push(rowData);
      });
    });
    return processedData;
  };

  const handleDownload = () => {
    savetranningScheduleData(data);
  };

  const extractLocationsWithCount = (
    dataObj: TrainingScheduleData
  ): Record<string, number> => {
    const locationCountMap: Record<string, number> = {};

    Object.entries(dataObj).forEach(([key, value]) => {
      const [, state] = key.split("_").reverse();
      const count = (value as RowData[]).length;
      locationCountMap[state] = (locationCountMap[state] || 0) + count;
    });

    return locationCountMap;
  };

  const handleLocationClick = (location: string) => {
    setSelectedLocation(location);
    if (location) {
      const filtered = Object.entries(data).filter(([key]) =>
        key.includes(location)
      );
      setFilteredData(processData(Object.fromEntries(filtered)));
    } else {
      setFilteredData(processData(data));
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      handleGridFilter();
    }
  }, [selectedLocation]);

  const handleGridFilter = () => {
    if (gridRef?.current) {
      const rowCount = gridRef.current.api?.getDisplayedRowCount();
      const filteredRows = [];

      for (let i = 0; i < rowCount; i++) {
        const rowNode = gridRef.current.api?.getDisplayedRowAtIndex(i);
        if (rowNode) {
          filteredRows.push(rowNode.data);
        }
      }
      setFilteredDataWati(filteredRows);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const res = await getFarmUserTrainingSchedule();
        setData(res);
        setFilteredData(processData(res));
        const extractedLocations = extractLocationsWithCount(res);
        setLocations(extractedLocations);
      } catch (err: any) {
        console.error("Error fetching data:", err);
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [open, setOpen] = useState<boolean>(false);
  const handleWhatsappBtn = () => {
    setOpen(!open);
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "User ID",
        field: "userId",
        autoHeight: true,
        sortable: true,
        filter: "agTextColumnFilter",
      },
      { headerName: "Name", field: "name", filter: "agTextColumnFilter" },
      {
        headerName: "Country Code",
        field: "countryCode",
        filter: "agTextColumnFilter",
      },
      { headerName: "Mobile", field: "mobile", filter: "agTextColumnFilter" },
      // { headerName: "Email", field: "email", filter: "true" },
      // { headerName: "Gender", field: "gender", filter: "true" },
      {
        headerName: "Region ID",
        field: "regionId",
        filter: "agTextColumnFilter",
      },
      { headerName: "Crop ID", field: "cropId", filter: "agTextColumnFilter" },
      { headerName: "Device", field: "device", filter: "agTextColumnFilter" },
      // { headerName: "Batch ID", field: "batchId", filter: "true" },
      {
        headerName: "Created Date",
        field: "created_date",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Installation Date",
        field: "installationDate",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Language",
        field: "language",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Week",
        field: "week",
        filter: "agTextColumnFilter",
      },
    ],
    []
  );

  const onGridReady = useCallback(() => {
    gridRef.current?.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
    };
  }, []);

  return (
    <div>
      <p
        style={{
          textAlign: "center",
          fontSize: "1rem",
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        Training Schedule
      </p>

      {error && (
        <p style={{ color: "red" }}>{error} Please Refresh the page!!</p>
      )}

      {loading ? (
        <Loader message="Loading Training Schedule ..." />
      ) : (
        <div>
          {/* fileter and download button  */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              marginBottom: "10px",
            }}
          >

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  margin: "6px 10px",
                  fontWeight: "600",
                  textDecoration: "underline",
                  fontStyle: "italic",
                }}
              >
                Locations:
              </p>

              {/* Render Locations */}
              {Object.entries(locations).length > 0
                ? Object.entries(locations).map(([key, value], index) => (
                    <div
                      key={index}
                      onClick={() => handleLocationClick(key)}
                      style={{ textDecoration: "inherit", color: "inherit" }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        color="success"
                        style={{
                          margin: "4px 5px",
                          borderRadius: "20px",
                          padding: "0px 10px",
                          backgroundColor:
                            selectedLocation === key ? "#2e7d32" : "",
                        }}
                      >
                        <Typography
                          variant="overline"
                          textTransform={"capitalize"}
                          style={{
                            color: selectedLocation === key ? "white" : "",
                          }}
                        >
                          {key} - {value}
                        </Typography>
                      </Button>
                    </div>
                  ))
                : null}
            </div>

            {/* Create and Download Button */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={handleDownload}
                disabled={!data || loading}
                style={{
                  border: "none",
                  marginRight: "10px",
                  color: !loading ? "green" : "#fff",
                  cursor: loading ? "not-allowed" : "pointer",
                  transition: "background-color 0.3s ease",
                  backgroundColor: "transparent",
                }}
              >
                <DownloadIcon />
              </button>

              <Button
                color="success"
                variant="contained"
                onClick={() => handleWhatsappBtn()}
                sx={{}}
                disabled={!data || loading}
              >
                <WhatsAppIcon />
              </Button>
            </div>
          </div>

          {/* ag-grid */}
          <div
            className="ag-theme-alpine"
            style={{
              height: "80vh",
              width: "100%",
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={filteredData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pagination={true}
              paginationPageSize={10}
              onGridReady={onGridReady}
              onFilterChanged={handleGridFilter}
            />
          </div>

          <WhatsappModal
            open={open}
            filtered_Data={
              filteredDataWati === null ? filteredData : filteredDataWati
            }
            loading={loading}
            handleClose={handleWhatsappBtn}
            flagTraningSchedule={true}
          />
        </div>
      )}
    </div>
  );
};

export default TrainingSchedule;

// mock data
// const mockData: Record<string, any[]> = {
//   "Unknown_Potato": [
//     {
//       userId: "VR242",
//       name: "Priyanhsu",
//       countryCode: "+91",
//       mobile: "7091015947",
//       language: "hi",
//     },
//   ],
// };
