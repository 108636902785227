import React, { useEffect, useRef } from 'react';
import { Grid, Tooltip, Typography } from "@mui/material";
import { getDate, removeUnderScores } from "../../utils/helper";

interface CropStagesProps {
  cropsSown: any;
}

const CropStages: React.FC<CropStagesProps> = ({ cropsSown }): JSX.Element => {
  const { cropStageIds, activeStage, stageStartDate } = cropsSown;

  const stageRefs = useRef<(HTMLElement | null)[]>([]);

  useEffect(() => {
    if (activeStage && stageRefs.current) {
      const activeIndex = cropStageIds.findIndex((stage:any) => stage === activeStage);
      if (activeIndex !== -1 && stageRefs.current[activeIndex]) {
        stageRefs.current[activeIndex]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [activeStage, cropStageIds]);

  return (
    <Grid display={'flex'} flexDirection={'column'}>
      <Typography sx={{ fontWeight: '600', marginBottom: 1, textAlign: 'center' }} width={100}>
        Stages
      </Typography>
      <Grid
        container
        display={'flex'}
        flexDirection={"column"}
        flexWrap={'nowrap'}
        style={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}
        pb={1}
      >
        {cropStageIds?.map((stage: string, ind: number) => {
          const totalDates = stageStartDate?.length;
          const title = ind < totalDates ? getDate(stageStartDate[ind], 'lll') : '';

          const isActiveStage = stage === activeStage;
          const bgColor = isActiveStage ? '#0da00a' : '';
          const color = isActiveStage ? 'white' : '';
          const fontSize = isActiveStage ? 15 : 14;

          return (
            <Tooltip title={title} placement="bottom" key={`crop_stage_${ind}_at_${stage}`}>
              <Typography
                ref={(el) => stageRefs.current[ind] = el}
                px={2} mr={1} mb={1.2}
                borderRadius={10} border={1} borderColor={'#0da00a'}
                color={color}
                bgcolor={bgColor}
                fontSize={fontSize}
                minWidth={150}
                textAlign={'center'}
                textTransform={'capitalize'}
              >
                {removeUnderScores(stage)}
              </Typography>
            </Tooltip>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default CropStages;