import { getData } from "./common.service";
export async function getOrdersByPagination(
  skip: number, 
  limit: number,
  filters: any[] = []
  ): Promise<any[]> {
    const qs: any = {
      limit,
      skip,
      order: "created_date DESC",
    };
    // If filters are provided, build a where clause
    if (filters.length) {
      qs.where = { and: filters };
    }
  const url = `/orders?filter=${encodeURIComponent(JSON.stringify(qs))}`;
  return getData(url, {
    basePath: process.env.REACT_APP_CORE_API,
    sendTokenInHeader: true,
  });
}
export async function getOrdersCount(filters: any[] = []): Promise<number> {
  let url = `/orders/count`;
  if (filters.length) {
    url += `?where=${encodeURIComponent(JSON.stringify({ and: filters }))}`;
  }
  const response = await getData(url, {
    basePath: process.env.REACT_APP_CORE_API,
    sendTokenInHeader: true,
  });
  return response.count || 0;
}
export async function getAllOrdersForExport(): Promise<any[]> {
  const url = `/orders`;
  return getData(url, {
    basePath: process.env.REACT_APP_CORE_API,
    sendTokenInHeader: true,
  });
}
export async function updateOrder(orderId: string, orderData: any): Promise<void> {
  const url = `${process.env.REACT_APP_CORE_API}/orders/${orderId}`;
  const sanitizedOrder = { ...orderData };
  delete sanitizedOrder.id;
  delete sanitizedOrder.createdDate;
  sanitizedOrder.isNewLead = sanitizedOrder.isNewLead === "Yes";
  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify(sanitizedOrder),
  });
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to update order. ${errorText}`);
  }
}