import { Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Select, { SingleValue } from "react-select";
import {
  getCropOptions,
  getCrops,
  getcropsStageBasedOnVariety,
  getCropStages,
  getCropVarieties,
} from "src/services/crop.service";
import { generateOptions, sortByKey } from "src/utils/helper";
import { DropDownProps, DiseaseGridProps } from "./CropFilterBusinessDashboard";
import { KeyValues } from "src/constants/interfaces";
import { Collapsibles } from "../sections/FilterSection";
import dayjs from "dayjs";

const CropVariety: React.FC<any> = (props) => {
  const {
    setCropData,
    isVisible,
    closeRestCollapsiblesExcept,
    handleFilterUpdate,
    setMapsDate,
    setIndexData,
    setGraphType,
    setGraphSubType,
    setIsFilterLoading
  } = props;

  const [crops, setCrops] = useState<SingleValue<any>>([]);
  const [stages, setStages] = useState<SingleValue<any>>([]);
  const [varieties, setVarieties] = useState<SingleValue<any>>([]);

  // selected options
  const [selectedCropOption, setSelectedCropOption] = useState<any>(null);
  const [selectedStageOption, setSelectedStageOption] = useState<any>(null);
  const [selectedVarietyOption, setSelectedVarietyOption] = useState<any>(null);

  // loading states
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true);
  const [isStageLoading, setIsStageLoading] = useState<boolean>(false);
  const [isVarietyLoading, setIsVarietyLoading] = useState<boolean>(false);

  const [isVarietyDisabled, setIsVarietyDisabled] = useState(true);
  const [isStageDisabled, setIsStageDisabled] = useState(true);

  const [showResultBtnLoading, setShowResultBtnLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if(isVisible){
      setIsCropLoading(true);
      getCropOptions()
        .then((res) => {
          setCrops(generateOptions(res, "cropId", "cropName"));
        })
        .catch((error) => {
          console.error("Error fetching crop options:", error);
        })
        .finally(() => setIsCropLoading(false));
    }

  }, [isVisible]);

  const handleCropChange = (selected: SingleValue<any>) => {
    setSelectedCropOption(selected);
    const { value: cropId } = selected;

    unselectOptions(["stage", "variety"]);
    setVarieties([]);
    setStages([]);
    setIsVarietyDisabled(true);
    setIsStageDisabled(true);

    setIsVarietyLoading(true);

    getCropVarieties(cropId)
      .then((res) => {
        const options = generateOptions(res, "id", "name");
        console.log("variety check ", options);
        setVarieties(options);
        setIsVarietyDisabled(options.length === 0);
        setIsStageDisabled(true);

        if (options.length === 0) {
          setIsVarietyDisabled(true)
          getCropStages(cropId).then((res)=>{
            setStages(generateOptions(res, "stageId", "name"));
            setIsStageDisabled(false);
            // setStages(res)
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching crop varieties:", error);
        setVarieties([]);
      })
      .finally(() => setIsVarietyLoading(false));
  };

  const handleVarietyChange = (selected: SingleValue<any>) => {
    setSelectedVarietyOption(selected);
    const { value: varietyId } = selected;

    unselectOptions(["stage"]);
    setStages([]);
    setIsStageDisabled(true);

    if (selectedCropOption) {
      const cropId = selectedCropOption.value;
      setIsStageLoading(true);

      getcropsStageBasedOnVariety(cropId, varietyId)
        .then((res: any[]) => {
          const sortedStages = sortByKey(res, "orderId");
          setStages(generateOptions(sortedStages, "stageId", "name"));
          setIsStageDisabled(false);
        })
        .catch((error) => {
          console.error("Error fetching crop stages:", error);
          setStages([]);
        })
        .finally(() => setIsStageLoading(false));
    }
  };

  const handleDropdownChange = (
    dropdown: string,
    selected: SingleValue<any>
  ) => {
    const value = selected?.value;

    switch (dropdown) {
      case "crop":
        if (value) handleCropChange(selected);
        else unselectOptions(["crop", "variety", "stage"]);
        break;
      case "variety":
        if (value) handleVarietyChange(selected);
        else unselectOptions(["variety", "stage"]);
        break;
      case "stage":
        if (value) setSelectedStageOption(selected);
        else unselectOptions(["stage"]);
        break;
      default:
        break;
    }
  };

  const deviceTypeRanges = [
    {
      name: "Nero Unit",
      color: "green",
    },
    {
      name: "Irrigation Unit",
      color: "grey",
    },
    {
      name: "Master Unit",
      color: "yellow",
    },
    {
      name: "Kairo Unit",
      color: "turquoise",
    },
    {
      name: "Weather Unit",
      color: "red",
    },
  ];

  const getColor = (deviceType: any): string => {
    if (deviceType === "WEATHER_UNIT") return "red";
    if (deviceType === "MASTER_UNIT") return "yellow";
    if (deviceType === "IRRIGATION_UNIT") return "grey";
    if (deviceType === "NERO_UNIT") return "green";
    return "blue";
  };
  const unselectOptions = (toUnselect: string[]) => {
    for (const field of toUnselect) {
      if (field === "variety") {
        setSelectedVarietyOption(null);
        setVarieties([]);
      } else if (field === "stage") {
        setSelectedStageOption(null);
        setStages([]);
      }
    }
  };

  const showResults = () => {
    setIsFilterLoading(true);
    setShowResultBtnLoading(true);

    if (!selectedCropOption) {
      alert('Select a crop');
      setShowResultBtnLoading(false); // Reset loading state immediately if validation fails
      return;
    }
  
    let payload: any = {
      cropIds: [selectedCropOption.value],
    };
  
    if (selectedStageOption) {
      payload.stageId = selectedStageOption.value;
    }
  
    if (selectedVarietyOption) {
      payload.varietyId = selectedVarietyOption.value;
    }
  
    getCrops(payload)
      .then((res: KeyValues[]) => {
        setMapsDate(dayjs(new Date()).format("DD/MM/YYYY").toString());
        setGraphType("crop");
        setGraphSubType("airTemp");
        setIndexData([]);
        const plots: any[] = res.map((plots) => {
          const { plotId, location, plotArea, deviceType } = plots;
          return {
            plotId,
            location,
            color: getColor(plots.deviceType),
            plotArea,
            deviceType,
            value: plotArea,
          };
        });
        // console.log(deviceTypeRanges)
        
        setIndexData(deviceTypeRanges);
        setCropData(plots);
        handleFilterUpdate("crop");
      })
      .catch((error) => {
        console.error("Error fetching crops:", error);
        alert("An error occurred while fetching data.");
      })
      .finally(() => {
        setShowResultBtnLoading(false); 
        setIsFilterLoading(false) 
      });
  };
  

  return (
    <Grid mb={3} boxShadow={2} borderRadius={3} px={2}>
      <Grid
        data-testid="dashboard-crop"
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          letterSpacing={0.8}
          variant="overline"
          textTransform={"capitalize"}
          fontSize={20}
        >
          Crop
        </Typography>
        <IconButton
          onClick={() => closeRestCollapsiblesExcept(Collapsibles.variety)}
        >
          {isVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Grid>

      {isVisible && (
        <Grid pb={2}>
          <Grid {...DiseaseGridProps}>
            <Typography variant="subtitle1" mr={3}>
              Crop
            </Typography>
            <Select
              options={crops}
              onChange={(selected: SingleValue<any>) =>
                handleDropdownChange("crop", selected)
              }
              isLoading={isCropLoading}
              isDisabled={isCropLoading}
              value={selectedCropOption}
              {...DropDownProps}
              isClearable={true}
            />
          </Grid>

          <Grid {...DiseaseGridProps}>
            <Typography variant="subtitle1" mr={1}>
              Variety
            </Typography>
            <Select
              options={varieties}
              onChange={(selected: SingleValue<any>) =>
                handleDropdownChange("variety", selected)
              }
              isLoading={isVarietyLoading}
              isDisabled={
                !selectedCropOption || isVarietyLoading || isVarietyDisabled
              }
              value={selectedVarietyOption}
              {...DropDownProps}
              isClearable={true}
            />
          </Grid>

          <Grid {...DiseaseGridProps}>
            <Typography variant="subtitle1" mr={2}>
              Stage
            </Typography>
            <Select
              options={stages}
              onChange={(selected: SingleValue<any>) =>
                handleDropdownChange("stage", selected)
              }
              isLoading={isStageLoading}
              isDisabled={
                !selectedCropOption || isStageDisabled || isStageLoading
              }
              value={selectedStageOption}
              {...DropDownProps}
              isClearable={true}
            />
          </Grid>

          <Grid mt={2}>
            <Button
              disabled={!selectedCropOption || showResultBtnLoading} // Disable button during loading
              variant="outlined"
              fullWidth
              style={{ borderRadius: "20px" }}
              size="large"
              color="success"
              onClick={showResults}
            >
              {showResultBtnLoading ? "Loading..." : "Show Results"}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CropVariety;
