import { Grid, IconButton } from "@mui/material";
import { ChartData } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
import { Mappings, UnitMapping } from "../../constants/WordMapping";
import { getDate } from "../../utils/helper";

interface ChartsProps {
  datafields: any[];
  skipFields?: string[];
  chartData: any[];
  chartWidth?: string | number;
  isDaily?: boolean;
}

const DisplayCharts: React.FC<ChartsProps> = (
  props: ChartsProps
): JSX.Element => {
  const {
    datafields,
    skipFields,
    chartData,
    chartWidth = "45%",
    isDaily = false,
  } = props;

  const footer = (tooltipItems: any) => {
    const { dataIndex } = tooltipItems[0];
    const time = chartData[dataIndex]?.timestamp;
    if (isDaily) return "Date: " + getDate(time, "MMMM Do YYYY");
    return "Time: " + getDate(time, "lll");
  };

  return (
    <Grid container justifyContent={"space-around"}>
      {datafields?.map((field) => {
        if (skipFields?.includes(field)) {
          return <span key={field}></span>;
        }
        const graphData = generateGraphData(field, chartData);
        const options = {
          responsive: true,
          plugins: {
            legend: {
              position: "top" as const,
            },
            title: {
              display: true,
              text: Mappings[field] ?? field,
            },
            tooltip: {
              callbacks: {
                footer: footer,
                // label: function(tooltipItem: any){
                //   return 'custom tool tip'
                // },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
                minRotation: 0,
                maxRotation: 0,
              },
            },
            y: {
              title: {
                color: "green",
                display: true,
                text: UnitMapping[field] ?? "",
              },
              gridLines: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
              },
            },
          },
        };
        return (
          <Grid width={chartWidth} key={field}>
            <Line
              options={options}
              data={graphData as ChartData<"line", any, unknown>}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const generateGraphData = (field: string, data: any[]) => {
  const labels = data?.map((data: any) => getDate(data.timestamp, "DD/MM"));

  const pointBackgroundColors = data?.map((data: any, index) => {
    const value = data[field];
    const riskLevel = data?.[field + "_riskLevel"];
    if (["soilMoisture1", "soilMoisture2"].includes(field)) {
      const min =
        data[
          `minOptimalMoisture${field === "soilMoisture1" ? "1" : "2"}inStages`
        ];
      const max =
        data[
          `maxOptimalMoisture${field === "soilMoisture1" ? "1" : "2"}inStages`
        ];
      const minThreshold =
        data[
          `minThresholdMoisture${field === "soilMoisture1" ? "1" : "2"}inStages`
        ];
      const maxThreshold =
        data[
          `maxThresholdMoisture${field === "soilMoisture1" ? "1" : "2"}inStages`
        ];
      const isOptimal = value >= min && value <= max;
      const isInThresholdRange = value >= minThreshold && value <= maxThreshold;
      if (isOptimal) {
        return "green";
      } else if (isInThresholdRange) {
        return "orange";
      } else {
        return "red";
      }
    } else if (riskLevel) {
      if (riskLevel === "HIGH") {
        return "red";
      } else if (riskLevel === "MEDIUM") {
        return "orange";
      } else if (riskLevel === "NIL") {
        return "grey";
      } else {
        return "green";
      }
    } else {
      return "green";
    }
  });

  return {
    labels,
    datasets: [
      {
        label: Mappings[field] ?? field,
        data: data?.map((data: any) => data[field]),
        borderColor: "grey",
        borderWidth: 1,
        backgroundColor: "green",
        pointBackgroundColor: pointBackgroundColors,
        pointBorderColor: pointBackgroundColors,
        pointBorderWidth: 2,
        pointRadius: 3,
      },
    ],
  };
};

export default DisplayCharts;
