import { CORE_API } from "../constants/api";
import { KeyValues } from "../constants/interfaces";
import { getData, postData, deleteData } from "./common.service";

export const getCrops = async (payload: KeyValues) => {
  const url = '/plots/search-by-crop';
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })

}

export const getCropDiseasesPrediction = async (cropId: string, diseaseId: string, startDate: any, endDate: any, plotids: any) => {
  const url = `/plots/disease-predictions/${cropId}/${diseaseId}?startTime=${startDate}&endTime=${endDate}`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: {
      plotIds: plotids
    }
  })
}

export const getCropPestPrediction = async (cropId: string, pestId: string, startDate: any, endDate: any, plotids: any) => {
  const url = `/plots/pest-predictions/${cropId}/${pestId}?startTime=${startDate}&endTime=${endDate}`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: {
      plotIds: plotids
    }
  })
}


export const getCropOptions = async () => {
  const url = '/crops/supported'
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    apiCache:true
  })

}

export const getCropStages = async (cropId: string) => {

  const url = `/crops/${cropId}/crop-stages?filter=` + JSON.stringify({
    order: 'order ASC',
  });
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getAllCropStages = async (cropId: string) => {

  const url = `/crops/${cropId}/all-crop-stages?filter=` + JSON.stringify({
    order: 'order ASC',
  });
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const postCropStage = async (cropId:any, payload: any) => {
  const url = `/crops/${cropId}/crop-stages`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export async function patchCropStage(payload: any, id: any) {
  const url = `/crop-stages/${id}`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePatchMethod: true,
    body: payload
  })
}

export async function deleteCropStage(id: any) {
  const url = `/crop-stages/${id}`

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}

export const getCropVarieties = async (cropId: string) => {
  const url = `/crops/${cropId}/crop-varieties`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const postCropVariety = async (cropId:any, payload: any) => {
  const url = `/crops/${cropId}/crop-varieties`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export async function deleteCropVariety(varietyId: any) {
  const url = `/crop-varieties/${varietyId}`;

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}

export const getcropsStageBasedOnVariety = async (
  cropId: string,
  varietyId: string | string[]
) => {
  if (!cropId) throw new Error("Invalid cropId: cropId is required.");

  // Determine query parameters based on varietyId
  const isValidVariety =
    varietyId && (!Array.isArray(varietyId) || varietyId.length > 0);

  const varietyCondition = Array.isArray(varietyId)
    ? varietyId[0]
    : varietyId;

  const qs = {
    where: isValidVariety ? { cropVarietyId: varietyCondition } : undefined,
    order: "orderId ASC", // Default order
  };

  const url = `/crops/${cropId}/crop-stages?filter=${encodeURIComponent(
    JSON.stringify(qs)
  )}`;

  try {
    return await getData(url, {
      sendTokenInHeader: true,
      basePath: CORE_API,
    });
  } catch (error) {
    console.error(
      `Error fetching crop stages${
        isValidVariety ? " with varietyId" : " without varietyId"
      }:`,
      error
    );
    throw error;
  }
};



export const getCropDiseases = async (cropId: string) => {
  const url = `/crops/${cropId}/diseases`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const postCropDisease = async (cropId:any, payload: any) => {
  const url = `/crops/${cropId}/diseases`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export async function patchCropDisease(payload: any, id: any) {
  const url = `/diseases/${id}`
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePatchMethod: true,
    body: payload
  })
}

export async function deleteCropDisease(id: any) {
  const url = `/diseases/${id}`

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}

export const getCropPests = async (cropId: string) => {
  const url = `/crops/${cropId}/pests`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const postCropPest = async (cropId:any, payload: any) => {
  const url = `/crops/${cropId}/pests`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export async function patchCropPest(payload: any, id: any) {
  const url = `/pests/${id}`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePatchMethod: true,
    body: payload
  })
}

export async function deleteCropPest(id: any) {
  const url = `/pests/${id}`

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}

export const getPestPrediction = async (cropId: string, id: string, date: any) => {
  const url = `/crops/${cropId}/pests/${id}?date=${date}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getDiseasePrediction = async (cropId: string, id: string, date: any) => {
  const url = `/crops/${cropId}/diseases/${id}?date=${date}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getDailyData = async (id: string, date: any) => {
  const url = `/daily-data?date=${date}&fields=${id}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getFuturePredictionData = async (id: string, date: any, source: string = "") => {
  const url = `/regions/daily-weather-prediction?date=${date}&fields=${id}&source=${source}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getHourlyWeatherPrediction = async (date: any, fields: string) => {
  const url = `/regions/hourly-weather-prediction?date=${date}.745Z&fields=${fields}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}
export const getPesticideConfigs = async (id: string) => {
  const qs = {
    where: {
      cropId: id
    }
  }
  const url = `/pesticide-configs?filter=` + JSON.stringify(qs);
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getPesticideConfigsCondtion = async (id: string) => {

  const url = `/pesticide-configs/${id}/conditions`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}


export const postPesticideConfigs = async (payload: any) => {
  const url = `/pesticide-configs`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export async function putPesticideConfigs(payload: any, id: any) {
  const url = `/pesticide-configs/${id}`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePutMethod: true,
    body: payload
  })
}

export async function deletePesticideConfig(id: any) {
  const url = `/pesticide-configs/${id}`

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true
  })
}

export const getIDPMConfigs = async (id: string) => {
  const qs = {
    where: {
      cropId: id
    }
  }
  const url = `/idpm-configs?filter=` + JSON.stringify(qs);
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}


export const postIDPMConfigs = async (payload: any) => {
  const url = `/idpm-configs`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export async function putIDPMConfigs(payload: any, id: any) {
  const url = `/idpm-configs/${id}`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePutMethod: true,
    body: payload
  })
}

export async function deleteIDPMConfig(id: any) {
  const url = `/idpm-configs/${id}`

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true
  })
}

export const getCropCharacteristicsData = async (id: string) => {
  const filter = {
    where: {
      cropId: id
    }
  }
  const url = `/crop-characteristics?filter=${JSON.stringify(filter)}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export async function putCropCharacteristicsData(payload: any, id: any) {
  const url = `/crop-characteristics/${id}`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePutMethod: true,
    body: payload
  })
}

export async function deleteCropCharacteristicsData(id: any) {
  const url = `/crop-characteristics/${id}`

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true
  })
}

export const postCropCharacteristicsData = async (payload: any) => {
  const url = `/crop-characteristics`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export const getNewCropId = async (oldId: string) => {
  const filterQuery = {
    where: {
      oldId
    }
  }

  const url = "/id-mappers?filter=" + JSON.stringify(filterQuery);
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true
  })
}

export const getSprayConfigs = async (id: string) => {
  const qs = {
    where: {
      cropId: id
    }
  }
  const url = `/spray-configs?filter=` + JSON.stringify(qs);
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}


export const postSprayConfigs = async (payload: any) => {
  const url = `/spray-configs`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export async function putSprayConfigs(payload: any, id: any) {
  const url = `/spray-configs/${id}`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePutMethod: true,
    body: payload
  })
}

export async function deleteSprayConfig(id: any) {
  const url = `/spray-configs/${id}`

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true
  })
}