import { CORE_API } from "../constants/api";
import { KeyValues } from "../constants/interfaces";
import { getData } from "./common.service";
import { Activity } from 'src/utils/types';

export const getMonthlyPlans = async (month: Number, year: Number) => {
    let whereFilter: { [key: string]: any } = {
        and: [
            { month: month },
            { year: year }
        ]
    };

    const filter = JSON.stringify({
        order: 'created_date DESC',
        where: whereFilter,
        include: ['salesActivitys']
    });

    const url = `/monthly-sales-plans?filter=${filter}`;
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    });
};



export const getSalesActivityTypes = async () => {
    const url = `/sales-activity-types`;
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}


export const getSalesActivities = async (planId: string) => {
    const url = `/sales-activities?filter=` + JSON.stringify({
        where: {
            monthlySalesPlanId: planId
        },
        include: ['salesActivityType']
    });
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}
export const getActivities = async (month: number, year: number): Promise<Activity[]> => {
    const filter = JSON.stringify({
        where: {
            and: [
                {
                    created_date: {
                        gte: `${year}-${String(month).padStart(2, "0")}-01T00:00:00.000Z`,
                    },
                },
                {
                    created_date: {
                        lte: `${year}-${String(month).padStart(2, "0")}-31T23:59:59.999Z`,
                    },
                },
            ],
        },
        order: "created_date DESC",
        include: [
            {
                relation: "salesActivityType",
                scope: {
                    fields: {},
                    order: "id DESC",
                },
            },
        ],
    });

    const url = `/sales-activities?filter=${encodeURIComponent(filter)}`;
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
    });
};