import { Grid, IconButton, Typography, Modal, Tabs, Tab } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useNavigate } from "react-router";
import Select, { SingleValue } from "react-select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  getCropOptions,
  getCropVarieties,
  getAllCropStages,
  getCropDiseases,
  getCropPests,
  getCropCharacteristicsData,
} from "src/services/crop.service";
import CropCharacteristicsDetails from "./CropCharacteristicsDetails";
import {
  cropCharacteristicsColDef,
  cropVarietyDef,
  cropStageDef,
  cropDiseaseDef,
  cropPestDef,
} from "src/constants/columnDefs";
import Variety from "../components/Crop/Variety";
import Stage from "../components/Crop/Stage";
import DiseasePest from "../components/Crop/DiseasePest";
import { AgGridReact } from "ag-grid-react";
import { ColDef, FilterChangedEvent, GridOptions, GridReadyEvent, SideBarDef, SortChangedEvent } from "ag-grid-community";
import { isArrayWithLength } from "src/utils/helper";
interface AgGridProps {
  rowData: any;
  columnDef: any;
  setSelectedModalData?: any;
  setMovementOpenModal?: any;
  setLocationModal?: any;
  onFilteredRowsChanged?:any;
}

export const AgGridComponent: React.FC<AgGridProps> = ({ rowData, columnDef, setSelectedModalData, setMovementOpenModal, setLocationModal, onFilteredRowsChanged }) => {
  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const onGridReady = useCallback((params: GridReadyEvent) => {
  }, []);
  const defaultColDef = useMemo<ColDef>(() => {
      return {
          flex: 1,
          resizable: true,
          sortable: true,
          floatingFilter: true,
          menuTabs: ['filterMenuTab'],
      };
  }, []);

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
      return {
          toolPanels: ['filters'],
      };
  }, []);
  const gridOptions: GridOptions = {
      columnDefs: columnDef,
      rowData: rowData,
      rowSelection: 'single',
      animateRows: true,
      overlayLoadingTemplate:
          '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
      onCellClicked: (e) => {
          setSelectedModalData(e.data); setMovementOpenModal(true);
          if(setLocationModal){
              setLocationModal(false)
          }
      }
  };
  const handleColumnSort = (event: SortChangedEvent) => {
      const columns = event.columnApi.getColumnState();
      let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId
      console.log("🚀 ~ handleColumnSort ~ sortedCol:", sortedCol)
  }

  // function to perform server side filtering
  const handleColumnFilter = (event: FilterChangedEvent) => {
      if (event.afterFloatingFilter) { }

      if (gridRef.current) {
       const filteredRows = gridRef.current.api.getRenderedNodes().map((node: any) => node.data);
        if (onFilteredRowsChanged) {
          onFilteredRowsChanged(filteredRows);
        }
      }
    
      
  }

  const onPaginationChanged = useCallback(() => { }, []);
  if(isArrayWithLength(rowData))
  return (
      <div style={gridStyle} className="ag-theme-alpine">
          <Grid p={3}>
              <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDef}
                  onGridReady={onGridReady}
                  defaultColDef={defaultColDef}
                  sideBar={sideBar}
                  gridOptions={gridOptions}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={15}
                  domLayout="autoHeight"
                  onPaginationChanged={onPaginationChanged}
                  onSortChanged={handleColumnSort}
                  onFilterChanged={handleColumnFilter}
              />
          </Grid>
      </div>
  )
  return <></>
}


const CropCharacteristics: React.FC = (): JSX.Element => {
  const [isApplied, setIsApplied] = useState(false);
  const [isError, setisError] = useState(false);
  const [characteristicsData, setCharacteristicsData] = useState([]);
  const [crops, setCrops] = useState<SingleValue<any>>([]);
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true);
  const [selectedCropOption, setSelectedCropOption] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState<any>(null);
  const [varietyModal, setVarietyModal] = useState(false);
  const [stageModal, seStageModal] = useState(false);
  const [diseaseModal, setDiseaseModal] = useState(false);
  const [pestModal, setPestModal] = useState(false);

  const [value, setValue] = useState(0);

  const navigate = useNavigate();

  const onReset = () => {
    setIsApplied(false);
    setisError(false);
    setCharacteristicsData([]);
  };

  const onSubmit = async (type: number, crop: string) => {
    setisError(false);
    setCharacteristicsData([]);

    if (crop?.length === 0) {
      alert("Choose one crop");
      return;
    }

    setIsApplied(true);

    try {
      let res;
      switch (type) {
        case 0:
          res = await getCropVarieties(crop);
          break;
        case 1:
          res = await getAllCropStages(crop);
          break;
        case 2:
          res = await getCropDiseases(crop);
          break;
        case 3:
          res = await getCropPests(crop);
          break;
        case 4:
          res = await getCropCharacteristicsData(crop);
          break;
        default:
          break;
      }
      if (res.length > 0) {
        setCharacteristicsData(res);
      } else {
        setisError(true);
      }
    } catch (err) {
      console.log(err);
      setisError(true);
      setCharacteristicsData([]);
    } finally {
      setIsApplied(false);
    }
  };

  useEffect(() => {
    if (!!selectedCropOption?.value) {
      onSubmit(0, selectedCropOption?.value);
    }
  }, [varietyModal]);


  useEffect(() => {
    if (!!selectedCropOption?.value) {
      onSubmit(1, selectedCropOption?.value);
    }
  }, [stageModal]);


  useEffect(() => {
    if (!!selectedCropOption?.value) {
      onSubmit(2, selectedCropOption?.value);
    }
  }, [diseaseModal]);


  useEffect(() => {
    if (!!selectedCropOption?.value) {
      onSubmit(3, selectedCropOption?.value);
    }
  }, [pestModal]);

  useEffect(() => {
    if (!!selectedCropOption?.value) {
      onSubmit(4, selectedCropOption?.value);
    }
  }, [openModal]);

  useEffect(() => {
    getCropOptions().then((res) => {
      setCrops(res?.map((r: any) => {
        return {
          ...r,
          label: r?.cropName,
          value: r?.cropId
        }
      }));
      setIsCropLoading(false);
    });
  }, []);

  const handleChange = (newValue: number) => {
    setValue(newValue);
    onSubmit(newValue, selectedCropOption?.value);
  };

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={3}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Crop Characteristics
          </Typography>
        </Grid>
        {selectedCropOption?.value && (
          <input
            type="button"
            value={
              value === 0 ? "Add Variety" :
                value === 1 ? "Add Stage" :
                  value === 2 ? "Add Disease" :
                    value === 3 ? "Add Pest" :
                      "Add Characteristic"
            }
            style={{
              width: '15%',
              padding: '10px',
              fontSize: 18,
              paddingTop: '10px',
              alignSelf: 'center',
              backgroundColor: "white",
              borderRadius: 10,
              borderWidth: 1,
            }}
            onClick={() => {
              setSelectedModalData({
                cropId: value === 4 ? selectedCropOption?.cropId : selectedCropOption?.id
              });
              value === 0 ? setVarietyModal(true) :
                value === 1 ? seStageModal(true) :
                  value === 2 ? setDiseaseModal(true) :
                    value === 3 ? setPestModal(true) :
                      setOpenModal(true)
            }}
          ></input>
        )}
      </Grid>

      <Grid>
        <div
          style={{
            backgroundColor: isApplied ? "#B6F7BC" : "#E6E6E6",
            margin: "0px 0 15px 0",
            padding: "5px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" ml={1} fontWeight="500" my={1}>
            Crop Id
          </Typography>
          <Select
            options={crops}
            onChange={(selected: SingleValue<any>) => {
              setSelectedCropOption(selected);
              if (selected?.value && selected?.value?.length > 0) {
                setValue(0);
                onSubmit(0, selected.value);
              } else {
                onReset();
              }
            }}
            isLoading={isCropLoading}
            isDisabled={isCropLoading}
            value={selectedCropOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "25%",
                marginLeft: "5px",
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />
        </div>
      </Grid>

      {selectedCropOption?.value && (
        <Tabs value={value} variant="fullWidth">
          <Tab label="Variety" onClick={() => handleChange(0)} />
          <Tab label="Stage" onClick={() => handleChange(1)} />
          <Tab label="Disease" onClick={() => handleChange(2)} />
          <Tab label="Pest" onClick={() => handleChange(3)} />
          <Tab label="Characteristic" onClick={() => handleChange(4)} />
        </Tabs>
      )}

      <AgGridComponent
        rowData={characteristicsData}
        columnDef={
          value === 0 ? cropVarietyDef : 
            value === 1 ? cropStageDef : 
              value === 2 ? cropDiseaseDef : 
                value === 3 ? cropPestDef : 
                  cropCharacteristicsColDef
        }
        setMovementOpenModal={
          value === 0 ? setVarietyModal : 
            value === 1 ? seStageModal : 
              value === 2 ? setDiseaseModal : 
                value === 3 ? setPestModal : 
                  setOpenModal
        }
        setSelectedModalData={setSelectedModalData}
      />

      {isError && (
        <Typography fontSize={17} color={"red"} margin={3}>
          There seems to be an error. Please try again!
        </Typography>
      )}

      <Modal
        open={varietyModal}
        style={{ overflow: "scroll" }}
        onClose={() => setVarietyModal(false)}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <Variety
            selectedModalData={selectedModalData}
            setOpenModal={setVarietyModal}
            cropOptions={crops}
          />
        </Grid>
      </Modal>

      <Modal
        open={stageModal}
        style={{ overflow: "scroll" }}
        onClose={() => seStageModal(false)}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <Stage
            selectedModalData={selectedModalData}
            setOpenModal={seStageModal}
            cropOptions={crops}
          />
        </Grid>
      </Modal>

      <Modal
        open={diseaseModal || pestModal}
        style={{ overflow: "scroll" }}
        onClose={() => { setDiseaseModal(false); setPestModal(false) }}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <DiseasePest
            selectedModalData={selectedModalData}
            setOpenModal={value === 3 ? setPestModal : setDiseaseModal}
            cropOptions={crops}
            isPest={value === 3}
          />
        </Grid>
      </Modal>

      <Modal
        open={openModal}
        style={{ overflow: "scroll" }}
        onClose={() => setOpenModal(false)}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <CropCharacteristicsDetails
            selectedModalData={selectedModalData}
            setOpenModal={setOpenModal}
          />
        </Grid>
      </Modal>
    </Grid>
  );
};

export default CropCharacteristics;
