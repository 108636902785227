import * as _ from "lodash";
import { useParams } from "react-router";
import { useState, useEffect, useMemo } from "react";
import { CalendarMonthOutlined, Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Typography,
  Select as Dropdown,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import Select, { MultiValue } from "react-select";
import { Option } from "../../constants/interfaces";
import { DatePicker, DisplayCharts, Loader } from "../../components";
import { WindDirectionCharts } from "../../components";
import { Mappings, WindDirectionMapping } from "../../constants/WordMapping";
import { getHistoricalSensorData } from "../../services/plot.service";
import { getDate, print } from "../../utils/helper";
import ZoomPlugin from "chartjs-plugin-zoom";
import { getWindDirectionPrediction } from "../../services/plot.service";
import { saveToXlsxHistorical } from "../../utils/XLSX";
import dayjs from "dayjs";
import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import * as XLSX from "xlsx";
import { AgGridComponent } from "src/pages/CropCharacteristics";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import GridOnIcon from "@mui/icons-material/GridOn";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ZoomPlugin
);

interface PlotID {
  propsPlotId?: string;
}

const HistoricalData: React.FC<PlotID> = (props): JSX.Element => {
  const { propsPlotId } = props;
  let { plotId } = useParams();
  if (propsPlotId !== undefined) {
    plotId = propsPlotId;
  }

  /* Dates
    toDate and fromDate -> states date range of charts
    modalToDate, modalFromDate -> states date range of csv data
  */
  const [toDate, setToDate] = useState<Date>(new Date());
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [modalFromDate, setModalFromDate] = useState<Date | null>(null);
  const [modalToDate, setModalToDate] = useState<Date | null>(null);
  const [isChartView, setIsChartView] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* data fields -> in naam ke charts banenge 
      - string[]: ['field1', 'field2', ...]
      all fields are without mapping
  */
  const [dataFields, setDataFields] = useState<string[]>([]); // for device data i.e soiltemp, etc

  /* charts mein ye data display hoga 
      - any[]:  [
                  {
                    timestamp: '',
                    field1: 23,
                    field2: 12,
                    ...
                  }
                ]
  */
  const [historicalData, setHistoricalData] = useState<any>(); // historicalData.data is any[]
  const [windChartsData, setWindChartsData] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  // dropdown options: all fields of historical data
  const AverageDataFieldOptions: Option[] = [
    {
      label: Mappings.soilMoisture1,
      value: "soilMoisture1",
    },
    {
      label: Mappings.soilMoisture2,
      value: "soilMoisture2",
    },
    {
      label: Mappings.moisture1,
      value: "moisture1",
    },
    {
      label: Mappings.moisture2,
      value: "moisture2",
    },
    {
      label: Mappings.moisture3,
      value: "moisture3",
    },
    {
      label: Mappings.moisture4,
      value: "moisture4",
    },
    {
      label: Mappings.airTemp,
      value: "airTemp",
    },
    {
      label: Mappings.airHumidity,
      value: "airHumidity",
    },
    {
      label: Mappings.rainFall,
      value: "rainFall",
    },
    {
      label: Mappings.lightIntensity,
      value: "lightIntensity",
    },
    {
      label: Mappings.windSpeed,
      value: "windSpeed",
    },
    {
      label: Mappings.airPressure,
      value: "airPressure",
    },
    {
      label: Mappings.soilTemp,
      value: "soilTemp",
    },
    {
      label: Mappings.leafWetness,
      value: "leafWetness",
    },
    {
      label: Mappings.battery,
      value: "battery",
    },
    {
      label: Mappings.signalStrength,
      value: "signalStrength",
    },
  ];

  const MinDataFieldOptions: Option[] = [
    {
      label: Mappings.MinSoilMoisture1,
      value: "MinSoilMoisture1",
    },
    {
      label: Mappings.MinSoilMoisture2,
      value: "MinSoilMoisture2",
    },
    {
      label: Mappings.moisture1,
      value: "moisture1",
    },
    {
      label: Mappings.moisture2,
      value: "moisture2",
    },
    {
      label: Mappings.moisture3,
      value: "moisture3",
    },
    {
      label: Mappings.moisture4,
      value: "moisture4",
    },
    {
      label: Mappings.MinAirTemp,
      value: "MinAirTemp",
    },
    {
      label: Mappings.MinAirHumidity,
      value: "MinAirHumidity",
    },
    {
      label: Mappings.rainFall,
      value: "rainFall",
    },
    {
      label: Mappings.MinLux,
      value: "MinLux",
    },
    {
      label: Mappings.MinWindSpeed,
      value: "MinWindSpeed",
    },
    {
      label: Mappings.MinAirPressure,
      value: "MinAirPressure",
    },
    {
      label: Mappings.MinSoilTemp,
      value: "MinSoilTemp",
    },
    {
      label: Mappings.MinLeafWet,
      value: "MinLeafWet",
    },
    {
      label: Mappings.MinBattery,
      value: "MinBattery",
    },
    {
      label: Mappings.MinSignalStrength,
      value: "MinSignalStrength",
    },
  ];

  const MaxDataFieldOptions: Option[] = [
    {
      label: Mappings.MaxSoilMoisture1,
      value: "MaxSoilMoisture1",
    },
    {
      label: Mappings.MaxSoilMoisture2,
      value: "MaxSoilMoisture2",
    },
    {
      label: Mappings.moisture1,
      value: "moisture1",
    },
    {
      label: Mappings.moisture2,
      value: "moisture2",
    },
    {
      label: Mappings.moisture3,
      value: "moisture3",
    },
    {
      label: Mappings.moisture4,
      value: "moisture4",
    },
    {
      label: Mappings.MaxAirTemp,
      value: "MaxAirTemp",
    },
    {
      label: Mappings.MaxAirHumidity,
      value: "MaxAirHumidity",
    },
    {
      label: Mappings.rainFall,
      value: "rainFall",
    },
    {
      label: Mappings.MaxLux,
      value: "MaxLux",
    },
    {
      label: Mappings.MaxWindSpeed,
      value: "MaxWindSpeed",
    },
    {
      label: Mappings.MaxAirPressure,
      value: "MaxAirPressure",
    },
    {
      label: Mappings.MaxSoilTemp,
      value: "MaxSoilTemp",
    },
    {
      label: Mappings.MaxLeafWet,
      value: "MaxLeafWet",
    },
    {
      label: Mappings.MaxBattery,
      value: "MaxBattery",
    },
    {
      label: Mappings.MaxSignalStrength,
      value: "MaxSignalStrength",
    },
  ];

  const [chartData, setChartData] = useState<Option[]>(AverageDataFieldOptions);
  const [chartDataType, setChartDataType] = useState<string>("avg");

  // dropdown options: historical data + disease + pest
  const [allChartsDataFieldOptions, setallChartsDataFieldOptions] =
    useState<Option[]>(chartData);

  /* 

  */
  const [openModal, setOpenModal] = useState<boolean>(false); // if true: modal is opened
  const [isDownloadModal, setIsDownloadModal] = useState<boolean>(false); // states: to download csv
  const [showMergedCharts, setShowMergedCharts] = useState<boolean>(false); // states: display merged charts or all charts
  const [selectedFieldsToMerge, setSelectedFieldsToMerge] = useState<
    MultiValue<any>
  >([]);
  const [daysCount, setDaysCount] = useState<number>(7);
  const [chartTypeDaily, setChartTypeDaily] = useState<boolean>(false);

  const handleModalClose = () => {
    setOpenModal(false);
    setIsDownloadModal(false);
  };

  const handleFromDate = (days = 7) => {
    setDaysCount(days);

    const newPrevDate = new Date(
      toDate.getFullYear() - (days === 365 ? 1 : 0),
      toDate.getMonth() - (days === 30 ? 1 : 0),
      toDate.getDate() - (days === 1 ? 1 : days === 7 ? 7 : 0)
    );
    setFromDate(newPrevDate);
    setToDate(new Date());
  };

  useEffect(() => {
    handleFromDate();
  }, []);

  // fetches chart data on every plotId and Date changes
  useEffect(() => {
    setIsLoading(true);
    let newFromDate = moment(
      moment(fromDate).toDate().setHours(0, 0, 0, 0)
    ).toDate();
    const newToDate = moment(toDate).endOf("day").toDate();
    setModalFromDate(newFromDate);
    setModalToDate(newToDate);

    // getWindDirectionPrediction(plotId!, newFromDate, newToDate).then((res) => {
    //   const finalData = res
    //     ?.map((val: { windDirection: string; windSpeed?: number }) => {
    //       const mappedDirection = WindDirectionMapping[val.windDirection];
    //       return mappedDirection
    //         ? {
    //             ...val,
    //             windDirection: mappedDirection,
    //             windSpeed: 10,
    //           }
    //         : null;
    //     })
    //     .filter((val: any) => val !== null);
    //   setWindChartsData(finalData);
    // });

    fetchDeviceData(
      plotId!,
      moment(newFromDate).toISOString(),
      moment(newToDate).toISOString()
    );
  }, [plotId, fromDate, toDate, isChartView, chartTypeDaily, chartData]);

  useEffect(() => {
    setChartData(
      chartDataType === "avg"
        ? AverageDataFieldOptions
        : chartDataType === "min"
        ? MinDataFieldOptions
        : MaxDataFieldOptions
    );
  }, [chartDataType]);

  const fetchDeviceData = async (
    plotId: string,
    newFromDate: string,
    newToDate: string
  ) => {
    let res;

    if (chartTypeDaily) {
      res = await getHistoricalSensorData(
        plotId,
        newFromDate,
        newToDate,
        "daily"
      );
    } else {
      res = await getHistoricalSensorData(
        plotId,
        newFromDate,
        newToDate,
        "hourly"
      );
    }

    setHistoricalData(res);

    const uniqueKeys = new Set();

    if (Array.isArray(res.data)) {
      res.data.forEach((item: any) => {
        Object.keys(item).forEach((key) => uniqueKeys.add(key));
      });
    }

    const allUniqueKeys = Array.from(uniqueKeys);

    const chartDataValues =
      chartData?.map((field) => field.value as string) || [];

    const tempDataFields = chartDataValues.filter((field) =>
      allUniqueKeys.includes(field)
    );
    setDataFields(tempDataFields);
    setIsLoading(false);
  };

  useEffect(() => {
    // console.log('all charts data fields', allChartsDataFieldOptions)
  }, [allChartsDataFieldOptions]);

  const getCommonLabels = (combinedLabels: string[], labels: string[]) => {
    if (combinedLabels.length > 0) {
      return _.intersection(combinedLabels, labels);
    }
    return labels;
  };

  // Merge Chart: generates Chart data for selected options from dropdown
  const generateGraphData = (field: MultiValue<any>) => {
    const timeFormat = daysCount !== 1 ? "lll" : "MMMM Do YYYY, h A";
    const labels: string[] = historicalData?.data?.map((data: any) =>
      getDate(data.timestamp, timeFormat)
    );

    const colors = [
      "green",
      "blue",
      "orange",
      "purple",
      "violet",
      "dodgerblue",
      "lightGray",
      "MediumSeaGreen",
      "tomato",
      "cyan",
    ];
    const totalColors = colors.length;

    let stylee: any = [];
    const downArrow = new Image();
    downArrow.src = require("../../assets/down.png");
    downArrow.width = 40;
    downArrow.height = 40;
    const upArrow = new Image();
    upArrow.src = require("../../assets/up.png");
    upArrow.width = 40;
    upArrow.height = 40;
    const westArrow = new Image();
    westArrow.src = require("../../assets/left.png");
    westArrow.width = 40;
    westArrow.height = 40;
    const eastArrow = new Image();
    eastArrow.src = require("../../assets/right.png");
    eastArrow.width = 40;
    eastArrow.height = 40;

    let hasSensors = false;

    const sensorField = AverageDataFieldOptions.concat(MinDataFieldOptions)
      .concat(MaxDataFieldOptions)
      ?.map((field) => field.value as string);

    for (const fld of field) {
      const { value } = fld;
      hasSensors = hasSensors || sensorField.includes(value);
    }

    // jitne labels, utne values
    let combinedLabels: string[] = [];

    if (hasSensors) {
      combinedLabels = getCommonLabels(combinedLabels, labels);
    }

    const datasets = field?.map((fld, ind = 0) => {
      const { value } = fld;
      // if (value === "windSpeed") {
      //   windChartsData?.map((val) => {
      //     if (val.windDirection === "N") stylee.push(upArrow);
      //     else if (val.windDirection === "S") stylee.push(downArrow);
      //     else if (val.windDirection === "E") stylee.push(eastArrow);
      //     else if (val.windDirection === "W") stylee.push(westArrow);
      //   });
      // }
      const data = generateDataOfDataset(value, combinedLabels, timeFormat);
      return {
        label: Mappings[value] ?? value,
        data: data,
        borderColor: colors[ind % totalColors],
        backgroundColor: colors[ind % totalColors],
        borderWidth: value === "windSpeed" ? 1 : 3,
        pointBackgroundColor:
          value === "windSpeed" ? "transparent" : colors[ind % totalColors],
        pointStyle: value === "windSpeed" ? stylee : [],
      };
    });

    return {
      labels: combinedLabels,
      datasets: datasets,
    };
  };

  const generateDataOfDataset = (
    key: string,
    labels: string[],
    timeFormat = "lll"
  ) => {
    const result: any = [];
    const noData = undefined;
    // const noData = '' // use this if want to join all dots (jahan pe data nahi rahega vo 0 ho jaaega)
    for (const time of labels) {
      let flag = 0; // check if data contains object having this time
      for (const data of historicalData?.data) {
        if (getDate(data.timestamp, timeFormat) === time) {
          result.push(data[key]);
          flag = 1;
        }
      }

      if (!flag) {
        result.push(noData);
      }
    }

    return result;
  };

  const loadModalDate = () => {
    if (modalFromDate) setFromDate(modalFromDate);
    if (modalToDate) setToDate(modalToDate);
    handleModalClose();
  };

  const defaultDatePickerPlaceholder = {
    maxDate: new Date(),
    calendarIcon: (
      <IconButton>
        <CalendarMonthOutlined />
      </IconButton>
    ),
    clearIcon: <Clear />,
  };

  const handleFieldSelect = (selected: MultiValue<any>) => {
    setSelectedFieldsToMerge(selected);
  };

  const handleDownloadChartData = () => {
    setOpenModal(true);
    setIsDownloadModal(true);
  };

  const downloadChartData = () => {
    getHistoricalSensorData(
      plotId!,
      moment(moment(modalFromDate).toDate().setHours(0, 0, 0, 0))
        .toDate()!
        .toISOString(),
      moment(modalToDate).endOf("day").toDate().toISOString()!,
      !!chartTypeDaily ? "daily" : "hourly"
    ).then((res: any) => {
      saveToXlsxHistorical(
        res,
        `${plotId}-${moment(modalFromDate).format("DD/MMM/YYYY")}-${moment(
          modalToDate
        ).format("DD/MMM/YYYY")}`,
        chartTypeDaily
      );

      setOpenModal(false);
      setIsDownloadModal(false);
    });
  };

  const filteredOptions = useMemo(() => {
    if (!historicalData?.data?.[0]) return [];
    return Object.keys(historicalData?.data?.[0])
      ?.filter((k) => k && Mappings?.hasOwnProperty(k))
      ?.map((k) => ({ label: Mappings[k], value: k }));
  }, [historicalData]);

  let defaultCoulumn: string[] = [
    "timestamp",
    "soilMoisture1",
    "soilMoisture2",
    "moisture1",
    "moisture2",
    "moisture3",
    "moisture4",
    "airTemp",
    "airHumidity",
    "rainFall",
    "lightIntensity",
    "windSpeed",
    "airPressure",
    "soilTemp",
    "leafWetness",
    "battery",
    "signalStrength",
  ];

  useEffect(() => {
    setSelectedOptions(
      filteredOptions?.filter((option) =>
        defaultCoulumn?.includes(option.value)
      )
    );
  }, [filteredOptions]);

  const sensorDef = useMemo(() => {
    return selectedOptions?.map((option: any) => ({
      headerName: option.label,
      field: option.value,
      autoHeight: true,
      sortable: true,
      filter: "agTextColumnFilter",
      minWidth: 230,
      valueFormatter: (params: any) => {
        if (option.value === "timestamp") {
          return dayjs(params.value).format("LLL");
        }
        return params.value;
      },
    }));
  }, [selectedOptions]);

  return (
    <Grid>
      {/* modal */}
      <Modal
        open={openModal}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box bgcolor={"white"} p={3} width={700}>
          <>
            <Grid container justifyContent={"space-evenly"}>
              <div>
                <Typography>From Date</Typography>
                <DatePicker
                  {...defaultDatePickerPlaceholder}
                  onChange={setModalFromDate}
                  value={modalFromDate}
                />
              </div>
              <div>
                <Typography>To Date</Typography>
                <DatePicker
                  {...defaultDatePickerPlaceholder}
                  onChange={setModalToDate}
                  value={modalToDate}
                />
              </div>
            </Grid>
            <Grid container justifyContent={"space-evenly"} sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                onClick={isDownloadModal ? downloadChartData : loadModalDate}
              >
                {" "}
                {isDownloadModal ? "Doownload" : "Load"} Data
              </Button>
              <Button variant="contained" onClick={handleModalClose}>
                {" "}
                Cancel{" "}
              </Button>
            </Grid>
          </>
        </Box>
      </Modal>

      <Grid container justifyContent={"space-around"} pb={4}>
        <ButtonGroup color="success">
          <Button
            variant={daysCount === 7 ? "contained" : "outlined"}
            onClick={() => handleFromDate(7)}
          >
            1 Week
          </Button>
          <Button
            variant={daysCount === 30 ? "contained" : "outlined"}
            onClick={() => handleFromDate(30)}
          >
            1 Month
          </Button>
          <Button
            variant={daysCount === 365 ? "contained" : "outlined"}
            onClick={() => handleFromDate(365)}
          >
            1 Year
          </Button>
          <Button
            variant={
              ![1, 7, 30, 365].includes(daysCount) && !isDownloadModal
                ? "contained"
                : "outlined"
            }
            onClick={() => {
              setOpenModal(true);
              setDaysCount(4);
            }}
          >
            Date Range
          </Button>
        </ButtonGroup>
        <ButtonGroup color="success" variant="contained">
          <Button onClick={() => setShowMergedCharts(!showMergedCharts)}>
            {" "}
            {showMergedCharts ? "All Charts" : "Merge Charts"}{" "}
          </Button>
          <Button onClick={handleDownloadChartData}>Download</Button>
        </ButtonGroup>
        <Grid
          alignContent={"center"}
          paddingX={2}
          boxShadow={1}
          borderRadius={2}
          justifyContent={"center"}
        >
          <div
            onClick={() => setIsChartView(!isChartView)}
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "space-between",
            }}
          >
            <ShowChartIcon
              style={{
                color: isChartView ? "#0c9611" : "#7d7d7d",
                marginRight: "8px",
              }}
            />
            <GridOnIcon
              style={{ color: !isChartView ? "#0c9611" : "#7d7d7d" }}
            />
          </div>
        </Grid>
        <Dropdown
          style={{ width: "10%" }}
          value={chartTypeDaily ? "daily" : "hourly"}
          onChange={() => setChartTypeDaily(!chartTypeDaily)}
          displayEmpty={true}
          color="success"
          fullWidth={true}
          variant="standard"
        >
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="hourly">Hourly</MenuItem>
        </Dropdown>
        {isChartView && (
          <Dropdown
            style={{ width: "10%" }}
            value={chartDataType}
            onChange={(e) => setChartDataType(e.target.value)}
            displayEmpty={true}
            color="success"
            fullWidth={true}
            variant="standard"
          >
            <MenuItem value="avg">Average</MenuItem>
            <MenuItem value="min">Minimum</MenuItem>
            <MenuItem value="max">Maximum</MenuItem>
          </Dropdown>
        )}
      </Grid>

      <Typography fontSize={15} textAlign={"center"}>
        Data from <span style={{ fontWeight: "600" }}>{print(fromDate)}</span>{" "}
        to <span style={{ fontWeight: "600" }}>{print(toDate)}</span>
      </Typography>

      {isLoading && (
        <div
          style={{
            marginTop: "20vh",
            width: "95%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}

      {!isLoading && (
        <>
          {showMergedCharts ? (
            <Grid mt={3}>
              <Select
                options={allChartsDataFieldOptions
                  .concat(MinDataFieldOptions)
                  .concat(MaxDataFieldOptions)}
                onChange={handleFieldSelect}
                isMulti
              />

              <Grid width={"90%"} mx={"auto"}>
                {selectedFieldsToMerge.length > 0 ? (
                  <Line
                    data={
                      generateGraphData(selectedFieldsToMerge) as ChartData<
                        "line",
                        any,
                        unknown
                      >
                    }
                    options={{
                      responsive: true,
                      scales: {
                        x: {
                          title: {
                            color: "green",
                            display: true,
                            text: "Timestamp",
                          },
                          ticks: {
                            autoSkip: true,
                            maxTicksLimit: 7,
                            minRotation: 90,
                            maxRotation: 90,
                          },
                        },
                        y: { beginAtZero: true },
                      },
                    }}
                  />
                ) : (
                  <Typography
                    variant="h5"
                    textAlign={"center"}
                    my={3}
                    fontWeight={200}
                  >
                    Please select fields to merge
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item width={"100%"}>
                <Typography variant="h5" textAlign={"center"} my={3}>
                  Device Charts
                </Typography>
                {!isChartView ? (
                  <Grid>
                    <Select
                      options={filteredOptions}
                      onChange={setSelectedOptions}
                      value={selectedOptions}
                      isMulti
                      isClearable={true}
                      isSearchable={true}
                      placeholder={"- select -"}
                      styles={{
                        container: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          width: "95%",
                          marginLeft: "5px",
                          border: "1px solid #ccc",
                          borderRadius: 5,
                          marginBottom: 12,
                        }),
                        control: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          borderTop: state.isFocused ? "1px" : "0px",
                          borderLeft: state.isFocused ? "1px" : "0px",
                          borderRight: state.isFocused ? "1px" : "0px",
                        }),
                        menu: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          zIndex: 100,
                        }),
                      }}
                    />
                    <AgGridComponent
                      columnDef={sensorDef}
                      rowData={historicalData?.data}
                    />
                  </Grid>
                ) : (
                  <DisplayCharts
                    datafields={dataFields}
                    skipFields={["timestamp"]}
                    chartData={historicalData?.data}
                    isDaily={chartTypeDaily}
                  />
                )}
              </Grid>

              {/* {isChartView && !!windChartsData.length && (
            <Grid item width={"100%"}>
              <Typography variant="h5" textAlign={"center"} mt={3}>
                Wind Direction
              </Typography>
              <WindDirectionCharts
                datafields={["windSpeed"]}
                chartData={windChartsData}
                chartWidth={"90%"}
              />
              <Typography style={{ fontSize: 18 }} textAlign={"center"}>
                Scroll right to see more
              </Typography>
            </Grid>
          )} */}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
export default HistoricalData;
