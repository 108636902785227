import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import dayjs from "dayjs";
import MonthYearPicker from "src/components/MonthYearPicker";
import { getActivities } from "src/services/sales.service";
import { Activity } from "src/utils/types";

const ActivityDashboard: React.FC = () => {
  const gridRef = useRef<AgGridReact>(null);
  const [selectedDate, setSelectedDate] = useState<{ month: number; year: number }>({
    month: dayjs().month() + 1,
    year: dayjs().year(),
  });
  const [activities, setActivities] = useState<Activity[]>([]);
  const [filteredActivities, setFilteredActivities] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filtersArray, setFiltersArray] = useState<any[]>([]);
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isInsightsOpen, setIsInsightsOpen] = useState(false);

  useEffect(() => {
    const fetchActivities = async () => {
      setIsLoading(true);
      try {
        const fetchedActivities = await getActivities(selectedDate.month, selectedDate.year);
        const formattedActivities = fetchedActivities.map((activity) => ({
          ...activity,
          created_date: dayjs(activity.created_date).format("YYYY-MM-DD"),
        }));
        setActivities(formattedActivities);
        setFilteredActivities(formattedActivities);
      } catch (error) {
        console.error("Error fetching activities:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchActivities();
  }, [selectedDate]);

  const buildFiltersFromModel = (model: any) => {
    const filters: any[] = [];
    Object.keys(model).forEach((field) => {
      const filterValue = model[field].filter;
      if (filterValue && filterValue.trim() !== "") {
        filters.push({ [field]: { regexp: `/${filterValue}/i` } });
      }
    });
    return filters;
  };

  useEffect(() => {
    if (debounceTimer) clearTimeout(debounceTimer);
    const timer = setTimeout(() => {
      setFilteredActivities([...activities]);
    }, 500);
    setDebounceTimer(timer);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [filtersArray, activities]);

  const onFilterChanged = useCallback(() => {
    if (!gridRef.current) return;
    const filterModel = gridRef.current.api.getFilterModel();
    const newFilters = buildFiltersFromModel(filterModel);
    setFiltersArray(newFilters);
  }, [gridRef]);

  const handleThumbnailClick = (images: string[], index: number) => {
    setSelectedImages(images);
    setCurrentImageIndex(index);
  };

  const handleCloseImageViewer = () => {
    setSelectedImages([]);
  };

  const handleDateChange = (date: { month: number; year: number }) => {
    setSelectedDate(date);
  };

  const generateBarChartData = (field: keyof Activity) => {
    const dataMap: Record<string, number> = {};
    activities.forEach((activity) => {
      const key = (activity[field] as any)?.description || activity[field] || "Unknown";
      dataMap[key] = (dataMap[key] || 0) + 1;
    });

    const sortedData = Object.entries(dataMap).sort((a, b) => a[1] - b[1]);

    return {
      labels: sortedData.map(([label]) => label),
      datasets: [
        {
          label: "Count",
          data: sortedData.map(([, value]) => value),
          backgroundColor: "#4CAF50",
        },
      ],
    };
  };

  const columnDefs = [
    {
      headerName: "Date",
      field: "created_date",
      flex: 1,
      filter: "agTextColumnFilter",
      valueFormatter: (params: any) => params.value,
    },
    {
      headerName: "Activity Type",
      field: "salesActivityType.description",
      flex: 1.5,
      filter: "agTextColumnFilter",
      tooltipField: "salesActivityType.description"
    },
    {
      headerName: "Comment",
      field: "comment",
      flex: 4,
      filter: "agTextColumnFilter",
      tooltipField: "comment"
    },
    {
      headerName: "Serviced By",
      field: "servicedBy",
      flex: 1,
      filter: "agTextColumnFilter",
      tooltipField: "servicedBy"
    },
    {
      headerName: "Location",
      field: "location.region",
      flex: 1,
      filter: "agTextColumnFilter",
      tooltipField: "location.region"
    },
    {
      headerName: "Images",
      field: "attachments.thumbnails",
      flex: 1,
      cellRenderer: (params: any) => {
        if (!params.value || params.value.length === 0) return "-";
        const thumbnails = params.value;
        const firstThumbnail = thumbnails[0];

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
            onClick={() => handleThumbnailClick(params.data.attachments.images, 0)}
          >
            <img
              src={firstThumbnail}
              alt="Thumbnail"
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            />
            {thumbnails.length > 1 && (
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "105px",
                  backgroundColor: "rgba(85, 82, 82, 0.75)",
                  color: "white",
                  fontSize: "10px",
                  padding: "2px 6px",
                  borderRadius: "50px",
                  fontWeight: "bold",
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
                  transform: "translate(50%, -50%)",
                }}
              >
                +{thumbnails.length - 1}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container flexDirection="column" style={{ padding: "20px" }}>
        <Grid container justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5">Monthly Activities</Typography>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIsInsightsOpen(true)}
              style={{ marginRight: "10px" }}
            >
              Insights
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => gridRef.current?.api.exportDataAsCsv()}
            >
              Export Activities
            </Button>
          </div>
        </Grid>
        <Grid container alignItems="center" mb={2}>
          <Typography variant="h6" mr={2}>
            Select Month and Year:
          </Typography>
          <MonthYearPicker value={selectedDate} onChange={handleDateChange} />
        </Grid>

        {isLoading ? (
          <Grid container justifyContent="center" alignItems="center" style={{ height: "20vh" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <div className="ag-theme-alpine" style={{ flex: 1, width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              rowData={filteredActivities}
              columnDefs={columnDefs}
              defaultColDef={{
                resizable: true,
                sortable: true,
                floatingFilter: true,
              }}
              onFilterChanged={onFilterChanged}
              domLayout="autoHeight"
              pagination
              paginationPageSize={15}
            />
          </div>
        )}

        <Dialog open={!!selectedImages.length} onClose={handleCloseImageViewer} maxWidth="lg">
          <DialogContent style={{ position: "relative", textAlign: "center", backgroundColor: "#000", padding: "0", }}>
          <IconButton
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                borderRadius: "50%",
                padding: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onClick={handleCloseImageViewer}
            >
              <CloseIcon style={{ color: "#000", fontSize: "24px" }} />
            </IconButton>

            {selectedImages.length > 0 && (
              <>
                <IconButton
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: 10,
                    transform: "translateY(-50%)",
                    zIndex: 10,
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    borderRadius: "50%",
                  }}
                  onClick={() => setCurrentImageIndex((prev) => Math.max(prev - 1, 0))}
                  disabled={currentImageIndex === 0}
                >
                  <ArrowBackIcon />
                </IconButton>

                <img
                  src={selectedImages[currentImageIndex]}
                  alt="Full Image"
                  style={{ maxWidth: "100%", maxHeight: "80vh" }}
                />

                <IconButton
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: 10,
                    transform: "translateY(-50%)",
                    zIndex: 10,
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    borderRadius: "50%",
                  }}
                  onClick={() =>
                    setCurrentImageIndex((prev) =>
                      Math.min(prev + 1, selectedImages.length - 1)
                    )
                  }
                  disabled={currentImageIndex === selectedImages.length - 1}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </>
            )}
          </DialogContent>
        </Dialog>

        {/* Insights Modal */}
        <Dialog
          open={isInsightsOpen}
          onClose={() => setIsInsightsOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            <IconButton
              style={{ position: "absolute", top: 10, right: 10 }}
              onClick={() => setIsInsightsOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" align="center" gutterBottom>
              Insights
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" align="center">
                  Activity Types
                </Typography>
                <Bar data={generateBarChartData("salesActivityType")} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" align="center">
                  Serviced By
                </Typography>
                <Bar data={generateBarChartData("servicedBy")} />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

export default ActivityDashboard;
