import { MyContext } from 'src/pages/PlotDetails';
import { Typography } from '@mui/material';
import React, { useContext } from 'react'
import { useLocation } from 'react-router'

type Props = {}

const Header: React.FC<Props> = (props) => {

  const details = useContext(MyContext);


  const location = useLocation();

  const { plotId } = location.state as any ?? details;

  return (
    <div style={{ margin: "10px" }}>
      <Typography>Plot: </Typography>
      <Typography variant="subtitle1" fontSize={18}>{plotId}</Typography>
    </div>
  )
}

export default Header;