import * as _ from "lodash";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { CalendarMonthOutlined, Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Modal,
  Typography,
  Select as Dropdown,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { DatePicker, DisplayCharts, Loader } from "../../components";
import ZoomPlugin from "chartjs-plugin-zoom";
import {
  getHistoricalDiseasePrediction,
  getHistoricalPestPrediction,
} from "../../services/plot.service";
import dayjs from "dayjs";
import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import * as XLSX from "xlsx";
import { AgGridComponent } from "src/pages/CropCharacteristics";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import GridOnIcon from "@mui/icons-material/GridOn";
import { Download } from "@mui/icons-material";
import { print } from "../../utils/helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ZoomPlugin
);

interface Props {
  propsPlotId?: string;
  isPest?: boolean;
}

const DiseasePestData: React.FC<Props> = (props): JSX.Element => {
  const { propsPlotId, isPest = false } = props;
  let { plotId } = useParams();
  if (propsPlotId !== undefined) {
    plotId = propsPlotId;
  }
  const [toDate, setToDate] = useState<Date>(new Date());
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [modalFromDate, setModalFromDate] = useState<Date | null>(null);
  const [modalToDate, setModalToDate] = useState<Date | null>(null);
  const [isChartView, setIsChartView] = useState<boolean>(true);
  const [chartsFields, setChartsFields] = useState<string[]>([]);
  const [chartsData, setChartsData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDownloadModal, setIsDownloadModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const [daysCount, setDaysCount] = useState<number>(7);

  const handleModalClose = () => {
    setOpenModal(false);
    setIsDownloadModal(false);
  };

  const handleFromDate = (days = 7) => {
    setDaysCount(days);

    const newPrevDate = new Date(
      toDate.getFullYear() - (days === 365 ? 1 : 0),
      toDate.getMonth() - (days === 30 ? 1 : 0),
      toDate.getDate() - (days === 1 ? 1 : days === 7 ? 7 : 0)
    );
    setFromDate(newPrevDate);
    setToDate(new Date());
  };

  useEffect(() => {
    handleFromDate();
  }, []);

  const getConvertedData = (data: any[], isDisease = true) => {
    let result: any[] = [];
    data?.map((disease) => {
      for (let ds of disease.data) {
        const { calculatedDate, probability, diseaseId, pestId, riskLevel } =
          ds;
        const timestamp = moment(calculatedDate).format("LL");

        let flag = 0;
        result?.map((diseaseData) => {
          if (diseaseData.timestamp === timestamp) {
            diseaseData[isDisease ? diseaseId : pestId] = probability;
            diseaseData[
              isDisease ? diseaseId + "_riskLevel" : pestId + "_riskLevel"
            ] = riskLevel;
            flag = 1;
          }
        });

        if (!flag) {
          let x: any = { timestamp };
          x[isDisease ? diseaseId : pestId] = probability;
          x[isDisease ? diseaseId + "_riskLevel" : pestId + "_riskLevel"] =
            riskLevel;
          result.push(x);
        }
      }
    });
    setIsLoading(false);
    return result;
  };

  useEffect(() => {
    setIsLoading(true);
    let newFromDate = moment(
      moment(fromDate).toDate().setHours(0, 0, 0, 0)
    ).toDate();
    const newToDate = moment(toDate).endOf("day").toDate();
    setModalFromDate(newFromDate);
    setModalToDate(newToDate);

    if (isPest) {
      getHistoricalPestPrediction(plotId!, newFromDate, newToDate).then(
        (res) => {
          const tempPestFields = res.map((pest: any) => pest.id);
          const convertedData = getConvertedData(res, false);
          setChartsFields(tempPestFields);
          setChartsData(convertedData);
        }
      );
    } else {
      getHistoricalDiseasePrediction(plotId!, newFromDate, newToDate).then(
        (res) => {
          const tempDiseaseFields = res?.map((disease: any) => disease.id);
          const convertedData = getConvertedData(res);
          setChartsFields(tempDiseaseFields);
          setChartsData(convertedData);
        }
      );
    }
  }, [plotId, fromDate, toDate, isChartView]);

  const loadModalDate = () => {
    if (modalFromDate) setFromDate(modalFromDate);
    if (modalToDate) setToDate(modalToDate);
    handleModalClose();
  };

  const defaultDatePickerPlaceholder = {
    maxDate: new Date(),
    calendarIcon: (
      <IconButton>
        <CalendarMonthOutlined />
      </IconButton>
    ),
    clearIcon: <Clear />,
  };

  const diseasePestDef = (type: string) => {
    const def: any[] = [
      {
        headerName: "Date",
        field: "timestamp",
        autoHeight: true,
        sortable: true,
        filter: "agTextColumnFilter",
        minWidth: 230,
        valueFormatter: (params: any) => {
          return dayjs(params.value).format("LLL");
        },
      },
    ];

    Object.keys(chartsData[0] ?? {}).forEach((d: any) => {
      if (d !== "timestamp" && !d.endsWith("_riskLevel")) {
        def.push({
          headerName: d,
          field: d,
          autoHeight: true,
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 230,
          valueFormatter: (params: any) => {
            const riskLevel = params?.data[`${d}` + `_riskLevel`];
            return `${Number(params?.value).toFixed(2)} (${riskLevel})`;
          },
        });
      }
    });
    return def;
  };

  const downloadDiseasePestData = async (isPest = false) => {
    try {
      const workbook = XLSX.utils.book_new();
      chartsFields.forEach((field: any) => {
        const fieldData = chartsData
          .filter((item: any) => item[field] !== undefined)
          .map((item: any) => ({
            Date: item.timestamp,
            Probability: Number(item[field]).toFixed(2),
            RiskLevel: item[field + "_riskLevel"],
          }));

        const worksheet = XLSX.utils.json_to_sheet(fieldData);

        XLSX.utils.book_append_sheet(workbook, worksheet, field);
      });

      XLSX.writeFile(
        workbook,
        `${isPest ? "pest" : "disease"}_data_${dayjs(fromDate).format(
          "DD/MMM/YYYY"
        )}_to_${dayjs(toDate).format("DD/MMM/YYYY")}.xlsx`
      );
    } catch (err: any) {
      alert("Something went wrong");
    }
  };

  return (
    <Grid>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box bgcolor={"white"} p={3} width={700}>
          <>
            <Grid container justifyContent={"space-evenly"}>
              <div>
                <Typography>From Date</Typography>
                <DatePicker
                  {...defaultDatePickerPlaceholder}
                  onChange={setModalFromDate}
                  value={modalFromDate}
                />
              </div>
              <div>
                <Typography>To Date</Typography>
                <DatePicker
                  {...defaultDatePickerPlaceholder}
                  onChange={setModalToDate}
                  value={modalToDate}
                />
              </div>
            </Grid>
            <Grid container justifyContent={"space-evenly"} sx={{ mt: 2 }}>
              <Button variant="outlined" onClick={loadModalDate}>
                {" "}
                Load Data
              </Button>
              <Button variant="contained" onClick={handleModalClose}>
                {" "}
                Cancel{" "}
              </Button>
            </Grid>
          </>
        </Box>
      </Modal>

      <Grid container justifyContent={"space-around"} pb={4}>
        <ButtonGroup color="success">
          <Button
            variant={daysCount === 7 ? "contained" : "outlined"}
            onClick={() => handleFromDate(7)}
          >
            1 Week
          </Button>
          <Button
            variant={daysCount === 30 ? "contained" : "outlined"}
            onClick={() => handleFromDate(30)}
          >
            1 Month
          </Button>
          <Button
            variant={daysCount === 365 ? "contained" : "outlined"}
            onClick={() => handleFromDate(365)}
          >
            1 Year
          </Button>
          <Button
            variant={
              ![1, 7, 30, 365].includes(daysCount) && !isDownloadModal
                ? "contained"
                : "outlined"
            }
            onClick={() => {
              setOpenModal(true);
              setDaysCount(4);
            }}
          >
            Date Range
          </Button>
        </ButtonGroup>
        <Grid
          alignContent={"center"}
          paddingX={2}
          boxShadow={1}
          borderRadius={2}
          justifyContent={"center"}
        >
          <div
            onClick={() => setIsChartView(!isChartView)}
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "space-between",
            }}
          >
            <ShowChartIcon
              style={{
                color: isChartView ? "#0c9611" : "#7d7d7d",
                marginRight: "8px",
              }}
            />
            <GridOnIcon
              style={{ color: !isChartView ? "#0c9611" : "#7d7d7d" }}
            />
          </div>
        </Grid>
      </Grid>

      <Typography fontSize={15} textAlign={"center"}>
        Data from <span style={{ fontWeight: "600" }}>{print(fromDate)}</span>{" "}
        to <span style={{ fontWeight: "600" }}>{print(toDate)}</span>
      </Typography>

      <Grid container>
        <Grid item width={"100%"}>
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignContent={"center"}
            mt={3}
            justifyContent={"center"}
          >
            <Typography variant="h5" textAlign={"center"}>
              {isPest ? "Pest" : "Disease"} Charts
            </Typography>
            <IconButton
              data-testid="download-excel-onHome"
              onClick={() => downloadDiseasePestData()}
            >
              <Download />
            </IconButton>
          </Grid>
          {!isLoading && (
            <>
              {!isChartView ? (
                <AgGridComponent
                  columnDef={diseasePestDef(isPest ? "pest" : "disease")}
                  rowData={chartsData}
                />
              ) : (
                <DisplayCharts
                  datafields={chartsFields}
                  chartData={chartsData}
                />
              )}
            </>
          )}

          {isLoading && (
            <div
              style={{
                marginTop: "20vh",
                width: "95%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DiseasePestData;
