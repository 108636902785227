import { Grid, Typography } from "@mui/material";
import React, { useState, memo } from "react";
import { KeyValues, FilterDataValue, FilterProps } from '../../constants/interfaces'
import { getDevices } from "../../services/device.service";
import FormikApp from '../DynamicForm'
import Select, { SingleValue } from "react-select";

const DeviceFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
  const { handleFilterDataChange, handleRemove, filterId } = props

  const fields = [
    {
      id: "imei",
      label: "Device IMEI",
      placeholder: "Device IMEI",
      type: "text",
      validationType: "string",
      value:'',
      validations: [],
    },
  ]

  const [isApplied, setIsApplied] = useState(false)
  const [selectedDeviceOption, setSelectedDeviceOption] = useState<any>(null)


  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds)
    setIsApplied(status)
  }

  const onSubmit = (values: KeyValues) => {
    getDevices({...values, deviceType: selectedDeviceOption?.value})
      .then((res: KeyValues[]) => {
        const plotIds = res.map((plotId) => plotId.plotId) as string[];
        handleFitlerChange(true, plotIds)
      })
  }
  const onReset = () => {
    handleFitlerChange(false, undefined)
  }
  
  let deviceTypes = JSON.parse(sessionStorage.getItem('deviceTypes')??'');


  return (
    <>
      <Typography variant="subtitle2" mt={1} >Devices</Typography>
      <div style={{
        backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
        margin: '0px 0 15px 0',
        padding: '5px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center'
      }} >
         <Grid width={250} mr={1}>
          <Typography variant="subtitle1" fontWeight={600}>Devices</Typography>
          <Select
           options={deviceTypes?.map((d: any) => ({
            value: d,
            label: d
              .split('_') 
              .map((word:any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
              .join(' ')
          }))}
            onChange={(selected: SingleValue<any>) => setSelectedDeviceOption(selected)}
            isSearchable={true}
            placeholder={'- select -'}
            value={selectedDeviceOption}
            isClearable
          />
        </Grid>
         <FormikApp
          formFields={fields}
          onSubmit={(values: KeyValues) => onSubmit(values)}
          onReset={() => {}}
          filter={true}
          showReset
          removeField = {() => handleRemove(filterId)}
        />
      </div>
    </>
  )
}

export default memo(DeviceFilter);