import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from "@mui/material";

interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title = "Please Confirm",
  description = "Are you sure you want to proceed?",
  onConfirm,
  onCancel
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          padding: 2,          
          borderRadius: 2,    
          minHeight: 200 
        }
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold", fontSize: 20 }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ fontSize: 16 }}>
          {description}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={onCancel} variant="outlined" color="inherit">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
