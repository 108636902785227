import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
  CardContent,
  Card,
  Chip,
  Paper,
} from "@mui/material";
import {
  getTemplates,
  postTemplateData,
} from "../../../services/whatsapp.service";
import Prism from "prismjs";
import "prismjs/themes/prism.css";
import Loader from "../../../components/Loader";
import { IoMdCloseCircle, IoMdSend } from "react-icons/io";

interface PlotModalProps {
  open: boolean;
  filtered_Data: any;
  loading: boolean;
  handleClose: () => void;
  flagTraningSchedule?: boolean;
}

interface Template {
  id: number;
  bodyOriginal: string;
  customParams: [];
  elementName: string;
}
interface CodeBlockProps {
  code: string;
}
interface User {
  mobile: string;
  [key: string]: any;
}

interface CustomParam {
  name: string;
  value: any;
}

interface Receiver {
  customParams: CustomParam[];
  whatsappNumber: string;
  lang: string;
  countryCode: string;
}

//component for shoowing the selected item
const CodeBlock: React.FC<CodeBlockProps> = ({ code }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [code]);

  return (
    <pre className="language-js">
      <code>{code}</code>
    </pre>
  );
};

const WhatsappModal: React.FC<PlotModalProps> = ({
  open,
  filtered_Data,
  loading,
  handleClose,
  // a flag to identify ki yeah modal kha se call hua hai
  flagTraningSchedule
}) => {
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  const [loadingState, setLoadingState] = useState(loading);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [templates, setTemplates] = useState<Template[]>([]);
  const [loadingTemp, setLoadingTemp] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  );
  const [removeDuplicates, setRemoveDuplicates] = useState(true);

  // Fetch templates based on search term
  const handleSearch = async () => {
    setLoadingTemp(true);
    try {
      const filters = { searchText: searchTerm };
      const response = await getTemplates(filters);
      const fetchedTemplates: Template[] = response.result?.items ?? [];

      setTemplates(fetchedTemplates);
    } catch (error) {
      console.error("Error fetching templates:", error);
    } finally {
      setLoadingTemp(false);
    }
  };

  const [uniqueLanguages, setUniqueLanguages] = useState<string[]>([]);

  useEffect(() => {
    if(flagTraningSchedule){
      setRemoveDuplicates(false);
      setFilteredData(filtered_Data);
      handleClear();
      return;
    }
    const uniqueLangs = new Set<string>();
    const uniqueData: any[] = [];
    const seenMobiles = new Set();

    // Loop through filtered_Data to extract unique languages and possibly filter users
    filtered_Data.forEach((user: any) => {
      const mobile = user?.farmUser?.mobile;
      const lang = user?.farmUser?.lang;

      // Always add unique language to the set
      uniqueLangs.add(lang);

      // Add to uniqueData if removeDuplicates is true, otherwise add all
      if (removeDuplicates) {
        if (!seenMobiles.has(mobile)) {
          seenMobiles.add(mobile);
          uniqueData.push(user);
        }
      } else {
        uniqueData.push(user);
      }
    });

    handleClear();
    setFilteredData(uniqueData);
    setUniqueLanguages(Array.from(uniqueLangs));
  }, [filtered_Data, removeDuplicates]);

  useEffect(() => {
    if(open){
      handleSearch();
    }
  }, [open]);

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [selectedKey, setSelectedKey] = useState<Record<string, string>>({});
  const [typedValue, setTypedValue] = useState<Record<string, string>>({});
  const [selectedParams, setSelectedParams] = useState<Record<string, string>>(
    {}
  );
  const [responsesFromWatti, setResponseFromWatti] = useState<any[]>([]);

  // Function to flatten object keys

  const flattenObjectKeys = (
    obj: Record<string, any>,
    parentKey: string = "",
    result: Record<string, any> = {}
  ): Record<string, any> => {
    for (const key in obj) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        flattenObjectKeys(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key];
      }
    }
    return result;
  };

  // Get all possible flattened keys from complexObject--- yeah dropdown k data ko store krwa rha hai
  const flattenedKeys = flattenObjectKeys(filtered_Data[0]);

  // Handle clearing of the form
  const handleClear = () => {
    setSelectedKey({});
    setSelectedValue("");
    setSelectedParams({});
    setTypedValue({});
  };

  function getNestedValue(obj: any, path: string): any {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  }
  const [loader, setLoader] = useState(false);

  const handleCheck = async () => {
    if (!selectedTemplate) {
      alert("Pls select the template first");
      return;
    }

    setLoader(true);

    let receivers: Receiver[] = [];

    filteredData.forEach((user: User) => {
      const customParams: CustomParam[] = [];

      if (Object.keys(selectedParams).length > 0) {
        // Populate customParams only if selectedParams is not empty
        Object.keys(selectedParams).forEach((paramKey) => {
          const path = selectedParams[paramKey];
          const tempObj: CustomParam = {
            name: paramKey,
            value: getNestedValue(user, path) || path,
          };
          customParams.push(tempObj);
        });
      }

      // Push to receivers, including only customParams if any were created
      receivers.push({
        customParams,
        whatsappNumber: flagTraningSchedule ? user.mobile : user.farmUser.mobile,
        lang: flagTraningSchedule ? user.language : user.farmUser.lang,
        countryCode: flagTraningSchedule ? user.countryCode : user.farmUser.countryCode,
      });
      
    });

    const dataToPost = {
      receivers,
      template_name: selectedTemplate?.elementName,
    };

    try {
      const res = await postTemplateData(dataToPost);
      setResponseFromWatti(res);
      setLoader(false);
    } catch (error) {
      console.error("Error posting data:", error);
      setLoader(false);
    }
  };

  const handleParamSelection = (key: string, paramName: string) => {
    setSelectedParams((prevParams) => ({ ...prevParams, [paramName]: key }));
  };

  const handleCheckboxChange = () => {
    setRemoveDuplicates(!removeDuplicates);
  };

  const isButtonEnabled = () => {
    return (
      selectedTemplate &&
      selectedTemplate.customParams.length ===
        Object.keys(selectedParams).length &&
      filtered_Data.length > 0
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "80vh",
          width: "80vw",
          bgcolor: "background.paper",
          padding: 4,
          boxShadow: 24,
          outline: "none",
          // overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        {filteredData?.length === 0 ? (
          <Typography
            sx={{
              color: "red",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
              padding: "18px 16px",
              marginTop: "20px",
              backgroundColor: "#fbe9e7",
              borderRadius: "4px",
              boxShadow: "0 1px 3px rgba(255, 0, 0, 0.2)", // Red shadow
            }}
          >
            Please filter the users to whom you want to send the template
            message.
          </Typography>
        ) : (
          <Box>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                width: "100px",
                fontSize: "14px",
                padding: "10px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "#f0f0f0",
                color: "black",
                zIndex: 1000,
                "&:hover": { backgroundColor: "#e0e0e0" },
                "&:focus": { outline: "none" },
              }}
            >
              Close
            </Button>

            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                height:"150px",
                width:"150px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end", 
              }}
            >
              {loader ? (
              
                  <Loader />
                 
              ) : (
                <Button
                  variant="contained"
                  onClick={handleCheck}
                  sx={{
                    width: "100px",
                    fontSize: "14px",
                    padding: "10px 20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    backgroundColor: isButtonEnabled() ? "green" : "#cccccc",
                    color: "#fff",
                    cursor: "pointer",
                    zIndex: 1000,
                    border: "none",
                    boxShadow: "none",
                    "&:hover": { backgroundColor: "#228b22" },
                  }}
                  disabled={!isButtonEnabled()}
                >
                  <IoMdSend size={24} />
                </Button>
              )}
            </Box>

            {loadingState ? (
              <div style={{ color: "green" }}>Loading...</div>
            ) : (
              <Box sx={{ display: "flex", gap: 2, height: "100vh" }}>
                {/* Left Section - Filtered Data */}
                <Box
                  sx={{
                    width: "50%",
                    margin: "0 auto",
                    textAlign: "center",
                    maxHeight: "80vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {/* Search Box */}
                  {!selectedTemplate && (
                    <Box
                      sx={{
                        marginBottom: 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#e8f5e9",
                        padding: "17px",
                        borderRadius: "12px",
                        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                      }}
                    >
                      <TextField
                        label="Search Templates"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        sx={{
                          marginRight: 2,
                          backgroundColor: "white",
                          borderRadius: "4px",
                        }}
                      />
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleSearch}
                        sx={{
                          width: "100px",
                          fontSize: "14px",
                          padding: "10px 20px",
                        }}
                      >
                        Search
                      </Button>
                    </Box>
                  )}

                  {selectedTemplate ? (
                    <Box
                      sx={{
                        padding: 3,
                        backgroundColor: "#f0f9f0",
                        borderRadius: "8px",
                        height: "100%",
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                      }}
                    >
                      <h1
                        style={{
                          color: "green",
                          fontSize: "28px",
                          marginBottom: "16px",
                        }}
                      >
                        Selected Template
                      </h1>
                      <h2 style={{ color: "#388e3c", fontSize: "24px" }}>
                        {selectedTemplate.elementName}{" "}
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#66bb6a",
                            marginLeft: "8px",
                            fontWeight: "normal",
                          }}
                        >
                          (ID: {selectedTemplate.id})
                        </span>
                      </h2>
                      <p
                        style={{
                          color: "#1b5e20",
                          fontSize: "14px",
                          fontStyle: "italic",
                        }}
                      >
                        {selectedTemplate.bodyOriginal}
                      </p>

                      {selectedTemplate?.customParams?.length > 0 && (
                        <>
                          <h2 style={{ color: "green", marginTop: "16px" }}>
                            Parameters
                          </h2>
                          {selectedTemplate.customParams.map(
                            (item: any, index: number) => (
                              <div key={index} style={{ marginBottom: "8px" }}>
                                <p
                                  style={{
                                    color: "#2e7d32",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Param Name: {item.paramName}
                                </p>
                              </div>
                            )
                          )}
                        </>
                      )}

                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          setSelectedTemplate(null);
                          setResponseFromWatti([]);
                          handleClear();
                        }}
                        sx={{ marginTop: 3, padding: "10px 20px" }}
                      >
                        Back to Templates
                      </Button>
                    </Box>
                  ) : (
                    <>
                      {loadingTemp ? (
                        <div
                          style={{
                            marginTop: "20vh",
                            width: "95%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Loader message="Loading Templates..." />
                        </div>
                      ) : (
                        <Box>
                          {templates?.map((template) => (
                            <Box
                              key={template.id}
                              sx={{
                                backgroundColor: "#e8f5e9",
                                padding: 2,
                                borderRadius: "8px",
                                marginBottom: 2,
                                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                              }}
                            >
                              <h3 style={{ color: "#388e3c" }}>
                                {template.elementName}
                              </h3>
                              <Button
                                variant="outlined"
                                color="success"
                                onClick={() => setSelectedTemplate(template)}
                              >
                                Select Template
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </>
                  )}
                </Box>

                {/* Right Section - Templates */}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    // justifyContent:filteredData?.length === 0 ? "center" : "flex-start",
                    width: "50%",
                    margin: "0 auto",
                    textAlign: "center",
                    maxHeight: "80vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    "&::-webkit-scrollbar": {
                      display: "none", // Chrome/Safari: hide scrollbar
                    },
                  }}
                >
                  {/* Filtered Data Header */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "16px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      boxShadow: "0 2px 6px rgba(0, 128, 0, 0.1)", // Slight shadow
                      marginBottom: "16px",
                    }}
                  >
                    {filteredData?.length === 0 ? (
                      <Typography
                        sx={{
                          height: "100%",
                          color: "red",
                          fontSize: "16px",
                          fontWeight: "bold",
                          textAlign: "center",
                          padding: "18px 16px",
                          marginTop: "20px",
                          backgroundColor: "#fbe9e7",
                          borderRadius: "4px",
                          boxShadow: "0 1px 3px rgba(255, 0, 0, 0.2)", // Red shadow
                        }}
                      >
                        Please filter the users to whom you want to send the
                        template message.
                      </Typography>
                    ) : (
                      <>
                        <Box
                          sx={{
                            color: "#388e3c", // Medium green for text
                            fontSize: "18px",
                            fontWeight: "600",
                            marginBottom: "8px",
                          }}
                        >
                          Total Selected Items: {filteredData?.length}
                        </Box>

                        {!flagTraningSchedule && <Box
                          component="label"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={removeDuplicates}
                            onChange={handleCheckboxChange}
                          />
                          Remove Duplicate WhatsApp Numbers
                        </Box>}

                        {!flagTraningSchedule && <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            marginTop: "16px",
                            gap: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                            }}
                          >
                            Unique Languages
                          </Typography>
                          <Card sx={{ width: "100%" }}>
                            <CardContent>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "nowrap",
                                  justifyContent: "flex-end",
                                  gap: 1,
                                  overflowX: "auto",
                                }}
                              >
                                {uniqueLanguages.map((lang, index) => (
                                  <Chip
                                    key={index}
                                    label={lang}
                                    size="small"
                                    sx={{
                                      backgroundColor: "#4caf50",
                                      color: "#fff",
                                      borderRadius: "20%",
                                    }}
                                  />
                                ))}
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>}
 
                      </>
                    )}
                  </Box>

                  {responsesFromWatti.length > 0 && (
                    <MessageList responses={responsesFromWatti} />
                  )}

                  {/* Selected Template Parameters */}
                  {selectedTemplate &&
                    selectedTemplate?.customParams.length > 0 && (
                      <Box sx={{ marginTop: "30px", textAlign: "center" }}>
                        {selectedTemplate.customParams.map(
                          (item: any, index: number) => {
                            const isDropdownSelected =
                              !!selectedKey[item.paramName];

                            const handleClearParam = () => {
                              setSelectedKey((prev) => ({
                                ...prev,
                                [item.paramName]: "",
                              }));
                              setTypedValue((prev) => ({
                                ...prev,
                                [item.paramName]: "",
                              }));
                              setSelectedParams((prev) => {
                                const { [item.paramName]: _, ...rest } = prev;
                                return rest;
                              });
                            };

                            return (
                              <Box
                                key={index}
                                sx={{
                                  backgroundColor: "#ffffff",
                                  padding: "10px",
                                  borderRadius: "8px",
                                  marginBottom: "12px",
                                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  gap: 2,
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1b5e20",
                                  }}
                                >
                                  Param Name: {item.paramName}
                                </label>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "baseline",
                                    flexWrap: "wrap",
                                    gap: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "baseline",
                                      flexWrap: "wrap",
                                      gap: 2,
                                    }}
                                  >
                                    <Select
                                      value={selectedKey[item.paramName] || ""}
                                      onChange={(event) => {
                                        const selectedValue =
                                          event.target.value;
                                        setSelectedKey((prev) => ({
                                          ...prev,
                                          [item.paramName]: selectedValue,
                                        }));
                                        setTypedValue((prev) => ({
                                          ...prev,
                                          [item.paramName]: "",
                                        }));
                                        handleParamSelection(
                                          selectedValue,
                                          item.paramName
                                        );
                                      }}
                                      displayEmpty
                                      disabled={!!typedValue[item.paramName]}
                                      sx={{
                                        flexGrow: 1,
                                        minWidth: "150px",
                                        maxWidth: "100%",
                                        marginBottom: 1,
                                        marginLeft: 1,
                                        backgroundColor: "#f1f8e9",
                                      }}
                                    >
                                      <MenuItem value="" disabled>
                                        Select Field
                                      </MenuItem>
                                      {Object.keys(flattenedKeys).map((key) => (
                                        <MenuItem key={key} value={key}>
                                          {key}
                                        </MenuItem>
                                      ))}
                                    </Select>

                                    {/* Ensures proper vertical alignment */}
                                    <TextField
                                      value={typedValue[item.paramName] || ""}
                                      onChange={(event) => {
                                        const typedVal = event.target.value;
                                        setTypedValue((prev) => ({
                                          ...prev,
                                          [item.paramName]: typedVal,
                                        }));
                                        setSelectedKey((prev) => ({
                                          ...prev,
                                          [item.paramName]: "",
                                        }));
                                        handleParamSelection(
                                          typedVal,
                                          item.paramName
                                        ); // Handle input values
                                      }}
                                      placeholder="Type a value"
                                      disabled={!!selectedKey[item.paramName]}
                                      sx={{
                                        marginLeft: 1,
                                        flexGrow: 1,
                                        minWidth: "150px",
                                        maxWidth: "100%",
                                      }}
                                    />
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={handleClearParam}
                                      sx={{
                                        marginLeft: 1,
                                        padding: "5px 10px",
                                      }}
                                    >
                                      Clear
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            );
                          }
                        )}

                        {/* Overall Clear button */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={handleClear}
                            sx={{
                              marginLeft: 2,
                              padding: "8px 16px",
                              boxShadow: "0 2px 6px rgba(0, 128, 0, 0.2)",
                              backgroundColor: "#4CAF50",
                              "&:hover": { backgroundColor: "green" },
                            }}
                          >
                            Reset
                          </Button>
                        </Box>

                        {/* Display the Final Selected Values */}

                        <Box
                          sx={{
                            backgroundColor: "#f0f9f0",
                            padding: "12px",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            maxWidth: "600px",
                            margin: "0 auto",
                          }}
                        >
                          <h3
                            style={{
                              color: "#1b5e20",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Selected Parameters:
                          </h3>
                          <pre
                            style={{
                              color: "#388e3c",
                              fontSize: "16px",
                              whiteSpace: "pre-wrap",
                              overflowWrap: "break-word",
                              maxHeight: "200px",
                              overflowY: "auto",
                              padding: "8px",
                              backgroundColor: "#e8f5e9",
                              borderRadius: "6px",
                            }}
                          >
                            <CodeBlock
                              code={JSON.stringify(selectedParams, null, 2)}
                            />
                          </pre>
                        </Box>
                      </Box>
                    )}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default React.memo(WhatsappModal);

const MessageList: React.FC<{ responses: any[] }> = ({ responses }) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "10px",
        margin: "10px 0",
        borderRadius: "5px",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#1b5e20",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "10px",
        }}
      >
        Responses
      </Typography>
      {responses?.map((response, index) => (
        <Paper
          key={index}
          elevation={1}
          sx={{
            padding: "10px",
            margin: "10px 0",
            borderRadius: "5px",
            backgroundColor: response.result ? "#e6ffed" : "#ffe6e6",
            color: response.result ? "#2c662d" : "#a94442",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              margin: 0,
              fontWeight: "bold",
            }}
          >
            {response.result ? "Success" : "Error"}
          </Typography>
          <Typography sx={{ margin: "5px 0" }}>
            {response.errors.error || "No error details available"}
          </Typography>
          {response.errors.invalidWhatsappNumbers?.length > 0 && (
            <Typography sx={{ margin: "5px 0" }}>
              <strong>Invalid WhatsApp Numbers:</strong>{" "}
              {response.errors.invalidWhatsappNumbers.join(", ")}
            </Typography>
          )}
          {response.errors.invalidCustomParameters?.length > 0 && (
            <Typography sx={{ margin: "5px 0" }}>
              <strong>Invalid Custom Parameters:</strong>{" "}
              {response.errors.invalidCustomParameters.join(", ")}
            </Typography>
          )}
        </Paper>
      ))}
    </Box>
  );
};
