import { Grid, Typography } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import {
  KeyValues,
  FilterDataValue,
  FilterProps,
} from "../../constants/interfaces";
import { getTagPlots, getTags } from "../../services/plot.service";
import FormikApp from "../DynamicForm";
import Select, { MultiValue, SingleValue } from "react-select";
import { getAgents } from "src/services/user.service";
import { debounce } from 'lodash';
import {getFilteredInventoryLocations} from "src/services/inventory.service"

const PlotFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
  const { handleFilterDataChange, handleRemove, filterId } = props;

  const [isApplied, setIsApplied] = useState(false);
  const [tags, setTags] = useState<any>(null);
  const [selectedTag, setSelectedTag] = useState<any>(null);
  const [agents, setAgents] = useState<any>(null);
  const [selectedAgents, setSelectedAgents] = useState<any>(null);

  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds);
    setIsApplied(status);
  };

  const onSubmit = (values: KeyValues) => {
    getTagPlots(
      selectedTag?.map((s:any)=>s?.value) as string[],
      selectedAgents?.value as string
    ).then((res: KeyValues[]) => {
      const plotIds = res?.map((plotId) => plotId.plotId) as string[];
      handleFitlerChange(true, plotIds);
    });
  };

  const fetchTags = async (query: string, limit = 5) => {
    let responseTags:any[] = await getTags(query, limit);
    responseTags = responseTags?.map((r: any) => ({
        label: r?.text,
        value: r?.text,
      }))
    let inventoryTags:any[]= await getFilteredInventoryLocations(query, limit);
    inventoryTags = inventoryTags?.map((r: any) => ({
        label: r?.name,
        value: r?.name,
      }))
      return responseTags.concat(inventoryTags);
    };

  const debouncedFetchTags = useCallback(
    debounce(async (inputValue: string) => {
      if (inputValue) {
        const newTags = await fetchTags(inputValue);
        setTags(newTags);
      } else {
        setTags([]);
      }
    }, 300),
    []
  );

  const handleInputChange = (inputValue: string) => {
    debouncedFetchTags(inputValue);
  };


  useEffect(() => {
    getAgents().then((res) => {
      setAgents(
        res?.map((r: any) => ({
          label: r?.name,
          value: r?.farmUserId,
        }))
      );
    });
  }, []);

  return (
    <>
      <Typography variant="subtitle2" mt={1}>
        Plots
      </Typography>
      <div
        style={{
          backgroundColor: isApplied ? "#B6F7BC" : "#E6E6E6",
          margin: "0px 0 15px 0",
          padding: "5px",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid width={250} mr={1}>
          <Typography variant="subtitle1" fontWeight={600}>
            Tags
          </Typography>
          <Select
            options={tags}
            isMulti
            onChange={(selected: MultiValue<any>) => setSelectedTag(selected)}
            onInputChange={handleInputChange}
            isSearchable={true}
            placeholder={"- select -"}
            value={selectedTag}
            isClearable
          />
        </Grid>
        <Grid width={250} mr={1}>
          <Typography variant="subtitle1" fontWeight={600}>
            Serviced By
          </Typography>
          <Select
            options={agents}
            onChange={(selectedAgents: SingleValue<any>) => setSelectedAgents(selectedAgents)}
            isSearchable={true}
            placeholder={"- select -"}
            value={selectedAgents}
            isClearable
          />
        </Grid>
        <FormikApp
          onSubmit={(values: KeyValues) => onSubmit(values)}
          onReset={() => {}}
          filter={true}
          showReset
          removeField={() => handleRemove(filterId)}
        />
      </div>
    </>
  );
};

export default memo(PlotFilter);
