import { Grid, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import ConfirmDialog from "src/utils/confirmDialog";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from 'xlsx';
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import {
  getInventoryLocations,
  postInventoryMovement,
  completeInventoryMovement,
  cancelInventoryMovement,
} from "../services/inventory.service";
import Select, { SingleValue, MultiValue } from "react-select";
import { generateOptions } from "src/utils/helper";
import { DatePicker } from "src/components";
import moment from "moment";
import _ from "lodash";

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
  deviceOptions?: any
}
const InventoryMovement: React.FC<Props> = (props: Props): JSX.Element => {
  const res: any = props?.selectedModalData;
  const [imei, setImei] = useState("");
  // const [allImei, setAllImei] = useState<String[]>(res?.imei || []);
  const [imeiList, setImeiList] = useState<String[]>(res?.imei || []);
  // const [imeiList, setImeiList] = useState<String[]>([]);
  const [imeiFilter, setImeiFilter] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [fileImeis, setFileImeis] = useState<string[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState<string>("");
  const [quantity, setQuantity] = useState<any>(res?.quantity || "");
  const [comments, setComments] = useState<any>(res?.comments || "");
  const [trackingId, settrackingId] = useState<any>(res?.trackingId || "");
  const [userId, setUserId] = useState<any>(
    res?.userId || localStorage.getItem("userId")
  );
  const [fromLocation, setFromLocation] = useState<any>(
    res?.fromInventoryLocationId || ""
  );
  const [toLocation, setToLocation] = useState<any>(
    res?.toInventoryLocationId || ""
  );
  const [typeOptions, setTypeOptions] = useState<SingleValue<any>>();
  const [locationOptions, setlocationOptions] = useState<SingleValue<any>>();
  const [selectedDeviceOption, setSelectedDeviceOption] = useState<any>({
    label: res?.inventoryItem?.name || null,
    value: res?.inventoryItem?.id || null,
  });
  const [selectedFromOption, setSelectedFromOption] = useState<any>({
    label: res?.fromInventoryLocation?.name || null,
    value: res?.fromInventoryLocation?.id || null,
  });
  const [selectedToOption, setSelectedToOption] = useState<any>({
    label: res?.toInventoryLocation?.name || null,
    value: res?.toInventoryLocation?.id || null,
  });
  const [selectedTypeOption, setSelectedTypeOption] = useState<any>({
    label: res?.type,
    value: res?.type,
  });
  const [selectedStatusOption, setSelectedStatusOption] = useState<any>({
    label: res?.status || "OPEN",
    value: res?.status || "OPEN",
  });
  const [movementDate, setMovementDate] = useState<Date>(
    res?.id ? moment(res?.date).toDate() : moment().toDate()
  );
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [confirmTitle, setConfirmTitle] = useState<string>("");
  const [confirmDescription, setConfirmDescription] = useState<string>("");
  const [onConfirmAction, setOnConfirmAction] = useState<() => void>(() => {});

  const showConfirm = (
    title: string,
    description: string,
    onConfirmCallback: () => void
  ) => {
    setConfirmTitle(title);
    setConfirmDescription(description);
    setOnConfirmAction(() => onConfirmCallback);
    setConfirmOpen(true);
  };


  const isValidIMEI = (value: string): boolean => {
    const numericRegex = /^[0-9]+$/;
    return numericRegex.test(value) && value.length >= 15 && value.length <= 20;
  };  

const handleEditImei = (index: number) => {
  setEditIndex(index);
  setEditValue(fileImeis[index]);
};


const handleSaveEdit = () => {
  if (!editValue.trim()) {
    toast.error("IMEI cannot be empty.");
    return;
  }
  if (!isValidIMEI(editValue.trim())) {
    toast.error("IMEI must be numeric and 15-20 digits long.");
    return;
  }
  if (fileImeis.includes(editValue.trim()) && fileImeis[editIndex!] !== editValue.trim()) {
    toast.error("Duplicate IMEI not allowed.");
    return;
  }

  const updatedImeis = [...fileImeis];
  updatedImeis[editIndex!] = editValue.trim();
  setFileImeis(updatedImeis);
  setEditIndex(null);
  setEditValue("");
  toast.success("IMEI updated successfully!");
};

const handleCancelEdit = () => {
  setEditIndex(null);
  setEditValue("");
};


  const handleAddImei = () => {
    if (imei.trim()) {
      if (!isValidIMEI(imei.trim())) {
        toast.error("IMEI must be numeric and 15-20 digits long.");
        return;
      }
      if (imeiList.includes(imei.trim())) {
        toast.error("Duplicate IMEI cannot be added.");
        return;
      }
      setImeiList((prev: String[]) => [...prev, imei.trim()]);
      setImei("");
      toast.success("IMEI added successfully!");
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;
      const data = new Uint8Array(arrayBuffer);
      const binaryString = String.fromCharCode(...data);

      const workbook = XLSX.read(binaryString, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      const rows = XLSX.utils.sheet_to_json<string[]>(worksheet, {
        header: 1,
      });

      const imeis = rows
        .flat()
        .filter(
          (item) =>
            typeof item === "string" || typeof item === "number"
        )
        .map((item) => String(item).trim())
        .filter(isValidIMEI);

      const uniqueImeis = Array.from(new Set(imeis));
      setFileImeis(uniqueImeis);
      setOpenDialog(true);
    };

    reader.readAsArrayBuffer(file);
  }
  event.target.value = "";
  };
  
  

  const handleRemoveImei = (index: number) => {
    setFileImeis((prev) => prev.filter((_, i) => i !== index));
  };
  
  const handleAddImeisToUI = () => {
    const uniqueFileImeis = Array.from(new Set(fileImeis));
    const uniqueImeis = uniqueFileImeis.filter((imei) => !imeiList.includes(imei));
  
    if (uniqueImeis.length > 0) {
      setImeiList((prev) => [...prev, ...uniqueImeis]);
      toast.success(`${uniqueImeis.length} IMEIs added successfully!`);
    } else {
      toast.warn("No new IMEIs to add.");
    }
  
    setOpenDialog(false);
    setFileImeis([]);
  };
  
  const filteredImeis = useMemo(() => {
    const filterText = imeiFilter.trim().toLowerCase();
    if (!filterText) return imeiList;
  
    return imeiList.filter((item) =>
      item.toLowerCase().includes(filterText)
    );
  }, [imeiFilter, imeiList]);
  

  useEffect(() => {
    getInventoryLocations().then((result) => {
      if (result.length > 0) {
        setlocationOptions(
          generateOptions(result, "id", "name", false, "isProductionPartner")
        );
      }
    });

    setTypeOptions(
      generateOptions(
        [
          { id: "ORDER_FULFILLMENT", name: "Order fulfillment" },
          { id: "INTERNAL_TRANSFER", name: "Internal transfer" },
          { id: "INSTALLATION", name: "Installation" },
          { id: "PRODUCTION", name: "Production" },
        ],
        "id",
        "name"
      )
    );
  }, []);

  const handleSubmitLogic = () => {
    const hasDevice = !!selectedDeviceOption && !!selectedDeviceOption.value;
    const hasMovementType = !!selectedTypeOption && !!selectedTypeOption.value;
    const hasQuantity = _.isNumber(Number(quantity));

    if (!hasDevice || !hasMovementType || !hasQuantity) {
      toast.warn("Please fill required fields");
      return;
    }
    if (
      selectedTypeOption.value === "PRODUCTION" &&
      !locationOptions.find((loc: any) => loc.value === toLocation)?.isProductionPartner
    ) {
      toast.error("Production movement is not supported on this Location");
      return;
    }

    if (!res?.id && userId && quantity && selectedDeviceOption?.value?.length > 0) {
      const finalData = {
        date: movementDate,
        quantity: Number(quantity),
        comments,
        userId,
        inventoryItemId: selectedDeviceOption?.value,
        fromInventoryLocationId:
          fromLocation?.length > 0 && fromLocation !== "--select--" ? fromLocation : "",
        toInventoryLocationId:
          toLocation?.length > 0 && toLocation !== "--select--" ? toLocation : "",
        type: selectedTypeOption?.value,
        trackingId,
        imei: imeiList
      };

      postInventoryMovement(finalData).then((res: any) => {
        if (res.id) {
          toast.success("Movement added");
          setTimeout(() => {
            props?.setOpenModal?.(false);
          }, 1500);
        } else {
          toast.error("Some Error Ocuured");
        }
      });
    } else {
      toast.error("Some Error Ocuured");
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    showConfirm("Submit Movement", "Do you want to submit?", handleSubmitLogic);
  };

  const handleCompleteLogic = () => {
    if (res?.id) {
      completeInventoryMovement(res.id).then((res: any) => {
        if (res.status === "COMPLETED") {
          toast.success("Movement Completed");
          props?.setOpenModal?.(false);
        } else {
          toast.error("Some Error Ocuured");
        }
      });
    } else toast.error("Some Error Ocuured");
  };

  const onComplete = () => {
    showConfirm("Complete Movement", "Do you want to submit?", handleCompleteLogic);
  };

  const handleCancelLogic = () => {
    if (res?.id) {
      cancelInventoryMovement(res.id).then((res: any) => {
        if (res.status === "CANCELED") {
          toast.warn("Movement Canceled");
          props?.setOpenModal?.(false);
        } else {
          toast.error("Some Error Ocuured");
        }
      });
    } else {
      toast.error("Some Error Ocuured");
    }
  };

  const onCancel = () => {
    showConfirm("Cancel Movement", "Do you want to submit?", handleCancelLogic);
  };

  const navigate = useNavigate();

  return (
    <Grid
      width="90%"
      my={2}
      mx="auto"
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    > 
    <ConfirmDialog
        open={confirmOpen}
        title={confirmTitle}
        description={confirmDescription}
        onConfirm={() => {
          setConfirmOpen(false);
          onConfirmAction();
        }}
        onCancel={() => setConfirmOpen(false)}
      />
      <ToastContainer position="top-right" autoClose={1000} />
      <Grid p={2} borderRadius={25} style={{ backgroundColor: "#f5f5f5" }}>
        <Grid container alignItems="center">
          <IconButton
            onClick={() =>
              props?.setOpenModal
                ? props.setOpenModal(false)
                : navigate("/inventory")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" style={{ marginLeft: 10 }}>
            Movement
          </Typography>
        </Grid>
      </Grid>
      <Grid display={"flex"} justifyContent={"flex-end"} mr={3}>
        <DatePicker
          showCalendarIcon
          label=""
          maxDate={new Date(Infinity)}
          labelStyle={{ fontWeight: "bold" }}
          value={movementDate}
          onChange={(d: any) => !res?.id && setMovementDate(d)}
        />
      </Grid>
      <div style={{ justifyContent: "center", padding: 20, marginTop: -20 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault(); 
            onSubmit(e);
          }}
        >
          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            User Id
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <br />
          <input
            name="userId"
            onChange={(e) => !res?.id && setUserId(e.target.value)}
            value={userId}
            type="text"
            style={{
              width: "93%",
              padding: "10px",
              fontSize: 18,
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <h3 style={{ margin: 5 }}>From Location</h3>
          <Select
            options={locationOptions}
            onChange={(selected: SingleValue<any>) => {
              !res?.id && setSelectedFromOption(selected);
              if (selected?.value && selected?.value?.length > 0) {
                setFromLocation(selected.value);
              } else {
                setFromLocation("");
              }
            }}
            value={selectedFromOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <h3 style={{ margin: 5 }}>To Location</h3>
          <Select
            options={locationOptions}
            onChange={(selected: SingleValue<any>) => {
              !res?.id && setSelectedToOption(selected);
              if (selected?.value && selected?.value?.length > 0) {
                setToLocation(selected.value);
              } else {
                setToLocation("");
              }
            }}
            value={selectedToOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <div style={{ display: "flex" }}>
            <h3 style={{ margin: 5 }}>Device Name</h3>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
          </div>
          <Select
            options={props.deviceOptions}
            onChange={(selected: SingleValue<any>) => {
              !res?.id && setSelectedDeviceOption(selected);
            }}
            value={selectedDeviceOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <div style={{ display: "flex" }}>
            <h3 style={{ margin: 5 }}>Movement Type</h3>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <br />
          </div>
          <Select
            options={typeOptions}
            onChange={(selected: SingleValue<any>) => {
              !res?.id && setSelectedTypeOption(selected);
            }}
            value={selectedTypeOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <div style={{ display: "flex" }}>
            <h3 style={{ margin: 5 }}>Quantity</h3>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <br />
          </div>
          <input
            name="quantity"
            onChange={(e) => !res?.id && setQuantity(e.target.value)}
            value={quantity}
            type="number"
            min={0}
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <br />

          <h3 style={{ margin: 5, marginTop: 8 }}>Tracking Id</h3>
          <input
            name="trackingId"
            onChange={(e) => !res?.id && settrackingId(e.target.value)}
            value={trackingId}
            type="text"
            style={{
              width: "93%",
              padding: "10px",
              fontSize: 18,
              margin: "3px",
              marginBottom: 11,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <br />
          <div style={{display:"flex"}}>
          <h3 style={{margin: 5, marginTop: 8}}>IMEI:</h3>
          {res?.id &&
          <div style={{ display: "flex", alignItems: "center", gap: 10, width:"20%", marginLeft: "50px"}}>
          <input
            type="text"
            placeholder="Filter IMEIs"
            value={imeiFilter}
            onChange={(e) => setImeiFilter(e.target.value)}
            style={{
              flex: 1,
              padding: "8px",
              fontSize: 16,
            }}
          />
        </div>}
          </div>
          {!res?.id && (
            <Grid style={{ display: "flex", alignItems: "center", marginTop: 5, gap: "10px" }}>
              <input
                name="imei"
                value={imei}
                onChange={(e) => setImei(e.target.value)}
                type="text"
                style={{
                  flex: 1,
                  padding: "10px",
                  fontSize: 18,
                  margin: "3px",
                  marginBottom: 11,
                  border: "1px solid #ccc",
                  borderRadius: 5,
                }}
              />
              <button
                type="button"
                onClick={handleAddImei}
                style={{
                  height: "40px",
                  padding: "0 15px",
                  backgroundColor: "#4caf50",
                  color: "white",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                  fontSize: 14,
                  marginBottom: "10px"
                }}
              >
                Add IMEI
              </button>

              {/* File Upload */}
              <label
                htmlFor="file-upload"
                style={{
                  height: "42px",
                  padding: "0 15px",
                  backgroundColor: "#4caf50",
                  color: "white",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px"
                }}
              >
                Choose File
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".csv, .xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
            </Grid>
          )}


          {/* Display IMEIs */}
          <div
            style={{
              display: "flex",
              maxHeight: "70px",
              lineHeight: "20px",
              overflowY: "auto",
              width: "90%",
              flexWrap: "wrap",
              gap: "10px",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {filteredImeis.map((item, index) => (
              <div
                key={index}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 10px",
                  backgroundColor: "#f1f1f1",
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  minWidth: "100px",
                }}
              >
                <span>{item}</span>
                
                {/* Remove button only shows in new mode */}
                {!res?.id && (
                  <button
                  type="button"
                    onClick={() => setImeiList((prev) => prev.filter((_, i) => i !== index))}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      backgroundColor: "gray",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                      cursor: "pointer",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                    }}
                    title="Remove"
                  >
                    ×
                  </button>
                )}
              </div>
            ))}
          </div>


          <br />
          <h3 style={{ margin: 5 }}>Comments</h3>
          <input
            name="comments"
            onChange={(e) => !res?.id && setComments(e.target.value)}
            value={comments}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <br />

          {!res?.id && selectedStatusOption?.value === "OPEN" && (
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 55 }}
            >
              <input
                type="button"
                value="Submit"
                onClick={onSubmit}
                style={{
                  width: "42%",
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
            </Grid>
          )}

          {res?.id && selectedStatusOption?.value === "OPEN" && (
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 55 }}
            >
              <input
                type="button"
                value="Complete"
                onClick={onComplete}
                disabled={selectedStatusOption?.value === "COMPLETED"}
                style={{
                  width: "42%",
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
              <input
                type="button"
                value="Cancel"
                onClick={onCancel}
                disabled={selectedStatusOption?.value === "CANCELED"}
                style={{
                  width: "42%",
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#f44336",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
            </Grid>
          )}
        </form>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Uploaded IMEIs</DialogTitle>
      <DialogContent>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "left", padding: "10px", borderBottom: "1px solid #ccc" }}>IMEI</th>
              <th style={{ textAlign: "left", padding: "10px", borderBottom: "1px solid #ccc" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {fileImeis.map((imei, index) => (
              <tr key={index}>
                <td style={{ padding: "10px", borderBottom: "1px solid #eee" }}>
                  {editIndex === index ? (
                    <input
                      type="text"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      style={{
                        padding: "5px",
                        width: "90%",
                        border: "1px solid #ccc",
                        borderRadius: "3px",
                      }}
                    />
                  ) : (
                    imei
                  )}
                </td>
                <td style={{ padding: "10px", borderBottom: "1px solid #eee" }}>
                  {editIndex === index ? (
                    <>
                      <button
                        onClick={handleSaveEdit}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#4caf50",
                          color: "#fff",
                          border: "none",
                          borderRadius: "5px",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Save
                      </button>
                      <button
                        onClick={handleCancelEdit}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#ccc",
                          color: "#000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEditImei(index)}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#2196f3",
                          color: "#fff",
                          border: "none",
                          borderRadius: "5px",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleRemoveImei(index)}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#f44336",
                          color: "#fff",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Remove
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DialogContent>

      <DialogActions>
        <button
          onClick={handleAddImeisToUI}
          style={{
            padding: "10px 20px",
            backgroundColor: "#4caf50",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Add
        </button>
        <button
          onClick={() => setOpenDialog(false)}
          style={{
            padding: "10px 20px",
            backgroundColor: "#ccc",
            color: "#000",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Cancel
        </button>
      </DialogActions>
    </Dialog>

    </Grid>
    
  );
  
};

export default InventoryMovement;
