import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import {
  InformationCard,
  InformationText,
} from "../components";
import { getPlotInsights } from "src/services/businessDashboard.service";
import {
  NotificationMapping,
  InformationCardTitles,
} from "src/constants/WordMapping";
import {
  getAnomalousPlots,
  getInactiveDevices,
  getExpiredPlots,
} from "src/services/device.service";
import _ from "lodash";
import ScrollableSearchableCard from "../components/ScrollableSearchCard";
import { saveToXlsxCropData } from "../../../utils/XLSX";

interface DataStructure {
  [key: string]: string[];
}

interface ResultStructure {
  [plotId: string]: [string | null, string | null, string | null]; // [device, location, crop]
}

// Functions to calculate data
function calCulateData(res: any) {
  //! district,state,units
  const district_Array = Object.keys(res?.locations).map((key) => {
    const [city, state] = key.split("_");

    return {
      city: city,
      state: state,
      units: res.locations[key].length,
    };
  });
  // Mock response as input
  const crops: DataStructure = res?.crops;
  const locations: DataStructure = res?.locations;
  const devices: DataStructure = res?.devices;

  // Initialize result with an empty object and type
  const result: ResultStructure = {};

  // Helper function to initialize an entry if it doesn't exist
  function ensurePlotExists(plotId: string): void {
    if (!result[plotId]) {
      result[plotId] = [null, null, null]; // [device, location, crop]
    }
  }

  // Process devices
  Object.entries(devices).forEach(([device, plotIds]) => {
    plotIds.forEach((plotId) => {
      ensurePlotExists(plotId);
      result[plotId][0] = device; // First element for device
    });
  });

  // Process locations
  Object.entries(locations).forEach(([location, plotIds]) => {
    plotIds.forEach((plotId) => {
      ensurePlotExists(plotId);
      result[plotId][1] = location; // Second element for location
    });
  });

  // Process crops
  Object.entries(crops).forEach(([crop, plotIds]) => {
    plotIds.forEach((plotId) => {
      ensurePlotExists(plotId);
      result[plotId][2] = crop; // Third element for crop
    });
  });

  //! list of crops and there numbers of devices

  const cropDeviceCount: { [crop: string]: number } = {};

  Object.values(result).forEach(([device, , crop]) => {
    if (crop && device) {
      if (!cropDeviceCount[crop]) {
        cropDeviceCount[crop] = 0;
      }

      cropDeviceCount[crop]++;
    }
  });

  //!    cropDeviceDetails

  const fixedDeviceUnits = [
    "KAIRO_UNIT",
    "WEATHER_UNIT",
    "NERO_UNIT",
    "IRRIGATION_UNIT",
    "MASTER_UNIT",
  ];

  const cropDeviceDetails: {
    [crop: string]: { [device: string]: number } & { totalDevices: number };
  } = {};

  Object.values(result).forEach(([device, , crop]) => {
    if (crop && device) {
      // Initialize the crop in cropDeviceDetails if it doesn't exist
      if (!cropDeviceDetails[crop]) {
        cropDeviceDetails[crop] = {
          totalDevices: 0, // Initialize totalDevices to 0
        };

        // Ensure that each crop has the fixed device units with initial count of 0
        fixedDeviceUnits.forEach((unit) => {
          cropDeviceDetails[crop][unit] = 0;
        });
      }

      // Increment the count for the specific device if it matches the fixed units
      if (fixedDeviceUnits.includes(device)) {
        cropDeviceDetails[crop][device]++;
        cropDeviceDetails[crop].totalDevices++; // Increment totalDevices
      }
    }
  });

  return { district_Array, cropDeviceDetails, cropDeviceCount };
}

const DataSkeleton: React.FC = (): JSX.Element => (
  <Grid>
    <Grid container justifyContent={"space-evenly"}>
      <Skeleton
        variant="rectangular"
        width={300}
        height={200}
        style={{ borderRadius: "15px", margin: "15px 0" }}
      />
      <Skeleton
        variant="rectangular"
        width={300}
        height={200}
        style={{ borderRadius: "15px", margin: "15px 0" }}
      />
      <Skeleton
        variant="rectangular"
        width={300}
        height={200}
        style={{ borderRadius: "15px", margin: "15px 0" }}
      />
      <Skeleton
        variant="text"
        height={40}
        width={300}
        style={{ borderRadius: "15px", margin: "15px 0" }}
      />      
    </Grid>
  </Grid>
);
interface InfoSectionProps {
  handleChange: (res: any) => void;
}
const InfoSection: React.FC<InfoSectionProps> = ({ handleChange }): JSX.Element => {
  const [insightData, setInsightData] = useState<any>();
  const [isReady, setIsReady] = useState<Boolean>(false);

  const [falsyPlots, setFalsyPlots] = useState<{
    inactive: string[];
    anomalous: string[];
    expired: string[];
  }>();

  //calculating lat and log
 const gettingPlotsLatLn = (res: any):any => {
  const plots: DataStructure = res?.plots;
  // this plots are send to show map on default home screen
  handleChange(plots);
  return plots;
};
  
  const [coromandelData, setCoromandeData] = useState({});

  const handleDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Prevent the click from bubbling up

    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");  
    const month = String(today.getMonth() + 1).padStart(2, "0");   
    const year = today.getFullYear();  

    const date = `${day}_${month}_${year}`;  
    const fileName = `Data_${date}`;

    if (insightData && coromandelData) {
      const { district_Array, cropDeviceDetails, cropDeviceCount } =
        calCulateData(insightData);
        saveToXlsxCropData({
        district_Array,
        cropDeviceDetails,
        cropDeviceCount,
        coromandelData,
        fileName,
      });
    }
  };

  useEffect(() => {
    getPlotInsights().then((res) => {
      setInsightData(res);
      sessionStorage.setItem('deviceTypes', JSON.stringify(Object.keys(res?.devices)));
      gettingPlotsLatLn(res);
      setIsReady(true);
    });

    // /get-anomalous-plots
    // /inactive-devices
    // /expired-plots
    getFalsyPlots();
  }, []);

  const getPlotIds = (plots: any): string[] => {
    return plots?.map((plot: any) => plot.plotId);
  };

  const getFalsyPlots = async () => {
    const [anomalousPlots, inactiveDevices, expiredPlots] = await Promise.all([
    // const [ inactiveDevices, expiredPlots] = await Promise.all([
      getAnomalousPlots(),
      getInactiveDevices(),
      getExpiredPlots(),
    ]);

    setFalsyPlots({
      inactive: getPlotIds(inactiveDevices),
      anomalous: getPlotIds(anomalousPlots),
      expired: getPlotIds(expiredPlots?.plots),
    });
  };

  useEffect(() => {
    if (insightData && falsyPlots && insightData.devices) {
      // Ensure that insightData and falsyPlots are fully loaded and valid
      const coromandelData = {
        totalPlots: insightData.plotCount || 0,
        WEATHER_UNIT: insightData.devices.WEATHER_UNIT?.length || 0,
        NERO_UNIT: insightData.devices.NERO_UNIT?.length || 0,
        KAIRO_UNIT: insightData.devices.KAIRO_UNIT?.length || 0,
        IRRIGATION_UNIT: insightData.devices.IRRIGATION_UNIT?.length || 0,
        MASTER_UNIT: insightData.devices.MASTER_UNIT?.length || 0,
        INACTIVE_plots: falsyPlots.inactive?.length || 0,
        Anomalous_plots: falsyPlots.anomalous?.length || 0,
        expired_plots: falsyPlots.expired?.length || 0,
      };

      setCoromandeData(coromandelData);
    }
  }, [insightData, falsyPlots]);
  
  return (
    <Grid
      item
      xs={3}
      px={4}
      height={"90vh"}
      style={{ overflowY: "scroll" }}
      className="informationOverflow"
    >
      {isReady ? (
        <Grid>
          <InformationCard
            data-testid="total-plots"
            amount={insightData?.plotCount ?? 0}
            text={InformationCardTitles.plots}
            data={Object.keys(insightData?.devices)?.map(
              (val: string) => insightData?.devices?.[val] as string[]
            )}
            handleDownload={handleDownload}
          >
            {Object.keys(insightData?.devices)?.map((val: any) => (
              <InformationText
                data={insightData?.devices?.[val]}
                text={val}
                key={val}
              />
            ))}
          </InformationCard>

          <ScrollableSearchableCard
            text={InformationCardTitles.districts}
            data={insightData?.locations}
          />

          <ScrollableSearchableCard  
            text={InformationCardTitles.crops}
            data={insightData?.crops}
          />

          {insightData?.farms && <ScrollableSearchableCard
            text={InformationCardTitles.farms}
            data={insightData?.farms}
          /> }

          <InformationCard
            data-testid="inconsistent-irrigation"
            amount={
              insightData.interactions?.["Early irrigation"]?.length ??
              0 +
                insightData.interactions?.["Insufficient irrigation"]?.length ??
              0 + insightData.interactions?.["No irrigation"]?.length ??
              0
            }
            data={[
              ...(insightData.interactions?.["Early irrigation"] ?? []),
              ...(insightData.interactions?.["Insufficient irrigation"] ?? []),
              ...(insightData.interactions?.["No irrigation"] ?? []),
            ]}
            amountFontSize={50}
            text={InformationCardTitles.inconsistentIrrigation}
            textFontSize={15}
            lastUpdated={true}
          >
            {!!insightData.interactions?.["No irrigation"] && (
              <InformationText
                data={insightData.interactions?.["No irrigation"]}
                text="no irrigation"
              />
            )}
            {!!insightData.interactions?.["Early irrigation"] && (
              <InformationText
                data={insightData.interactions?.["Early irrigation"]}
                text="early irrigation"
              />
            )}
            {!!insightData.interactions?.["Insufficient irrigation"] && (
              <InformationText
                data={insightData.interactions?.["Insufficient irrigation"]}
                text="insufficient irrigation"
              />
            )}
          </InformationCard>

          <InformationCard
            data-testid="actions-for-today"
            text={InformationCardTitles.actions}
            data={[] as string[]}
            amount={0}
          >
            {Object.keys(insightData?.notifications)?.map((val) => (
              <InformationText
                key={NotificationMapping[val]}
                data={insightData?.notifications?.[val]}
                text={`${NotificationMapping[val]}`}
              />
            ))}
          </InformationCard>

          {/* {hasPermission && (
              <Grid>
                <InformationText2 text={`${insightData.interactions?.['No recent interaction']?.length} plots where no interactions has been initiated since last month`} />
              </Grid>
            )} */}
        </Grid>
      ) : (
        <DataSkeleton />
      )}

      {!!falsyPlots ? (
        <>
          <InformationCard
            text={InformationCardTitles.falsyPlots}
            data={_.union(
              falsyPlots.anomalous,
              falsyPlots.inactive,
              falsyPlots.expired
            )}
            amount={0}
          >
            <InformationText
              text={"Anomalous Plots"}
              data={falsyPlots.anomalous}
            />
            <InformationText text={"Inactive"} data={falsyPlots.inactive} />
            <InformationText text={"Expired"} data={falsyPlots.expired} />
          </InformationCard>
        </>
      ) : (
        <Grid container justifyContent={"space-evenly"}>
          <Skeleton
            variant="rectangular"
            width={300}
            height={200}
            style={{ borderRadius: "15px", margin: "15px 0" }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default InfoSection;




 //TODO: Need to optimise the code for download button.
