import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Typography,
  Button,
  Modal,
  IconButton,
  Paper,
  CircularProgress,
} from "@mui/material";
import { ArrowBack, CalendarMonthOutlined, Clear } from "@mui/icons-material";
import { DhartiChatsColDef } from "../constants/columnDefs";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import {
  GridReadyEvent,
  GridOptions,
  FilterChangedEvent,
  SortChangedEvent,
  ColDef,
  SideBarDef,
} from "ag-grid-community";
import {
  getDhartiChatsCount,
  getChatsByPagination,
} from "src/services/chatSupport.service";
import { DatePicker } from "src/components";
import { ToastAlert } from "src/components";
import { ToastSeverity } from "src/components/ToastAlert/types";
import dayjs from "dayjs";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useNavigate } from "react-router";

const DhartiChat = (): JSX.Element => {
  const [chats, setChats] = useState<any[]>([]);
  const [totalChat, setTotalChats] = useState<number>(0);
  const [columnFilter, setColumnFilters] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [showTranslation, setShowTranslation] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>("success");
  const [modalFromDate, setModalFromDate] = useState<any>(
    dayjs().subtract(1, "week").toDate()
  );
  const [modalToDate, setModalToDate] = useState<any>(
    dayjs().subtract(1, "D").toDate()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef?.current?.api?.sizeColumnsToFit();
    // gridRef?.current?.api.setColumnDefs(appNotifsColDef);
    // gridRef?.current?.api.setRowData(appNotifData);
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const gridOptions: GridOptions = {
    columnDefs: DhartiChatsColDef,
    rowData: chats,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setModalData(e.data);
      setShowTranslation(false);
      setOpenModal(true);
    },
  };

  const onPaginationChanged = useCallback(() => {}, []);

  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };

  const handleColumnFilter = (event: FilterChangedEvent) => {
    const filterModel = event.api.getFilterModel();
    const whereClause: any = { and: [] };

    if (Object.keys(filterModel).length > 0) {
      Object.entries(filterModel).forEach(([column, filter]) => {
        const {
          type,
          filterType,
          filter: filterValue,
          dateFrom,
          dateTo,
        } = filter;
        let filterCondition: any = {};
        switch (filterType) {
          case "text":
            filterCondition[column] = {
              like: `.*${filterValue}.*`,
              options: "i",
            };
            break;
          case "number":
            switch (type) {
              case "equals":
                filterCondition[column] = filterValue;
                break;
              case "notEqual":
                filterCondition[column] = { neq: filterValue };
                break;
              case "lessThan":
                filterCondition[column] = { lt: filterValue };
                break;
              case "lessThanOrEqual":
                filterCondition[column] = { lte: filterValue };
                break;
              case "greaterThan":
                filterCondition[column] = { gt: filterValue };
                break;
              case "greaterThanOrEqual":
                filterCondition[column] = { gte: filterValue };
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        whereClause.and.push(filterCondition);
      });
    }
    setColumnFilters(whereClause.and);
  };

  const fetchData = () => {
    setIsLoading(true);
    getChatsByPagination(
      modalFromDate,
      dayjs(modalToDate).add(1, "D").endOf("D").toDate(),
      columnFilter
    ).then((res: any) => {
      setChats(res);
    });

    getDhartiChatsCount(
      modalFromDate,
      dayjs(modalToDate).add(1, "D").endOf("D").toDate(),
      columnFilter
    ).then((res: any) => {
      let cnt = Math.round(res.count / 30);

      if (res.count % 30 !== 0) cnt += 1;
      setTotalChats(cnt);
    });
    setTimeout(() => setIsLoading(false), 1500);
  };

  useEffect(() => {
    if (columnFilter?.length > 0) {
      setIsLoading(true);
      getChatsByPagination(undefined, undefined, columnFilter).then(
        (res: any) => {
          setChats(res);
        }
      );

      getDhartiChatsCount(undefined, undefined, columnFilter).then(
        (res: any) => {
          let cnt = Math.round(res.count / 30);

          if (res.count % 30 !== 0) cnt += 1;
          setTotalChats(cnt);
        }
      );
      setTimeout(() => setIsLoading(false), 1500);
    } else fetchData();
  }, [columnFilter]);

  const defaultDatePickerPlaceholder = {
    maxDate: new Date(),
    calendarIcon: (
      <IconButton>
        <CalendarMonthOutlined />
      </IconButton>
    ),
    clearIcon: <Clear />,
  };

  const formatTimestamp = (timestamp: Date) => {
    const date = new Date(timestamp);
    const options: any = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleTimeString([], options);
  };

  const navigate = useNavigate();

  return (
    <Grid my={2} mx={"auto"}>
      <Grid p={3}>
        <div style={gridStyle} className="ag-theme-alpine">
          <Grid
            p={3}
            borderRadius={20}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <IconButton onClick={() => navigate("/")}>
                  <ArrowBack />
                </IconButton>
                <Typography m={1} variant="h5">
                  Dharti Chats
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <DatePicker
                    {...defaultDatePickerPlaceholder}
                    onChange={setModalFromDate}
                    value={modalFromDate}
                    label="From"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6">-</Typography>
                </Grid>
                <Grid item>
                  <DatePicker
                    {...defaultDatePickerPlaceholder}
                    onChange={setModalToDate}
                    value={modalToDate}
                    label="To"
                  />
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={fetchData}>
                    Load Data
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <CircularProgress size={40} color={"success"} />
            </div>
          )}
          <AgGridReact
            ref={gridRef}
            rowData={chats}
            columnDefs={DhartiChatsColDef}
            defaultColDef={defaultColDef}
            sideBar={sideBar}
            animateRows={true}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            domLayout="autoHeight"
            onPaginationChanged={onPaginationChanged}
            onSortChanged={handleColumnSort}
            onFilterChanged={handleColumnFilter}
            pagination={true}
            paginationPageSize={30}
          ></AgGridReact>
        </div>
      </Grid>
      <Modal open={openModal} onClose={() => setOpenModal(false)} style={{overflow:'auto'}}>
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid item>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              alignItems="center"
            >
              <Grid item>
                <IconButton onClick={() => setOpenModal(false)}>
                  <ArrowBack />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Grid
                  container
                  spacing={1}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item>
                    <Typography fontSize={18} fontWeight={700}>
                      {modalData?.farmer_name}
                    </Typography>
                  </Grid>
                  {modalData?.farmer_plot_ids?.length > 0 && (
                    <Grid item display={"flex"} alignItems={"center"}>
                      <Typography
                        fontSize={18}
                        fontWeight={700}
                        sx={{ marginRight: 1 }}
                      >
                        Plots:
                      </Typography>
                      <Grid container spacing={1}>
                        {modalData?.farmer_plot_ids?.map((id: string) => (
                          <Grid item key={id}>
                            <Link
                              to={`/plot-data/${id}`}
                              style={{ textDecoration: "none" }}
                              target="_blank"
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "primary.main",
                                  cursor: "pointer",
                                  padding: "8px 12px",
                                  border: "1px solid transparent",
                                  borderRadius: "4px",
                                  transition:
                                    "background-color 0.2s, border 0.2s",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 150, 136, 0.1)",
                                    border: "1px solid rgba(0, 150, 136, 0.5)",
                                  },
                                }}
                              >
                                {id}
                              </Typography>
                            </Link>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            width="90%"
            my={2}
            mx="auto"
            boxShadow={2}
            borderRadius={4}
            paddingBottom={5}
          >
            <Button style={{fontSize:14, marginLeft: "85%"}} onClick={()=>setShowTranslation(!showTranslation)}>{showTranslation?"Hide":"Show"} translation</Button>
            <div style={{ padding: "20px", height: "80vh", overflowY: "auto" }}>
              <Grid>
                <Typography
                  textAlign={"center"}
                  color={"#0c9611"}
                  fontWeight={"bold"}
                >
                  {dayjs(modalData?.expiry).format("DD MMM YYYY")}
                </Typography>
                {modalData?.messages.map((message: any, index: number) => (
                  <Grid
                    key={`message-${index}`}
                    item
                    display="flex"
                    justifyContent={
                      message.role === "assistant" ? "flex-start" : "flex-end"
                    }
                  >
                    <Paper
                      sx={{
                        paddingX: 1,
                        marginY: 1,
                        bgcolor:
                          message.role === "assistant" ? "#e0e0e0" : "#0c9611",
                        color: message.role === "assistant" ? "#000" : "#fff",
                        borderRadius: "16px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        maxWidth: "80%",
                        wordBreak: "break-word",
                      }}
                    >
                      <Typography variant="body1" fontSize={14}>
                        <Markdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            table: ({ node, ...props }) => (
                              <table
                                style={{
                                  width: "100%",
                                  border: "1px solid black",
                                }}
                                {...props}
                              />
                            ),
                            th: ({ node, ...props }) => (
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "4px",
                                }}
                                {...props}
                              />
                            ),
                            td: ({ node, ...props }) => (
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "4px",
                                }}
                                {...props}
                              />
                            ),
                            img: ({ node, ...props }) => (
                              <img {...props} style={{ maxWidth: "100%" }} />
                            ),
                            a: ({ node, ...props }) => (
                              <a
                                {...props}
                                target="_blank"
                                rel="noopener noreferrer"
                              />
                            ),
                          }}
                        >
                          {showTranslation? (message?.en ?? message.content) : message.content}
                        </Markdown>
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          marginTop: 0.5,
                          color:
                            message.role === "assistant" ? "gray" : "white",
                        }}
                      >
                        {formatTimestamp(message.timestamp)}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Modal>
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={toastSeverity}
        message={toastMessage}
      />
    </Grid>
  );
};

export default DhartiChat;
