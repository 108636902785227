import { Grid, IconButton, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { postInventoryLocation, patchInventoryLocation, deleteInventoryLocation, getInventoryMovementsByLocation } from '../services/inventory.service';
import { isArrayWithLength } from '../utils/helper';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { RegionAdress, getRegionAdress } from 'src/services/region.service';
import { SingleValue } from 'react-select';
import { AgGridComponent } from './CropCharacteristics';
import { inventoryMovementsColDef } from 'src/constants/columnDefs';

interface Props {
    selectedModalData?: any;
    setOpenModal?: any;
    setMovementModal?: any;
    setSelectedModalData?: any
}
const InventoryLocation: React.FC<Props> = (props: Props): JSX.Element => {
    const res: any = props?.selectedModalData;
    const [name, setName] = useState<any>(res?.name || "")
    const [movementData, setMovementData] = useState<any>(res?.name || "")
    const [contact, setContact] = useState<String[]>(res?.contact_no || [])
    const [isChannelPartner, setIsChannelPartner] = useState<boolean>(res?.isChannelPartner || false)
    const [isProductionPartner, setIsProductionPartner] = useState<boolean>(res?.isProductionPartner || false)
    const [contactView, setcontactView] = useState<any>(contact.join(','))
    const [stocks, setstocks] = useState<[]>(res?.device || [])
    const [regionAddress, setRegionAddress] = useState<
        RegionAdress & {
            geoLocation?: { type: string, coordinates: number[] },
            address?: string
        }>({
            village: res?.village ?? "",
            subDistrict: res?.subDistrict ?? "",
            district: res?.district ?? "",
            state: res?.state ?? "",
            country: res?.country ?? "",
        })

    const onSubmit = () => {
        if (name) {
            let finalData = {
                name: name,
                ...regionAddress,
                isChannelPartner: isChannelPartner,
                isProductionPartner: isProductionPartner,
                contact_no: contactView.split(',')?.map((i: any) => i.trim())
            }
            if (window.confirm("Do you want to submit?")) {
                if (res?.id) {
                    patchInventoryLocation(finalData, res.id)
                        .then((res: any) => {
                            if (res.message === 'Success') {
                                alert("Location updated");
                                props?.setOpenModal(false);
                            }
                            else
                                alert("Some Error Ocuured");
                        })
                }
                else {
                    postInventoryLocation(finalData)
                        .then((res: any) => {
                            if (res.id) {
                                alert("Location added");
                                props?.setOpenModal(false);
                            }
                            else
                                alert("Some Error Ocuured");
                        })
                }
            }
        }
        else
            alert("Some Error Ocuured");
    }

    const onDelete = () => {
        if (res?.id) {
            if (window.confirm("Do you want to delete?")) {
                deleteInventoryLocation(res.id)
                    .then((res: any) => {
                        if (res.message === 'Success') {
                            alert("Location deleted");
                            props?.setOpenModal(false);
                        }
                        else
                            alert("Some Error Ocuured");
                    })
            }
        }
        else
            alert("Some Error Occured");
    }

    const navigate = useNavigate()

    const clearRegionAddress = () => {
        setRegionAddress({
            village: "",
            subDistrict: "",
            district: "",
            state: "",
            country: "",
            geoLocation: undefined,
            address: undefined
        })
    }

    /**
     * autocomplete handler
     */

    const onGoogleAutoCompletePropChange = (te: SingleValue<any>) => {
        clearRegionAddress();
        if (te?.value?.place_id) {
            geocodeByPlaceId(te?.value?.place_id)
                .then(res => {
                    if (!!res && Array.isArray(res) && res.length > 0) {
                        const { geometry } = res[0];

                        const { lat, lng } = geometry.location;
                        const latlon = {
                            lat: lat(),
                            lon: lng()
                        }

                        const geoLocation = {
                            type: "Point",
                            coordinates: [latlon.lon, latlon.lat]
                        }

                        const address = te.label;

                        getRegionAdress(latlon)
                            .then(res => {
                                setRegionAddress({
                                    ...res,
                                    address,
                                    geoLocation
                                });
                            })
                            .catch(err => {
                                console.error("Failed: region address", err);
                            })
                    }
                })
        }
    }

    useEffect(() => {
        if (props?.selectedModalData?.id) {
            getInventoryMovementsByLocation(props?.selectedModalData?.id)?.then(res => setMovementData(res.map((mov: any) => ({
                ...mov,
                deviceName: mov?.inventoryItem?.name,
                fromLocation: mov?.fromInventoryLocation?.name,
                toLocation: mov?.toInventoryLocation?.name
            }))))
        }
    }, [props.selectedModalData])

    return (
        <Grid width="90%" my={2} mx="auto" boxShadow={2} borderRadius={4} paddingBottom={5}>
            <Grid p={2} borderRadius={25} style={{ backgroundColor: '#f5f5f5' }}>
                <Grid container alignItems="center">
                    <IconButton onClick={() => props?.setOpenModal ? props.setOpenModal(false) : navigate('/inventory')}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h5" style={{ marginLeft: 10 }}>Location</Typography>
                </Grid>
            </Grid>

            <div style={{ justifyContent: "center", padding: 20 }}>
                <form>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }}>Name</label>
                            <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red' }}>*</label>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ fontSize: 18, fontWeight: 'bold', marginRight: 11 }}>Production Partner</label>
                            <input type="checkbox" name="productionPartner" onChange={(e) => setIsProductionPartner(!isProductionPartner)} checked={isProductionPartner} style={{ width: '22px', height: '22px', marginRight: 30 }}></input>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ fontSize: 18, fontWeight: 'bold', marginRight: 11 }}>Channel Partner</label>
                            <input type="checkbox" name="channelPartner" onChange={(e) => setIsChannelPartner(!isChannelPartner)} checked={isChannelPartner} style={{ width: '22px', height: '22px', marginRight: 30 }}></input>
                        </div>
                    </div>

                    <input
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        type="text"
                        style={{
                            width: '93%',
                            padding: '10px',
                            fontSize: 18,
                            margin: '3px',
                            marginBottom: 11,
                            marginTop: 10,
                            border: '1px solid #ccc',
                            borderRadius: 5,
                        }}
                    />
                    <h3 style={{ margin: 5 }}>Contact</h3>
                    <input
                        name="contact"
                        onChange={(e) => setcontactView(e.target.value)}
                        value={contactView}
                        type="text"
                        style={{
                            width: '93%',
                            padding: '20px',
                            fontSize: 18,
                            paddingTop: '15px',
                            margin: '3px',
                            marginBottom: 11,
                            border: '1px solid #ccc',
                            borderRadius: 5,
                        }}
                    /><br />
                    <h3 style={{ margin: 5 }}>Search Location</h3>
                    <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_MAP_KEY}
                        selectProps={{
                            onChange: onGoogleAutoCompletePropChange,
                            styles: {
                                container: () => ({
                                    width: '96%',
                                    margin: '3px',
                                    marginBottom: 11,
                                    label: 'container',
                                    boxSizing: 'border-box',
                                    position: 'relative'
                                })
                            }
                        }}
                    />

                    <Grid container p={1} justifyContent="space-between">
                        <Grid item>
                            <h3>Country</h3>
                            <p>{regionAddress.country}</p>
                        </Grid>
                        <Grid item>
                            <h3>State</h3>
                            <p>{regionAddress.state}</p>
                        </Grid>
                        <Grid item>
                            <h3>District</h3>
                            <p>{regionAddress.district}</p>
                        </Grid>
                        <Grid item>
                            <h3>Sub District</h3>
                            <p>{regionAddress.subDistrict}</p>
                        </Grid>
                        <Grid item>
                            <h3>Village</h3>
                            <p>{regionAddress.village}</p>
                        </Grid>
                    </Grid>

                    {isArrayWithLength(stocks) && (
                        <>
                            <h3 style={{ margin: 5 }}>Stocks</h3>
                            <Grid display="flex" justifyContent="space-evenly" flexWrap="wrap">
                                {stocks?.map((st: any) => (
                                    <Grid my={2} boxShadow={2} borderRadius={3} px={2} pb={2} pt={2} key={st?.deviceName}>
                                        <Grid display="flex" alignItems="center">
                                            <div style={{ alignItems: 'center' }}>
                                                <label style={{ fontSize: 22, margin: '15px' }}>{st?.deviceName}: </label>
                                            </div>
                                            <div style={{ alignItems: 'center' }}>
                                                <Typography style={{
                                                    fontSize: 25,
                                                    width: 100,
                                                    padding: '5px 10px',
                                                    margin: '3px',
                                                    fontWeight: 'bold',
                                                }}>{st?.stockQuantity}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {res?.openOrders && Object.values(res?.openOrders)?.some((val: any) => val > 0) && (
                        <>
                            <h3 style={{ margin: 5 }}>Orders</h3>
                            <Grid display="flex" justifyContent="space-evenly" flexWrap="wrap">
                                {Object.entries(res?.openOrders)?.map(([key, value]: [any, any]) => {
                                    if (value > 0)
                                        return (
                                            <Grid my={2} boxShadow={2} borderRadius={3} px={2} pb={2} pt={2} key={key}>
                                                <Grid display="flex" alignItems="center">
                                                    <div style={{ alignItems: 'center' }}>
                                                        <label style={{ fontSize: 22, margin: '15px' }}>{key}: </label>
                                                    </div>
                                                    <div style={{ alignItems: 'center' }}>
                                                        <Typography style={{
                                                            fontSize: 25,
                                                            width: 100,
                                                            padding: '5px 10px',
                                                            margin: '3px',
                                                            fontWeight: 'bold',
                                                        }}>{value}</Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )
                                    else return null
                                })}
                            </Grid>
                        </>
                    )}

                    {isArrayWithLength(movementData) && (
                        <>
                            <h3 style={{ margin: 5 }}>Movement</h3>
                            <AgGridComponent columnDef={inventoryMovementsColDef} rowData={movementData} setSelectedModalData={props.setSelectedModalData} setMovementOpenModal={props.setMovementModal} setLocationModal={props.setOpenModal} />
                        </>
                    )
                    }



                    <Grid container justifyContent="space-between" style={{ marginTop: 55 }}>
                        <input
                            type="button"
                            value="Submit"
                            onClick={onSubmit}
                            style={{
                                width: '42%',
                                padding: '10px',
                                fontSize: 18,
                                backgroundColor: '#4caf50',
                                color: '#fff',
                                border: 'none',
                                borderRadius: 5,
                                cursor: 'pointer',
                            }}
                        />
                        {res?.id && (
                            <input
                                type="button"
                                value="Delete"
                                onClick={onDelete}
                                style={{
                                    width: '42%',
                                    padding: '10px',
                                    fontSize: 18,
                                    backgroundColor: '#f44336',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                }}
                            />
                        )}
                    </Grid>
                </form>
            </div>
        </Grid>


    )
}


export default InventoryLocation