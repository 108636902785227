import { MyContext } from "src/pages/PlotDetails";
import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { useLocation } from "react-router";
import { DateFilter, CropFilter } from "src/components/Filters";

type Props = {
  startDate: Date;
  endDate: Date;
  updateDiseasePest: (val: string) => void;
  updateObservationTypeId: (val: string) => void;
  updateStartDate: (data: Record<string, Date>) => void;
  updateEndDate: (data: Record<string, Date>) => void;
};

const Filters: React.FC<Props> = (props) => {
  const {
    startDate,
    endDate,
    updateDiseasePest,
    updateEndDate,
    updateStartDate,
    updateObservationTypeId,
  } = props;
  const details = useContext(MyContext);

  const location: any = useLocation();

  const { cropId } = location.state ?? details;

  const handleCropDropdown = (values: Record<string, any>) => {
    const { diseasePest, observationTypeId } = values;
    updateDiseasePest(diseasePest);
    updateObservationTypeId(observationTypeId);
  };

  return (
    <Grid container alignItems={"center"}>
      <Grid item xs={12}>
        <CropFilter
          dropdowns={["crop", "disease-pest-merged", "observation-types"]}
          defaultBackgroundColor="white"
          defaultValue={{ cropId }}
          onChange={handleCropDropdown}
          hideTitle
        />
      </Grid>

      <Grid
        p={1}
        style={{ display: "flex", justifyContent: "center", gap: 30 }}
      >
        <DateFilter
          defaultValue={startDate}
          label="Start date"
          defaultBackgroundColor="white"
          onChange={updateStartDate}
        />

        <DateFilter
          defaultValue={endDate}
          label="End date"
          defaultBackgroundColor="white"
          onChange={updateEndDate}
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
