import React from "react";
import AppRouter from "./router";
import { ThemeProvider } from "@mui/material";

import { theme } from './styles/theme'
import { Navbar } from "./components";
import { AuthProvider } from "./context/AuthProvider";
import { Dashboard, Exporter } from "./pages";
import { 
  Chart, 
  ArcElement, 
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend, 
} from "chart.js";
import Clarity from '@microsoft/clarity';


const trackingId = process.env.REACT_APP_CLARITY_TRACKING_ID;

if (trackingId) {
  console.log("Clarity Tracking ID:", trackingId);
  Clarity.init(trackingId);
} else {
  console.warn("Clarity tracking ID is not defined. Skipping Clarity initialization.");
}

Chart.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  );

const App: React.FC = (): JSX.Element => {
  return (
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Navbar />
          <Dashboard />
          <Exporter />
          <AppRouter />
        </ThemeProvider>
      </AuthProvider>
  )
}

export default App